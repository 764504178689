/* Styles import */
import styles from "../license.module.css";

/* Global import */
import React, { Component, Fragment, useEffect, useState } from "react";
import {
  Title,
  Card,
  CardContent,
  GridRow,
  GridCol,
  TextInput,
  CardHeader,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderLicense from "../../../components/headerLicense";
import MatchingLinkedComponent from "../../../components/matching/linked";
import MatchingEquipmentComponent from "../../../components/matching/equipment";
import MatchingLicenseComponent from "../../../components/matching/license";
import Loader from "../../../components/loader";
import TimeLine from "../../../components/timeLine";
import { flowUrl } from "../../../utils/generator";
import Api from "../../../utils/api";
import FiltersMatchingComponent from "../../../components/filter/FiltersMatchingComponent";
import AutoComplete from "../../../components/autoComplete";
import { toJS } from "mobx";
import {
  CCountry,
  LRegulation,
  countries,
  regulation,
} from "../../../utils/constant";
import { filter } from "lodash";
import axios from "axios";
import RightPanel from "../../../components/rightPanel";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import license from "../../../components/matching/license";
interface Props {
  isOpenMatching: boolean;
  rootStore: import("../../../stores/rootStore").default;
  flow?: Flow;
  countries?: Country[];
  onClose: () => void;
}

const MatchingLicense: React.FC<Props> = ({
  rootStore,
  isOpenMatching,
  flow,
  countries,
  onClose,
}) => {
  const api = new Api();
  const { id, type } = useParams() as {
    id?: string;
    type?: string;
  };
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, `${id}/${state.type}`));
  };
  const goBack = () => {
    navigate(-1);
  };

  const initialState = {
    filteredLicenses: [],
    selectedDepartureCountry: 0,
    sendDepartureCountry: "",
    selectedRegulation: {},
    selectedDestinationCountry:
      rootStore?.licenseStore?.matching?.flow?.destinationCountryId ||
      undefined,
    partNumber: "",
    endUser: "",
    classification: "",
    checkboxStates: {
      "departure-country-checkbox": true,
      "regulation-checkbox": false,
      "destination-checkbox": true,
      "partnumber-checkbox": false,
      "enduser-checkbox": false,
      "classification-checkbox": true,
    },
    regulationOptions: regulation,
    type: type ? type : "NATIONAL",
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedEquipmentItem, setSelectedEquipmentItem] = useState<any>();

  const [selectedEquipmentId, setSelectedEquipmentId] = useState<
    number | undefined
  >(undefined);

  const [countriesState, setCountries] = useState<any[]>([] as any[]);

  const onFiltersChange = async (filters: any) => {
    setLoading(true);
    try {
      const segments = window.location.pathname.split("/");
      const id = segments[segments.length - 2];
      const type = segments[segments.length - 3];

      filters.searchRegulation = type;
      const results = await api.searchLicenses(filters);
      rootStore.licenseStore.updateAssociableLicenses(results);
      setState({
        ...state,
        filteredLicenses: results as never,
        classification: filters.classification,
        partNumber: filters.partNumber,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      checkboxStates: {
        ...prevState.checkboxStates,
        [id]: checked,
      },
    }));
  };

  const handleDestinationCountry = (destinationCountry: string) => {
    const intDestinationCountry = parseInt(destinationCountry);
    setState({ ...state, selectedDestinationCountry: intDestinationCountry });
  };

  const handleRegulation = (regulationValue: string) => {
    const regulationObject = regulation.find(
      (r) => r.value === regulationValue
    );
    setState({ ...state, selectedRegulation: regulationObject as {} });
  };
  const handleDepartureCountry = (departureCountry: string) => {
    const intDepartureCountry = parseInt(departureCountry);
    setState({ ...state, selectedDepartureCountry: intDepartureCountry });
    handleRegulationOptions(intDepartureCountry);
  };

  const handlePartNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, partNumber: e.target.value });
  };

  const handleEndUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, endUser: e.target.value });
  };

  const handleClassifcationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, classification: e.target.value });
  };

  const handleRegulationOptions = (s: number) => {
    const countriesListed = countriesState;
    const countryCode = countriesListed.find((country) => country.id === s);
    const countryCodeStr = countryCode
      ? countryCode.value.substring(0, 2).toUpperCase()
      : "";

    const newOptions = regulation.filter((option) => {
      if (
        option.label.substring(0, 2) === "EU" &&
        (countryCodeStr === "BE" ||
          countryCodeStr === "NL" ||
          countryCodeStr === "SE" ||
          countryCodeStr === "SG" ||
          countryCodeStr === "IT")
      ) {
        return true;
      }

      if (
        option.label.substring(0, 2) === countryCodeStr ||
        option.label.substring(0, 2) === "US" ||
        countryCodeStr === "AL"
      ) {
        return true;
      }

      return false;
    });

    setState((prevState) => ({
      ...prevState,
      sendDepartureCountry: countryCodeStr,
      selectedDepartureCountry: s, // Nouvelle propriété ajoutée
      regulationOptions: newOptions,
    }));
  };

  const fetchData = async () => {
    const flowId = id;
    const response = await axios.get(
      `${process.env.REACT_APP_SCREEN}/countries_departure`
    );
    setCountries(response.data);
    // setCountries(countries as CCountry[]);

    if (flowId) {
      setLoading(true);

      const matching = await rootStore.licenseStore.getMatching(
        parseInt(flowId)
      );

      const departureCountryId =
        rootStore.licenseStore.matching?.flow.departureCountryId;
      const destinationCountryId =
        rootStore.licenseStore.matching?.flow.destinationCountryId;

      if (departureCountryId) {
        setState((prevState) => ({
          ...prevState,
          selectedDepartureCountry: departureCountryId,
        }));
      }

      if (destinationCountryId) {
        setState((prevState) => ({
          ...prevState,
          selectedDestinationCountry: destinationCountryId,
        }));
      }

      const type = state.type || "";

      let promises: any[] = [];

      const equipments = rootStore.licenseStore.matching?.flow.equipments;
      if (equipments) {
        let isStateUpdated = false;
        for (const equipment of equipments) {
          if (
            (state.type === "NATIONAL" && equipment.nationalEccn) ||
            (state.type === "US" && equipment.usEccn)
          ) {
            if (!isStateUpdated) {
              setSelectedEquipmentId(equipment.id);

              if (!state.classification) {
                setState((prevState) => ({
                  ...prevState,
                  classification: equipment.nationalEccn,
                }));
              }

              if (!state.partNumber) {
                setState((prevState) => ({
                  ...prevState,
                  partNumber: equipment.partNumber,
                }));
              }

              isStateUpdated = true;
            }

            promises.push(
              rootStore.licenseStore.loadLinkedEquipment(equipment.id, type)
            );
          }
        }
      }

      // Si vous souhaitez attendre que toutes les promesses soient résolues avant de mettre à jour l'état
      await Promise.all(promises);

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (countries && state.selectedDepartureCountry) {
      handleRegulationOptions(state.selectedDepartureCountry);
    }
  }, [countries, state.selectedDepartureCountry]); // Ajouter departureCountryId comme dépendance

  const handleClickEquipment = async (idParam: number) => {
    // Trouvez l'équipement avec l'ID donné
    const selectedEquipment =
      rootStore!.licenseStore!.matching!.flow!.equipments.find(
        (e) => e.id === idParam
      );
    let newClassification;

    if (selectedEquipment) {
      setSelectedEquipmentItem({ selectedEquipmentItem: selectedEquipment });

      if (state.type === "US" && selectedEquipment.usEccn) {
        newClassification = selectedEquipment.usEccn;
      } else if (state.type === "NATIONAL" && selectedEquipment.nationalEccn) {
        newClassification = selectedEquipment.nationalEccn;
      } else {
        newClassification = state.classification;
      }
    }
    const filters = {
      selectedDepartureCountry: state.sendDepartureCountry,
      selectedRegulation: state.selectedRegulation,
      selectedDestinationCountry: state.selectedDestinationCountry,
      partNumber: selectedEquipment?.partNumber,
      endUser: state.endUser,
      classification: newClassification,
      checkboxStates: state.checkboxStates,
    };

    onFiltersChange(filters);

    if (selectedEquipmentId === idParam) {
      setSelectedEquipmentId(undefined);

      rootStore.licenseStore.clearAssociableLicenses();
    } else if (id) {
      setSelectedEquipmentId(idParam);
      setLoading(true);

      // await rootStore.licenseStore.getAssociableLicense(parseInt(id), id);

      setLoading(false);
    }
  };

  const getLicenseState = (license: MatchingLicenseDto) => {
    const expirationDate = moment(license.expiration_date);
    const equip = selectedEquipmentItem.selectedEquipmentItem;
    //traitement FR/ML -> prix unitaire ? -> comparaison equipement flux / equipement licence
    if (license.regulation == "FR_MUNITIONS_LIST") {
      if (license.unit_price) {
        if (
          equip.quantity <=
            license.quantity_initial - license.quantity_ordered &&
          equip.amount <= license.amount_initial - license.amount_ordered &&
          equip.unitPrice <= license.unit_price &&
          moment().isBefore(expirationDate)
        ) {
          return "validFRML";
        } else {
          return "unknown";
        }
      } else {
        return "unit_price";
      }
    } else {
      if (moment().isBefore(expirationDate)) {
        return "valid";
      } else if (moment().isAfter(expirationDate)) {
        return "expired";
      } else if (license.amount === undefined || license.amount < 0) {
        return "insufficient_amount";
      } else if (license.quantity === undefined || license.quantity < 0) {
        return "insufficient_quantity";
      } else if (license.unit_price === undefined) {
        return "unit_price";
      }
      return "unknown";
    }
  };

  const handleClickLicense = async (licenseId: number) => {
    //const { id } = useParams();
    const flowId = id;
    const type = state.type ? state.type : "";
    if (selectedEquipmentId && flowId) {
      setLoading(true);

      await rootStore.licenseStore.saveLink(
        parseInt(flowId),
        selectedEquipmentId,
        licenseId,
        type
      );
      const licenseInfo =
        await rootStore.licenseStore.searchLicenseById(licenseId);

      //Addlog
      const logr = api.AddLog(
        rootStore.userStore.user?.tenantId || "",
        rootStore.userStore.user?.email || "",
        rootStore.userStore.user?.role || "",
        "Licence match",
        "",
        "",
        "" + licenseInfo?.departureCountryId,
        "" + licenseInfo?.destinationCountryName,

        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      setLoading(false);
      setSelectedEquipmentId(undefined);
    }
  };

  const user = rootStore.userStore.user;

  let roles: string | undefined;

  if (user) {
    roles = user.role;
  }

  const renderLinkedSection = () => {
    const matching = rootStore.licenseStore.matching;

    return (
      <div className={styles.marginTop}>
        <Card>
          <CardHeader title={i18n.t("pages.license.matching.linked-license")} />
          <CardContent>
            {matching && matching.linked.length > 0 ? (
              matching.linked.map((link, index) => {
                return (
                  <MatchingLinkedComponent
                    key={index}
                    link={link}
                    flowId={matching.flow.flowNumber}
                    rootStore={rootStore}
                  />
                );
              })
            ) : (
              <p>{i18n.t("pages.license.matching.no-license-linked")}</p>
            )}
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderEquipmentSection = () => {
    const matching = rootStore.licenseStore.matching;

    return (
      <div className={styles.marginTop}>
        <Card>
          <CardHeader title={i18n.t("pages.license.matching.equipment")} />
          <CardContent>
            {matching &&
              matching.flow.equipments
                .filter((equipment) => {
                  // Make sure the equipment is not in the linked list
                  const foundInLinked = matching.linked.find(
                    (link) => link.equipment.id === equipment.id
                  );

                  if (foundInLinked) {
                    return false;
                  }

                  if (
                    (state.type === "NATIONAL" && equipment.nationalEccn) ||
                    (state.type === "US" && equipment.usEccn)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })

                .map((equipment, index) => {
                  const validType =
                    state.type === "US" || state.type === "NATIONAL"
                      ? state.type
                      : undefined;

                  return (
                    <MatchingEquipmentComponent
                      flow={flow}
                      key={index}
                      equipment={equipment}
                      clickable={true}
                      className={
                        selectedEquipmentId === equipment.id
                          ? "selected"
                          : undefined
                      }
                      onClick={(id) => handleClickEquipment(id)}
                      type={validType}
                    />
                  );
                })}
          </CardContent>
        </Card>
      </div>
    );
  };
  type LicenseTypeGr = any;

  const getBaseLicenseNumber = (licenseNumber: string) => {
    if (!licenseNumber) return "";
    const parts = licenseNumber.split(" ");
    return parts.slice(0, 2).join(" ");
  };

  const renderLicenseSection = () => {
    const associableLicenses = rootStore.licenseStore.associableLicenses;

    if (!associableLicenses) return null;

    let groupedLicenses: { [key: string]: LicenseTypeGr[] } = {};
    let allBaseLicenseNumbers: Set<string> = new Set();

    associableLicenses.forEach((license) => {
      const licenseNumberStr = license.license_number
        ? license.license_number.toString()
        : "";
      const baseLicenseNumber = getBaseLicenseNumber(licenseNumberStr);
      allBaseLicenseNumbers.add(baseLicenseNumber); // Stockez les numéros de base de licence

      if (!groupedLicenses[baseLicenseNumber]) {
        groupedLicenses[baseLicenseNumber] = [];
      }
      groupedLicenses[baseLicenseNumber].push(license);
    });

    let lastVersionLicenses: any[] = [];
    Object.values(groupedLicenses).forEach((group) => {
      group.sort((a, b) => {
        const versionA = a.license_number
          ? parseFloat(a.license_number.split(" ").pop()) // Obtenez la dernière partie
          : 0;
        const versionB = b.license_number
          ? parseFloat(b.license_number.split(" ").pop()) // Obtenez la dernière partie
          : 0;
        return versionB - versionA;
      });
      const [latestLicense] = group;
      lastVersionLicenses.push(latestLicense);
    });

    /*
    associableLicenses.forEach((license) => {
      const fullLicenseNumber = license.license_number
        ? license.license_number.toString()
        : "";
      if (!allBaseLicenseNumbers.has(fullLicenseNumber)) {
        lastVersionLicenses.push(license);
      }
    });
    */

    const sortedLicenses = lastVersionLicenses
      .slice()
      .sort((a, b) => {
        const stateOrder = [
          "validFRML",
          "valid",
          "expired",
          "insufficient_amount",
          "insufficient_quantity",
          "unit_price",
          "unknown",
        ];
        const stateA = getLicenseState(a);
        const stateB = getLicenseState(b);
        return (
          stateOrder.indexOf(stateA as string) -
          stateOrder.indexOf(stateB as string)
        );
      })
      .map((license) => {
        // Ajouter la valeur de retour de "stateOrder" à chaque objet license
        return {
          ...license,
          tmpStatus: getLicenseState(license),
        };
      });

    return (
      <div className={styles.marginTop}>
        <Card>
          <CardHeader title={i18n.t("pages.license.matching.license")} />
          <CardContent>
            {sortedLicenses &&
              sortedLicenses.map((license, index) => {
                return (
                  <MatchingLicenseComponent
                    partNumber={
                      license.pn || license.part_number || license.partNumber
                    }
                    endUserFromLicense={
                      license.company_name || license.recipient
                    }
                    key={index}
                    selectedEquipmentItem={
                      selectedEquipmentItem?.selectedEquipmentItem
                    }
                    license={license}
                    clickable={true}
                    rootStore={rootStore}
                    onClick={(id) => handleClickLicense(id)}
                  />
                );
              })}
          </CardContent>
        </Card>
      </div>
    );
  };
  const renderFlowSection = () => {
    const matching = rootStore.licenseStore.matching;
    return (
      <Card className={styles.customPadding}>
        <CardHeader title={i18n.t("pages.license.matching.flow")} />
        <CardContent>
          <GridRow noMargin>
            <GridCol md={3} noMargin>
              <TextInput
                onChange={() => {}}
                id="departure-country"
                label={i18n.t("pages.license.add.license-departure")}
                value={(matching && matching.flow.departureCountry) || ""}
                disabled={true}
                className={styles.textInputNoMargin}
              />
            </GridCol>
            <GridCol md={3} noMargin>
              <TextInput
                onChange={() => {}}
                id="destination-country"
                label={i18n.t("pages.license.add.destination-country")}
                value={(matching && matching.flow.destinationCountry) || ""}
                disabled={true}
                className={styles.textInputNoMargin}
              />
            </GridCol>

            <GridCol md={3} noMargin>
              <TextInput
                onChange={() => {}}
                id="order_num"
                label={i18n.t("pages.license.add.end-user")}
                value={(matching && matching.flow.endUser) || ""}
                disabled={true}
                className={styles.textInputNoMargin}
              />
            </GridCol>
            <GridCol md={3} noMargin>
              <TextInput
                onChange={() => {}}
                id="order_num"
                label={i18n.t("pages.new-flow.general.order_num")}
                value={(matching && matching.flow.orderNum) || ""}
                disabled={true}
                className={styles.textInputNoMargin}
              />
            </GridCol>
            {/*
            <GridCol md={3}>
              <TextInput
                id="flow-number"
                label={i18n.t("pages.license.matching.flow-number")}
                value={(matching && matching.flow.flowNumber) || ""}
                disabled={true}
              />
            </GridCol>
    */}
          </GridRow>
        </CardContent>
      </Card>
    );
  };
  const matching = rootStore.licenseStore.matching;

  return (
    <RightPanel display={isOpenMatching} onClose={() => onClose()}>
      <div>
        {/*
        <AppBar selectedPage={"LICENSE_MNGT"} history={injected.history} />
        {injected.rootStore.userStore.user?.role !== "F_E" &&
          injected.rootStore.userStore.user?.role !== "F" && (
            <HeaderLicense pages="MATCHING" />
          )}
          */}

        {loading && <Loader />}

        <div className={styles.body}>
          <Title tag="h2">
            {type === "US"
              ? i18n.t("pages.license.menu.matching_us")
              : i18n.t("pages.license.menu.matching")}
          </Title>
          {renderFlowSection()}
          {!(
            matching &&
            matching?.flow?.equipments?.length == 0 &&
            matching.linked.length == 0
          ) && (
            <Fragment>
              <FiltersMatchingComponent
                rootStore={rootStore}
                onSearch={onFiltersChange}
                handleCheckboxChange={handleCheckboxChange}
                handleDestinationCountry={handleDestinationCountry}
                handleRegulation={handleRegulation}
                handleDepartureCountry={handleDepartureCountry}
                handlePartNumberChange={handlePartNumberChange}
                handleEndUserChange={handleEndUserChange}
                handleClassifcationChange={handleClassifcationChange}
                handleRegulationOptions={handleRegulationOptions}
                loading={loading}
                filteredLicenses={state.filteredLicenses}
                selectedEquipmentId={selectedEquipmentId}
                selectedDepartureCountry={state.selectedDepartureCountry}
                selectedRegulation={state.selectedRegulation}
                selectedDestinationCountry={state.selectedDestinationCountry}
                partNumber={state.partNumber}
                endUser={state.endUser}
                classification={state.classification}
                checkboxStates={state.checkboxStates}
                regulationOptions={state.regulationOptions}
                countries={countriesState}
              />

              {renderLinkedSection()}
              <GridRow>
                <GridCol md={6}>{renderEquipmentSection()}</GridCol>

                <GridCol md={6}>{renderLicenseSection()}</GridCol>
              </GridRow>
            </Fragment>
          )}

          {/*          {matching &&
            matching?.equipments.length > 0 &&
            i18n.t("global.nolicence")}
          {matching &&
            matching?.equipments.length == 0 &&
            matching.linked.length > 0 &&
            i18n.t("global.oklicence")}

          {matching &&
            matching?.equipments.length == 0 &&
            matching.linked.length == 0 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: i18n.t("global.noequipement"),
                }}
              />
            )}
              */}
        </div>

        <Footer
          buttons={[
            {
              id: "previous",
              onClick: () => onClose(),

              label: i18n.t("global.previous"),
            },
          ]}
        ></Footer>
      </div>
    </RightPanel>
  );
};

export default inject("rootStore")(observer(MatchingLicense));
