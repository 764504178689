/* Styles import */
import styles from "./results.module.css";
import Api from "../../../utils/api";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* Global import */
import React, { Component, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Title,
  GridRow,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import ScreeningResult from "../../../components/screeningResult/screeningResult";
import Footer from "../../../components/footer";
import { flowUrl } from "../../../utils/generator";
import TimeLine from "../../../components/timeLine";
import { useNavigate, useParams } from "react-router-dom";

interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const ResultsFlow: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams() as {
    id?: string;
  };
  const [state, setState] = useState({
    expanded: false,
    expandedPannel: false,
  });

  const getScreeningButton = () => {
    const screeningType =
      (rootStore.userStore.user && rootStore.userStore.user.screeningType) ||
      undefined;
    if (screeningType === "USE_BUILT_IN_SCREENING") {
      return {
        id: "screeningLink",
        onClick: () => navigate(flowUrl("/screening/basic/all/", id)),
        label: i18n.t("global.screening"),
        primary: true,
      };
    }
    return {
      id: "screeningLink",
      onClick: () => navigate(flowUrl("/screening/entity", id)),
      label: i18n.t("global.screening"),
      primary: true,
    };
    return {
      id: "screeningLink",
      onClick: () => navigate(flowUrl("/screening/entity", id)),
      label: i18n.t("global.screening"),
      primary: true,
    };
  };

  const renderResults = () => {
 
    const result = rootStore.flowStore.flowExportability;
 
    //const result = this.props.rootStore.exportabilityStore.screeningCountryResult

    return (
      result && (
        <div className={styles.messageBody}>
          <div className={styles.header}>
            <GridRow justify="space-between">
              <div className={styles.row}>
       

                {result.globalTrafficLightColor && (
                  <img
                    className={styles.traficLight}
                    src={require(
                      `../../../../statics/images/global-traffic-${result.globalTrafficLightColor.toLowerCase()}.svg`
                    )}
                    alt={"traficLight"}
                  />
                )}

                <div>
                  <Title tag="h2">
                    {i18n.t("pages.exportability.results.message.title")}
                  </Title>
                  <Title tag="h4">
                    <p className={styles.arrow}>
                      {result.departureCountryName}
                      <ArrowIcon />
                      {result.destinationCountryName}
                    </p>
                  </Title>
                </div>
              </div>
            </GridRow>
          </div>

          {renderResult("", result.generalScreeningDetails,result.departureCountryName)}
        </div>
      )
    );
  };

  const renderResult = (title: string, results: ScreeningDetails[],departure:string) => {
    return (
      results.length > 0 && (
        <div className={styles.results}>
          <Title tag="h3">{title}</Title>
          {renderListScreeningcorporate(results)}
          {renderListScreeningNT(results,departure)}
      
          <br />
          {renderListScreeningsanction(results)}
        </div>
      )
    );
  };

  const renderSubResult = (title: string, results: ScreeningDetails[],departure:string) => {
    return (
      results.length > 0 && (
        <div className={styles.subResults}>
          <Title tag="h5">{title}</Title>

          {renderListScreeningNT(results,departure)}
        </div>
      )
    );
  };

  const renderListScreeningNT = (results: ScreeningDetails[],departure:string) => {
    let hasNationalClassif = false;
for (const result of results) {
  if (result.eccnCode.includes("National classif")) {
    hasNationalClassif = true;
    break;
  }
}
    return results
      .filter((result) => result.exportabilityRule !== "RULE_SANCTIONED")
      .filter((result) => !result.eccnCode.includes("Sanction"))
      .filter((result) => !result.eccnCode.includes("U.S license exceptions"))
       .sort((a, b) => {
    if (hasNationalClassif) {
      // Si un eccnCode contient "(National classif)", inverser l'ordre de tri
      return ( a.eccnCode>b.eccnCode ) ? 1 : -1;
    } else {
      return (b.eccnCode > a.eccnCode) ? 1 : -1;
    }
  })
      .map((result, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <img
                className={styles.traficLight}
                style={{ width: "30px", paddingRight: "5px" }}
                src={require(
                  `../../../../statics/images/traffic-${result.trafficLightColor.toLowerCase()}.svg`
                )}
                alt={"traficLight"}
              />

              {result.eccnCode}
            </AccordionSummary>
            <AccordionDetails>
              <ScreeningResult
                rootStore={rootStore}
                destination={departure || ""}
                pdebut={result.destinationCountry || ""}
                result={result}
              />
            </AccordionDetails>
          </Accordion>
        );
      });
  };
  const renderListScreeningUS = (results: ScreeningDetails[]) => {
    return results
      .filter((result) => result.exportabilityRule !== "RULE_SANCTIONED")
      .filter((result) => !result.eccnCode.includes("Sanction"))
      .filter((result) => result.usOrigin)
      .map((result, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-us${index}-content`}
              id={`panel-us${index}-header`}
            >
              <img
                className={styles.traficLight}
                style={{ width: "30px", paddingRight: "5px" }}
                src={require(
                  `../../../../statics/images/traffic-${result.trafficLightColor.toLowerCase()}.svg`
                )}
                alt={"traficLight"}
              />

              {result.eccnCode}
            </AccordionSummary>
            <AccordionDetails>
              <ScreeningResult
                rootStore={rootStore}
                destination={result.departureCountry || ""}
                pdebut={result.destinationCountry || ""}
                result={result}
              />
            </AccordionDetails>
          </Accordion>
        );
      });
  };
  const renderListScreeningsanction = (results: ScreeningDetails[]) => {
    return results
      .filter((result) => result.eccnCode.includes("Sanction"))
      .map((result, index) => {
        return (
          <ScreeningResult
            rootStore={rootStore}
            destination={result.departureCountry || ""}
            pdebut={result.destinationCountry || ""}
            key={index}
            result={result}
          />
        );
      });
  };
  const renderListScreeningcorporate = (results: ScreeningDetails[]) => {
    return results
      .filter((result) => result.exportabilityRule === "RULE_SANCTIONED")
      .map((result, index) => {
        return (
          <ScreeningResult
            rootStore={rootStore}
            destination={result.departureCountry|| ""}
            pdebut={result.destinationCountry || ""}
            key={index}
            result={result}
          />
        );
      });
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="EXPORTABILITY" />

      {renderResults()}

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigate(flowUrl("/classification", id)),
            label: i18n.t("global.previous"),
          },
          getScreeningButton(),
          {
            id: "licensing",
            onClick: () => navigate(flowUrl("/license/matching", id)),
            label: i18n.t("global.licensing"),
            primary: true,
          },
        ]}
      >
        <TimeLine step={1} rootStore={rootStore} />
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultsFlow));
