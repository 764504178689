/* Styles import */
import styles from "../admin.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  FormGroup,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  SearchInput,
  Loading,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import EditIcon from "@material-ui/icons/Create";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import CountryEdit from "../../../components/countryEdit";
import { getToLocaleDate } from "../../../utils/date";
import ProgressUpload from "../../../components/progressUpload";
import configuration from "../../../configuration";
import axios from "axios";

interface State {
  search: string;
  selectedCountry?: Country;
  filename: string;
  file_date: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const ScreeningTenant: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    search: "",
    filename: "",
    file_date: "",
  });

  const handleSubmit = async (file: File) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();

        if (file) {
          data.append("file", file);
          data.append("id_tenant", rootStore.userStore.user?.tenantId || "");
        }
        rootStore.controlReasonsStore.classificationDatabaseState = "PENDING";
        const response = await axios({
          method: "POST",
          url:
            `${process.env.REACT_APP_SCREEN}/screening_tenant?id_tenant=` +
            rootStore.userStore.user?.tenantId,
          headers: {
            "content-type": "multipart/form-data",
          },

          data,
        });

        resolve(response.data);
        rootStore.controlReasonsStore.classificationDatabaseState = "DONE";
        rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.upload.notification.success.title"),
          i18n.t("pages.admin.upload.notification.success.message"),
          "success",
        );
      } catch (error) {
        rootStore.controlReasonsStore.classificationDatabaseState = "FAIL";

        reject(error);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const policy = await api.get_screening_file_tenant(
        rootStore.userStore.user?.tenantId || "",
      );

      if (policy) {
        setState({
          ...state,
          file_date: policy[0].date_maj,
          filename: policy[0].file_name,
        });
      }

      rootStore.controlReasonsStore.classificationDatabaseState = "INITIALIZE";
    };

    fetchData();
  }, []);

  const { search } = state;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="SCREENING_TENANT" rootStore={rootStore} />

      {rootStore.userStore.isAuthorized([
        "ADMINISTRATOR_GROUP",
        "C_E_ADMIN",
        "ADMINISTRATOR_SITE",
      ]) && (
        <Card className={styles.body}>
          <h4>Custom screening :</h4>
          <div style={{ marginLeft: "20px" }}>
            <FormGroup>
              <ProgressUpload
                onChange={(file) => handleSubmit(file)}
                progress={
                  rootStore.controlReasonsStore.classificationDatabasePourcent
                }
                uploadState={
                  rootStore.controlReasonsStore.classificationDatabaseState
                }
                label={"Upload the Custom screening file :"}
                subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename}`}
              />
            </FormGroup>
          </div>
        </Card>
      )}
    </div>
  );
};

export default inject("rootStore")(observer(ScreeningTenant));
