/* Global import */
import axios, { AxiosError } from "axios";
import i18n, { StringMap } from "i18next";
import findCountryByCode from "../stores/countriesStore";
/* Custom import */
import configuration from "../configuration";
import { generateQueryString } from "./generator";
import { LRegulation } from "./constant";
import moment from "moment";
type PeopleType = {
  id: string;
  Departure: string;
  Destination: string;
  Org: string;
  Light: string;
  Active: string;
  Message: string;
  Link_text: string;
  Link: string;
  Link_text1: string;
  Link1: string;
  Link_text2: string;
  Link2: string;
};
type PeopleType1 = { message: string; feu: string };
type PeopleType3 = { file_name: string; date_maj: string };

type MsgType = {
  code_msg: string;
  Regle: string;
  short_en: string;
  short_fr: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};
type numflow = {
  order_num: string;
  delivery_date: string;
  commande_date: string;
  num_licence_export: string;
  num_licence_import: string;
  num_licence_us: string;
};
type eccnclassification = {
  id: string;
  part_number: string;
  business_unit: string;
  product: string;
  country: string;
  classification: string;
  hs_code: string;
  made:string;
  tenant: string;
  author: string;
  date_chargement: string;
};

type LicenseUpdatePayload = {
  license_id: number;
  equipment_id: number;
  qtyRemaining: number;
  amountRemaining: number;
  amountEquip: number;
  quantityEquip: number;
};

export default class Api {
  /*
   * Auth API
   */
  public login(email: string, password: string): Promise<AuthResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/public/auth/login`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            email,
            password,
          },
        });

        resolve({ token: response.headers.authorization, user: response.data });
      } catch (error) {
        reject(i18n.t("errors.login-fail"));
      }
    });
  }

  public async checkIsLoged(token: string): Promise<AuthResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/auth/login`,
          headers: {
            "content-type": "application/json",
            authorization: token,
          },
        });

        resolve({ token: response.headers.authorization, user: response.data });
      } catch (error) {
        reject("");
      }
    });
  }

  public async logout(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await axios({
          method: "get",
          url: `${configuration.API}/auth/logout`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Eccn
   */
  public async searchEccn(
    keyword: string,
    country: EccnCountry,
    itemType: ItemType
  ): Promise<Eccn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/eccn/search?country=${country}&keywords=${keyword}&itemType=${itemType}`,
          headers: {
            "content-type": "application/json",
          },
          timeout: 30000, // Délai d'attente de 30 secondes
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async deepSearchEccn(
    code: string,
    regulation: string
  ): Promise<Eccn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/eccn/deep_search?code=${code}&regulation=${regulation}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public async searchClassificationCustom(
    keyword: string,
    tenant: string,
    country: EccnCountry
  ): Promise<eccnclassification[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/get_classificationCustom?tenant=${tenant}&country=${country}&partNumber=${keyword}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public async searchClassification(
    keyword: string,
    tenant: string,
    country: EccnCountry
  ): Promise<eccnclassification[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/get_classification?tenant=${tenant}&country=${country}&partNumber=${keyword}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Admin user
   */

  public async getUsers(): Promise<User[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/users`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public async getUsersRoutes(userId: number): Promise<String[]> {
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SCREEN}/accessible-routes?id=${userId}`,
        headers: {
          "content-type": "application/json",
          //'Access-Control-Allow-Origin': '*', // Allow connection with external domain-name
        },
      });
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des routes :", error);
      throw error;
    }
  }

  public async getSites(): Promise<Site[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/sites`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async updateuserentite(
    userId: number,
    entite: string
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/updateuserentite?userid=${userId}&entite=${entite}`,
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async updateUser(user: User): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/users/${user.id}`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            role: user.role,
            siteId: user.siteId,
            tenantId: user.tenantId,
            expiration_date: user.expiration_date,
            departure_country: user.departure_country,
          },
        });

        resolve(response.status === 200);
      } catch (error) {
        reject(error);
      }
    });
  }

  public get_file_eccn_exclus_file(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_file_eccn_exclus_file`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_file_french_eccn(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_file_french_eccn`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_file_codes_Russia_file(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_file_codes_Russia_file`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public get_file_codes_exceptions_file(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_file_codes_exceptions_file`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public get_norway_file(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_norway_file`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_screening_file_tenant(id_tenant: string): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/get_screening_file_tenant?id_tenant=` +
            id_tenant,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public get_screening_file(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_screening_file`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_screening_file_fr(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_screening_file_fr`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_screening_file_canada(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_screening_file_canada`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_screening_file_uk(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_screening_file_uk`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_alias_screening_file(): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_alias_screening_file`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public get_policy_file(id_tenant: string): Promise<PeopleType3[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/get_policy_file?id_tenant=` +
            id_tenant,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Countries
   */
  public getAutoEU(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/AutoEU`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public getSanctionEU(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/sanctionEU`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getSanctionUS(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/sanctionUS`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //******************* */

  public getSanctionUN(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/sanctionUN`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public async getCountries(): Promise<Country[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/countries`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public async gettimereplication(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/gettimereplication`,
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async savetimereplication(terme: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/updatetime?run_time=${terme}`,
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getSynonym(terme: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/Get_synonym?terme=${terme}`,
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getDepartureCountry(): Promise<Country[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SCREEN}/departureCountry`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getControlCategoriesByCountry(
    id: number
  ): Promise<CountryControlCategory[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/countries/${id}/control-categories`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getControlCategoriesSanctionsByCountry(
    id: number
  ): Promise<CountryControlSanctionCategory> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/control-categories/corporate-sanction/${id}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async setCountryControlCategory(
    countryId: number,
    controlCategoryIds: number[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await axios({
          method: "put",
          url: `${configuration.API}/countries/${countryId}/control-categories`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            controlCategoryIds,
          },
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
  public async setCountryControlCategorySanction(
    countryId: number,
    active: boolean
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await axios({
          method: "put",
          url: `${configuration.API}/countries/corporate-sanctions`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            countryId,
            active,
          },
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Screening
   */

  public async checkCountry(
    countryOfOriginId: number,
    countryOfDestinationId: number,
    eccns: ClassificationEccn[]
  ): Promise<ScreeningCountryResultDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/screenings/compute`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            countryOfOriginId,
            countryOfDestinationId,
            eccns,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Document
   */

  public async getDocument(documentName: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/documents/${documentName}`,
          responseType: "arraybuffer",
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Classification
   */

  public async postClassification(
    classification: File,
    division: string
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();

        if (classification) {
          data.append("file", classification);
        }
        const response = await axios({
          method: "post",
          url: `${configuration.API}/classification?division=${division}`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data,
        });

        resolve(response.data);
      } catch (error) {
        reject("error");
      }
    });
  }

  /*
   * Control Reasons
   */

  public async uploadReasons(
    file: File,
    fileType: FileType,
    onProgress: (pourcent: number) => void
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        let methode = "PUT";
        let url = configuration.API;

        switch (fileType) {
          case "COUNTRY_CONTROL_REASON":
            url += "/countries/control-reasons";
            break;
          case "ECCN_CONTROL_REASON":
            url += "/eccns/control-reasons";
            break;
          case "FRENCH_ECCN":
            url = process.env.REACT_APP_SCREEN + "/French_eccn";
            methode = "POST";
            break;
          case "GERMAN_ECCN":
            url += "/germany/eccns";
            break;
          case "EU_ANNEX_IV":
            url += "/eu-annex-iv/eccns";
            break;
          case "ECCN_744_21":
            url += "/744-21/eccns";
            break;
          case "AIRBUS_MODEL":
            url += "/airbus-models";
            break;
          case "ECCN_EXCLUS":
            url = process.env.REACT_APP_SCREEN + "/Eccn_exclus";
            methode = "POST";
            break;
          case "ECCN_RUSSIA":
            url = process.env.REACT_APP_SCREEN + "/codes_Russia";
            methode = "POST";
            break;
          case "ECCN_exceptions":
            url = process.env.REACT_APP_SCREEN + "/codes_exceptions";
            methode = "POST";
            break;
          case "NORWAY_SANCTION":
            url = process.env.REACT_APP_SCREEN + "/Norway_sanction";
            methode = "POST";
            break;
          case "SCREENING_EU":
            url = process.env.REACT_APP_SCREEN + "/Screening_eu";
            methode = "POST";
            break;
          case "SCREENING_CANADA":
            url = process.env.REACT_APP_SCREEN + "/Screening_canada";
            methode = "POST";
            break;

          case "SCREENING_FR":
            url = process.env.REACT_APP_SCREEN + "/Screening_fr";
            methode = "POST";
            break;

          case "SCREENING_UK":
            url = process.env.REACT_APP_SCREEN + "/Screening_uk";
            methode = "POST";
            break;

          case "ALIAS_SCREENING":
            url = process.env.REACT_APP_SCREEN + "/Alias_screening";
            methode = "POST";
            break;

          case "SCREENING_TENANT":
            url = process.env.REACT_APP_SCREEN + "/screening_tenant";
            methode = "POST";
            break;

          default:
            break;
        }

        data.append("file", file);
        if (methode === "POST")
          await axios({
            method: "POST",
            url: url,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) =>
              onProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
            data,
          });
        else
          await axios({
            method: "PUT",
            url: url,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) =>
              onProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
            data,
          });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getUploadVersionApi(
    fileType: FileType
  ): Promise<FileVersion | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/files/info?fileUploadType=${fileType}`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getCountryByCode(countryCode: EccnCountry): Promise<Country> {
    return new Promise(async (resolve, reject) => {
      let tcountry: string;

      if (countryCode == "UK") tcountry = "GB";
      else tcountry = countryCode;
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/countries/${tcountry}`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error: any) {
        if (error.response) {
          reject({ key: "errors.server", code: error.response.data.messages });
        } else {
          reject({ key: "errors.network" });
        }
      }
    });
  }

  public async getModels(productType: ProductType): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/airbus-models/model?productType=${productType}`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getModelsWithoutProduct(): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/airbus-models/allModels`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getEccnsByModel(
    departureCountryId: number,
    model: string
  ): Promise<ModelEccn> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/airbus-models/eccns?departureCountryId=${departureCountryId}&model=${model}`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getTypes(productType?: ProductType): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${configuration.API}/airbus-models/${
          productType ? "option" : "equipment"
        }-types`;

        if (productType && productType !== "EQUIPMENT") {
          url += `?itemType=${productType}`;
        }

        const response = await axios({
          method: "get",
          url,
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getOptionModels(
    grouping: string,
    productType?: ProductType
  ): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          url: `${configuration.API}/airbus-models/option-model?grouping=${grouping}&productType=${productType}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async saveFlow(flow: FormFlowDto): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/flows/create`,
          headers: {
            "content-type": "application/json",
          },
          data: flow,
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateflow(
    Id: number,
    order_num: string,
    delivery_date: string,
    commande_date: string,
    num_licence_export: string,
    num_licence_import: string,
    num_licence_us: string,
    has_ml_eccn: number,
    has_du_eccn: number,
    has_itar_eccn: number,
    has_ear_eccn: number,
    has_nc_eccn: number,
    has_ear99_eccn: number,
    has_hs_code?: number
  ): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/updateflow?id=` +
            encodeURIComponent(`${Id}`) +
            `&order_num=${order_num}` +
            `&delivery_date=${delivery_date}` +
            `&commande_date=${commande_date}` +
            `&num_licence_export=${num_licence_export}` +
            `&num_licence_import=${num_licence_import}` +
            `&num_licence_us=${num_licence_us}` +
            `&has_ml_eccn=${has_ml_eccn}` +
            `&has_du_eccn=${has_du_eccn}` +
            `&has_itar_eccn=${has_itar_eccn}` +
            `&has_ear_eccn=${has_ear_eccn}` +
            `&has_nc_eccn=${has_nc_eccn}` +
            `&has_ear99_eccn=${has_ear99_eccn}` +
            `&has_hs_code=${has_hs_code}`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            id: Id,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /** Filter matching licence */

  //********* Submit sanction  **************** */
  public searchLicenses(filters: {
    selectedDepartureCountry: number | undefined;
    selectedRegulation: LRegulation | undefined;
    selectedDestinationCountry: number | undefined;
    partNumber: string;
    endUser: string;
    classification: string;
    searchRegulation: string;
    checkboxStates: {
      "departure-country-checkbox": boolean;
      "regulation-checkbox": boolean;
      "destination-checkbox": boolean;
      "partnumber-checkbox": boolean;
      "enduser-checkbox": boolean;
      "classification-checkbox": boolean;
    };
  }): Promise<MatchingLicenseDto[]> {
    // Change string to MatchingLicenseDto[]
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          searchRegulation: filters.searchRegulation, // Ajout de la nouvelle clé
          selectedDepartureCountry: filters.selectedDepartureCountry,
          selectedRegulation: filters.selectedRegulation?.value,
          selectedDestinationCountry: filters.selectedDestinationCountry,
          partNumber: filters.partNumber,
          endUser: filters.endUser,
          classification: filters.classification,
          checkboxStates: filters.checkboxStates,
        };

        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/searchLicenses`,
          headers: {
            "content-type": "application/json",
          },
          data,
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public archiveflow(Id: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/archiveflow?id=` +
            encodeURIComponent(`${Id}`),
          headers: {
            "content-type": "application/json",
          },
          data: {
            id: Id,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async submitFlow(flow: FormFlowDto): Promise<ResponseSubmitFlow> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/flows/compute`,
          headers: {
            "content-type": "application/json",
          },
          data: flow,
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getEntities(
    company: string,
    countryId: number,
    nationalId?: string
  ): Promise<Entity[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/screenings/entity`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            companyName: company,
            countryId,
            nationalId,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntitiesfinale(company: string): Promise<ApiGetBasicfinale> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screenEntite?companyName=` +
            encodeURIComponent(`${company}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntitieseu(company: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screen_eu?companyName=` +
            encodeURIComponent(`${company}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntitiesah(
    company: string,
    id_tenant: string
  ): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screen_ah?companyName=` +
            encodeURIComponent(`${company}`) +
            `&id_tenant=` +
            encodeURIComponent(`${id_tenant}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
            id_tenant: id_tenant,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntitiesfr(company: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screen_fr?companyName=` +
            encodeURIComponent(`${company}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntitiesuk(company: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screen_uk?companyName=` +
            encodeURIComponent(`${company}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntitiescanada(company: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screen_canada?companyName=` +
            encodeURIComponent(`${company}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getBasicEntities(
    company: string,
    countryId: number
  ): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/screen?companyName=` +
            encodeURIComponent(`${company}`) +
            `&coutryid=${countryId}`,
          headers: {
            "content-type": "text/plain",
          },
          data: {
            companyName: company,
            countryId,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public submitDesactive(userId: number): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url:
            `${process.env.REACT_APP_SCREEN}/user/` +
            encodeURIComponent(`${userId}`),
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public submitDesactiveTenant(userId: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/destenant?userId=` +
            encodeURIComponent(`${userId}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            userId: userId,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public FlowDelete(userId: number): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/flowdelete?id_user=` +
            encodeURIComponent(`${userId}`),
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public submitDelete(userId: number): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/user1?id_user=` +
            encodeURIComponent(`${userId}`),
          headers: {
            "content-type": "text/plain",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public submitDeleteTenant(userId: string): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/deltenant?userId=` +
            encodeURIComponent(`${userId}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            userId: userId,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public licenceDelete(userId?: number): Promise<ApiGetBasic> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/DelLicence?id=` +
            encodeURIComponent(`${userId}`),
          headers: {
            "content-type": "text/plain",
          },
          data: {
            userId: userId,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public screeningBasic(
    hasSanction: boolean,
    flowId: number = 0,
    source: string = "",
    name: string = ""
  ): Promise<ScreeningEntityResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/screenings/basic/compute`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flowId,
            hasSanction,
            source,
            name,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public screeningEntity(
    bvdId: string,
    name: string,
    flowId: number = 0
  ): Promise<ScreeningEntityResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/screenings/entity/compute`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flowId,
            bvdId,
            entityName: name,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async newGetFlowById(id: string): Promise<Flow> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SCREEN}/getFlowById`,
          {
            params: {
              id: id,
            },
            headers: {
              "content-type": "application/json",
            },
          }
        );

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getFlowById(flowId: number): Promise<FlowDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/flows/${flowId}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public screeningEndUser(
    firstName: string,
    lastName: string,
    flowId: number = 0
  ): Promise<ScreeningEndUserDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "put",
          url: `${configuration.API}/screenings/enduser/compute`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            firstName,
            lastName,
            flowId,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async postLicense(
    license: PostLicenseDto,
    ...licenseFiles: (File | undefined)[]
  ): Promise<number> {
    try {
      const data = new FormData();
      licenseFiles.forEach((file) => {
        if (file && file.size > 0) {
          const fileName = file.name === "blob" ? "licence.jpg" : file.name;
          data.append("file", file, fileName); // Utilisez toujours 'file' comme clé
        }
      });

      data.append("data", JSON.stringify(license));

      const response = await axios.post(
        `${configuration.API}/licenses/create`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.licenseId;
    } catch (error) {
      console.error("Erreur lors de la publication de la licence:", error);
      throw error;
    }
  }

  public searchFlow(params: SearchFlowParams): Promise<SearchFlowDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const urlParams = generateQueryString(params);
        //alert(urlParams)
        const response = await axios({
          method: "get",
          url: `${configuration.API}/flows?${urlParams}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public searchLicense(
    page: number,
    regulation?: Regulation,
    destinationCountry?: number,
    endUser?: string
  ): Promise<SearchLicenseDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url:
            `${configuration.API}/licenses?pageNumber=${page}&` +
            `destinationCountry=${destinationCountry || ""}&endUser=${
              endUser || ""
            }&regulation=${regulation || ""}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public searchLicenseById(id: number): Promise<PostLicenseDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/license/${id}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public submitClassificationDatabase(
    file: File,
    type: string,
    tenant: string,
    author: string,
    choix_file: string,
    onProgress: (pourcent: number) => void
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();

        if (file) {
          data.append("file", file);
        }

        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/classification_base?division=${type}&tenant=${tenant}&author=${author}&choix_file=${choix_file}`,
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) =>
            onProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            ),
          data,
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*
   * Admin tenants
   */

  public async getTenants(): Promise<TenantItemReceived[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/tenants`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public submitTenant(method: axiosMethod, tenant: TenantItem) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();

        data.append(
          "data",
          JSON.stringify({
            id: tenant.id,
            name: tenant.name,
            erpAccess: tenant.erp,
            screening: tenant.screening,
          })
        );

        if (tenant.homepage) {
          data.append("file_homepage", tenant.homepage);
        }

        if (tenant.productline) {
          data.append("file_productline", tenant.productline);
        }

        const response = await axios({
          method,
          url: `${configuration.API}/tenants${
            method === "PUT" ? `/${tenant.name}` : ""
          }`,
          headers: {
            "content-type": "multipart/form-data",
          },
          data,
        });

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Matching
   */

  async getMatching(flowId: number): Promise<MatchingDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/flow/license/${flowId}`,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getnomficher(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/Fichiername?name=` + code,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  async getMedia(mediaId: string): Promise<File | undefined> {
    const response = await axios({
      method: "get",

      url: `${configuration.API}/medias/${mediaId}`,
      headers: {
        "content-type": "*/*",
      },
      responseType: "blob",
    });

    //const headerval = response.headers['content-disposition'];

    //  var filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
    var filename = await this.getnomficher(mediaId);
    const file = response.data;

    file.name = filename; // `license.${file.type.split('/')[1]}`
    file.lastModified = new Date();
    return file;
  }

  async getAssociableLicense(
    flowId: number,
    equipmentId: number
  ): Promise<MatchingLicenseDto[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "get",
          url: `${configuration.API}/flow/license/${flowId}/equipment/${equipmentId}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  async sendLicenseUpdate(payload: LicenseUpdatePayload) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SCREEN}/imput-license`,
        payload
      );

      // Gestion de la réponse du serveur
      if (response.data && response.data.message) {
        console.log(response.data.message); // Afficher le message de réussite
      }
    } catch (error) {
      console.error("Error updating license:", error);
      const axiosError = error as AxiosError;
      if (
        axiosError &&
        axiosError.response &&
        axiosError.response.data &&
        axiosError.response.data.message
      ) {
        console.error("Server Error:", axiosError.response.data.message); // Afficher l'erreur du serveur
      }
    }
  }
  async saveLink(
    flowId: number,
    equipmentId: number,
    licenseId: number,
    type: string
  ): Promise<MatchingLinkedDto> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/link-equipment-license`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flowId,
            equipmentId,
            licenseId,
            type,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getLink(
    equipmentId: number,
    type: string
  ): Promise<MatchingLinkedDto[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/link-equipment-license/${equipmentId}/${type}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async deleteLink(
    flowId: number,
    equipmentId: number,
    licenseId: number,
    type: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_SCREEN}/unlink-equipment-license`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flowId,
            equipmentId,
            licenseId,
            type,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /* Altran
  async deleteLink(flowId: number, equipmentId: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await axios({
          method: "DELETE",
          url: `${configuration.API}/flow/license/${flowId}/link/${equipmentId}`,
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
  */

  public activateUser(
    id: number,
    password: string,
    confirmPassword: string
  ): Promise<AuthResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "PUT",
          url: `${configuration.API}/public/user/activate`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            id,
            password,
            confirmPassword,
          },
        });

        resolve({ token: response.headers.authorization, user: response.data });
      } catch (error) {
        reject(i18n.t("errors.login-fail"));
      }
    });
  }

  public checkActivatedUser(id: number, token: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "post",
          url: `${configuration.API}/public/users`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            id,
            token,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public addUser(
    firstname: string,
    lastname: string,
    tenantId: string,
    email: string,
    expiration_date: string
  ): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${configuration.API}/users`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            firstname,
            lastname,
            tenantId,
            email,
            expiration_date,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //*********************** update user lara */
  public updateuserlara(id_user: number, id_site: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/updateuserlara`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            id_user: id_user,
            id_site: id_site,
          },
        });

        resolve(response.status === 200);
      } catch (error) {
        reject(error);
      }
    });
  }

  //*********************** update user lara1 */
  public updateuserlara1(id_user: number, departure: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/updateuserlara1`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            id_user: id_user,
            departure: departure,
          },
        });

        resolve(response.status === 200);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* isEu006 retourne ok si pays depart est en eu006**************** */
  public isEu006(depart: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/isEu006`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            depart: depart,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //********* isEu005 retourne ok si pays depart est en eu005**************** */
  public isEu005(depart: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/isEu005`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            depart: depart,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* isEurope retourne ok si pays depart est en europe**************** */
  public isEurope(depart: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/isEurope`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            depart: depart,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //**********************isannexe            ******************** */
  public isAnnexe(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/isAnnexe?code=` +
            encodeURIComponent(code),
          headers: {
            "content-type": "application/json",
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* Submit msg exportabilite **************** */
  public PostMsg(
    code_msg: string,
    Regle: string,
    short_en: string,
    short_fr: string,
    Message: string,
    Message_fr: string,
    Lib_url1: string,
    Url1: string,
    Lib_url2: string,
    Url2: String,
    Lib_url3: string,
    Url3: String
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/PostMsg`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            code_msg: code_msg,
            Regle: Regle,
            short_en: short_en,
            short_fr: short_fr,
            Message: Message,
            Message_fr: Message_fr,
            Lib_url1: Lib_url1,
            Url1: Url1,
            Lib_url2: Lib_url2,
            Url2: Url2,
            Lib_url3: Lib_url3,
            Url3: Url3,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* Submit sanction  **************** */
  public PostSanction(
    id: string,
    Departure: string,
    Destination: string,
    Import: string,
    Org: string,
    Light: string,
    Active: string,
    Message: string,
    Link_text: string,
    Link: string,
    Link_text1: string,
    Link1: string,
    Link_text2: string,
    Link2: string
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/PostSanction`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            id: id,
            Departure: Departure,
            Destination: Destination,
            Import: Import,
            Org: Org,
            Light: Light,
            Active: Active,
            Message: Message,
            Link_text: Link_text,
            Link: Link,
            Link_text1: Link_text1,
            Link1: Link1,
            Link_text2: Link_text2,
            Link2: Link2,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //**************************ADD Sanction ********************* */
  //********* Submit sanction  **************** */
  public AddSanction(Departure: string, Destination: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/AddSanction`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            Departure: Departure,
            Destination: Destination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* Submit en vrac les données a la table log **************** */
  public AddLog(
    Client: string,
    User: string,
    Role: string,
    Action: string,
    Action_ID: string,
    Result: string,
    Departure_country: string,
    Destination_country: string,
    National_code: string,
    US_code: string,
    Lic_number: string,
    Lic_type: string,
    Info1: string,
    Info2: string,
    Data: string
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/AddLog`,
          headers: {
            "content-type": "application/json",
            //'Access-Control-Allow-Origin': '*',
          },
          data: {
            Client: Client,
            User: User,
            Role: Role,
            Action: Action,
            Action_ID: Action_ID,
            Result: Result,
            Departure_country: Departure_country,
            Destination_country: Destination_country,
            National_code: National_code,
            US_code: US_code,
            Lic_number: Lic_number,
            Lic_type: Lic_type,
            Info1: Info1,
            Info2: Info2,
            Data: Data,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //********** Getsanction */
  public GetSanction(
    Departure: string,
    Destination: string
  ): Promise<PeopleType[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/GetSanction?Departure=` +
            encodeURIComponent(Departure) +
            `&Destination=` +
            encodeURIComponent(Destination),
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********** GetsanctionImport */
  public GetSanctionImport(
    Departure: string,
    Destination: string
  ): Promise<PeopleType[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/GetSanctionImport?Departure=` +
            encodeURIComponent(Departure) +
            `&Destination=` +
            encodeURIComponent(Destination),
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  /********** recuparation message new version api */
  public readflow(id: number): Promise<numflow> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/readflow?id=` + id.toString(),
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //************ recuperation code exception */
  public get_code_exception(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/get_code_exception?code=` + code,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public get_code_french(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_code_french?code=` + code,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  /********** recuparation message new version api */
  public GcodeToMsg(code: string): Promise<MsgType> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/GcodeToMsg?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********** recuparation message */
  public CodeToMsg(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeToMsg?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public CodeTolLib1(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeTolLib1?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public CodeTolUrl1(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeTolUrl1?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public CodeTolLib2(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeTolLib2?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public CodeTolUrl2(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeTolUrl2?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public CodeTolLib3(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeTolLib3?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public CodeTolUrl3(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/CodeTolUrl3?code=` +
            code +
            `&lang=` +
            i18n.language,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* recuperation des log **************** */
  public getLogreplication(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_log_replication`,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public getLog(annee: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/GetLog?an=` + annee,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* recuperation des log **************** */
  public getLogTenant(tenant: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/GetLogTenant?tenant=` + tenant,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getTenantEntities(tenant: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/get_entite?tenant=` + tenant,
          headers: {
            "content-type": "text/plain",
          },
        });
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  //********** is norway sanction ****************** */
  public isNorwaySanction(paysDestination: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/isnorwaysanction`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //********* isEUEmabargo retourne ok si pays destination sous embargo EU **************** */
  public isEUEmabargo(paysDestination: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/iseuembargo`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //********** policy corporate  */
  public Postpolicy(
    countryid: number,
    tenantid: string,
    message: string,
    feu: string
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/Postpolicy`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            countryid: countryid,
            tenantid: tenantid,
            message: message,
            feu: feu,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public Getpolicy(
    countryid: number,
    tenantid: string
  ): Promise<PeopleType1[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/Getpolicy`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            countryid: countryid,
            tenantid: tenantid,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //*********  get_hs_codes_russia **************** */
  public get_hs_codes_russia(code: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/get_hs_codes_russia?code=` + code,
          headers: {
            "content-type": "application/json",
          },
          data: {},
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* isitarretourne ok si pays itar partiel ou total**************** */
  public isitar(paysDestination: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url:
            `${process.env.REACT_APP_SCREEN}/isitar?destination=` +
            paysDestination,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //*********************** is annexe IV */
  public Is_eu_annex_iv(paysDestination: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/Is_eu_annex_iv`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //********* is744 retourne ok si pays 744 **************** */
  public is744(paysDestination: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/is744`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  public is744_code(paysDestination: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/is744_code`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //******************************************* */

  //********* isUNEmabargo retourne ok si pays destination sous embargo EN **************** */
  public isUNEmabargo(paysDestination: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/isunembargo`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //***********************embargo us */
  public isUSEmabargo(paysDestination: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/isusembargo`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            destination: paysDestination,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //******************ReportingSweden************************* */
  public reporting_sweden(flows: FlowDto[]): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/reporting-country/sweden`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flows: flows,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //******************ReportingUk************************* */
  public reporting_uk(flows: FlowDto[]): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/reporting-country/uk`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flows: flows,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //******************writeexcel************************* */
  public writeexcel(flows: FlowDto[]): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/writeexcel`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flows: flows,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  //******************writeexcelgdga************************* */
  public writeexcel_DGA(flows: FlowDto[]): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/writeexcelDGA`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            flows: flows,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  //******************************************* */
  public EU001(
    paysDepart: number,
    paysDestination: number,
    code: string
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/EU001`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            pays_depart: paysDepart,
            destination: paysDestination,
            code: code,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public controle(categorie: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/controle`,
          headers: {
            "content-type": "application/json",
          },
          data: {
            categorie: categorie,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public sendSettingsCrawler(data: {
    textfield: string;
    pays: string;
    urlCible?: string;
    urlJSON?: string;
  }): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/sendSettingsCrawler`,
          headers: {
            "content-type": "application/json",
          },
          data: data,
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getTimeMailUSLE(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/getTimeMailUSLE`,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getTimeMailUK(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/getTimeMailUK`,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getTimeMailFR(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/getTimeMailFR`,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async getTimeMailCAN(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_SCREEN}/getTimeMailCAN`,
          headers: {
            "content-type": "application/json",
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  public exportUSLEtoXLS(): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SCREEN}/exportUSLEtoXLS`,
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        let formattedDate = moment().format("YYYY-MM-DD");
        link.setAttribute(
          "download",
          formattedDate + "_US_License_exceptions.xls"
        );

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        resolve(blob);
      } catch (error) {
        console.error("Erreur lors de l'export : ", error);
        reject(error);
      }
    });
  }
}
