/* Styles import */
import styles from "./autoComplete.module.css";

/* Global import */
import React, { useState, useEffect } from "react";
import { TextInput } from "../../components/horizon-components-react/src/components";

interface Props {
  label: string;
  onChange?: (value: any) => void;
  options: Option[];
  required?: boolean;
  errorText?: string;
  id?: string;
  value?: any;
  disabled?: boolean;
  returnLabel?: boolean;
}

const AutoCompleteComponent: React.FC<Props> = ({
  label,
  onChange,
  options,
  required,
  errorText,
  id,
  value,
  disabled,
  returnLabel,
}) => {
  const [search, setSearch] = useState("");
  const [isFinish, setIsFinish] = useState(false);

  useEffect(() => {
    if (value) {
      if (returnLabel) {
        setSearch(value);
        setIsFinish(true);
      } else {
        const option = options.find((_) => _.value === value);
        setSearch(option ? option.label : "");
        setIsFinish(true);
      }
    }
  }, [value, returnLabel, options]);

  const handleSearch = (value: string) => {
    setSearch(value);
    setIsFinish(false);
  };

  const handleChange = (option: Option) => {
    setIsFinish(true);

    if (onChange) {
      if (returnLabel) {
        onChange(option.label);
      } else {
        onChange(option.value);
      }
    }
  };

  return (
    <div className={styles.body}>
      <TextInput
        className={`${styles.input} ${styles.customWidthTextfield}`}
        id={id}
        type="text"
        label={label}
        value={search || ""}
        required={Boolean(required)}
        errorText={errorText}
        disabled={disabled}
        onChange={(e) => handleSearch(e.target.value)}
      />

      {search.length > 0 && !isFinish && (
        <ul className={styles.list}>
          {options
            .filter(
              (option) =>
                option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1,
            )
            .map((option, index) => (
              <li key={index} onClick={() => handleChange(option)}>
                {option.label}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteComponent;
