import React, { SFC, ChangeEvent } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import Step from "../Step";
import { StepProps } from "../Step/Step";

type Props = {
  className?: string;
  /** The active step (zero based index) */
  activeStep?: number;
  /** If `true`, sets the stepper to a horizontal orientation */
  horizontal?: boolean;
  /** Callback fired when the user clicks on a step. */
  onClick?: (value: any) => void;
};

const Stepper: SFC<Props> = ({
  className,
  activeStep = 0,
  horizontal = false,
  onClick,
  ...rest
}) => {
  let index = 0;
  const children = React.Children.map(rest.children, (child, idx) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    if (child.type !== Step) {
      return child;
      index++;
    }
    return React.cloneElement<StepProps>(
      child as React.ReactElement<StepProps>, // Convertissez child en ReactElement
      {
        onClick,
        done: index < activeStep,
        active: index === activeStep,
        index: index++,
      },
    );
  });
  return (
    <div
      className={cx(
        classes["ds-steppers"],
        {
          [classes["ds-steppers--horizontal"]]: horizontal,
          [classes["ds-steppers--vertical"]]: !horizontal,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

Stepper.defaultProps = {
  activeStep: 0,
  horizontal: false,
};

export default Stepper;
