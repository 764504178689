/* Styles import */
import styles from "./log.module.css";

/* Global import */
import React, {
  Component,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { FormLabel } from "@material-ui/core";
import {
  Card,
  CardHeader,
  Loading,
  SearchInput,
  GridContainer,
  GridRow,
  GridCol,
  TextInput,
  Button,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderReport from "../../../components/headerReport";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

interface State {
  start_time: string;
  end_time: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const LogReplication: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    start_time: "",
    end_time: "",
    myRef: {
      current: {
        visibleColumns: [],
        data: [],
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      await rootStore.tenantStore.getLogreplication();
    };
    fetchData();
  }, []);

  const dataSource = rootStore.tenantStore.datalogrep;

  const columns = [
    {
      name: "start_time",
      header: i18n.t("pages.admin.menu.hs"),
      minWidth: 150,
      defaultFlex: 2,
    },
    {
      name: "end_time",
      header: i18n.t("pages.admin.menu.hf"),
      minWidth: 100,
      defaultFlex: 2,
    },
    {
      name: "status",
      header: i18n.t("pages.admin.menu.re"),
      minWidth: 100,
      defaultFlex: 2,
    },
  ];
  const filterValue = [
    { name: "start_time", operator: "contains", type: "date", value: "" },
    { name: "end_time", operator: "contains", type: "date", value: "" },
    { name: "status", operator: "contains", type: "string", value: "" },
  ];
  const gridStyle = { minHeight: "500PX", marginTop: "15PX" };
  const SEPARATOR = ";";
  var dateJour = new Date();
  var afDate = Moment(dateJour).format("YYYY-MM-DD");
  const downloadBlob = (
    blob: any,
    fileName = afDate + " export control report.csv",
  ) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const exportXLS = () => {
    const columns = state.myRef.current.visibleColumns;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const header = columns.map((c: any) => c.header).join(SEPARATOR);
    const rows = state.myRef.current.data.map((data: any) =>
      columns.map((c: any) => data[c.id]).join(SEPARATOR),
    );

    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    const ws = XLSX.utils.json_to_sheet(dataSource);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, afDate + " server replication " + fileExtension);
  };

  const exportCSV = () => {
    const columns = state.myRef.current.visibleColumns;

    const header = columns.map((c: any) => c.header).join(SEPARATOR);
    const rows = state.myRef.current.data.map((data: any) =>
      columns.map((c: any) => data[c.id]).join(SEPARATOR),
    );

    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    downloadBlob(blob);
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      {rootStore.tenantStore.loading && <Loading />}

      <div className="d-flex align-items-center" style={{ marginTop: 30 }}>
        <Button
          className="mr-3"
          style={{ marginTop: 0, marginLeft: 50, display: "inline-block" }}
          onClick={exportXLS}
        >
          Export to xls
        </Button>
      </div>
      <ReactDataGrid
        onReady={(myRef) => {
          myRef = myRef;
        }}
        idProperty="id"
        nativeScroll={true}
        columns={columns}
        pagination="local"
        dataSource={dataSource}
        // defaultFilterValue={filterValue}
        defaultLimit={100}
        style={gridStyle}
      />
    </div>
  );
};

export default inject("rootStore")(observer(LogReplication));
