import React from "react";
import i18n from "i18next";
import { Sync } from "@material-ui/icons";

export const generateFlowId = (flowType?: FlowType, id?: number): string => {
  let labelType = "";

  switch (flowType) {
    case "NEW_SALE":
      labelType = "Sale_";
      break;
    case "SPARES":
      labelType = "Spare_";
      break;
    case "MRO":
      labelType = "MRO_";
      break;
    case "INTERCOMPANY":
      labelType = "Inter_";
      break;
    case "RETROFIT":
      labelType = "RETRO_";
      break;
    case "TEMPORARY":
      labelType = "Temp_";
      break;
    case "TECHNOLOGY":
      labelType = "Tech_";
      break;
    case "IMPORT":
      labelType = "Import_";
      break;
    default:
      labelType = "";
      break;
  }

  return `${labelType}${id || ""}`;
};

export const flowUrl = (pageUrl: string, flowId?: string) => {
  let url = "";

  if (flowId) {
    url = `/flow/${flowId}`;
  }

  url += pageUrl;

  return url;
};

export const licenseStatus = {
  ACTIVE: {
    color: "#20bf6b",
    text: "ACTIVE",
    url: "/add",
  },
  EXPIRED: {
    color: "#c92e39",
    text: "EXPIRED",
    url: "/add",
  },
  DELETED: {
    color: "#c92e39",
    text: "DELETED",
    url: "/add",
  },
  PENDING: {
    color: "#fcc438",
    text: "PENDING",
    url: "/add",
  },
  DRAFT: {
    color: "#dc6eca",
    text: "DRAFT",
    url: "/request",
  },
  RENEW: {
    color: "#ff8000",
    text: "RENEW",
    url: "/request",
  },
};

export const flowStatus = {
  CLASSIFICATION: {
    text: "CLASSIFICATION",
    color: "#0f7cba",
    url: "/classification",
  },
  BASIC_SCREENING: {
    text: "BASIC_SCREENING",
    color: "#834187",
    url: "/screening/basic/us",
  },
  ARCHIVED: {
    text: "ARCHIVED",
    color: "#834187",
    url: "/screening/basic/us",
  },
  BASIC_SCREENING_REJECTED: {
    text: "BASIC_SCREENING",
    color: "#834187",
    url: "/screening/basic/us/result",
  },
  EMBARGO: {
    text: "EMBARGO",
    color: "#c92e39",
    url: "/exportability/results",
  },
  ENTITY: {
    text: "ENTITY",
    color: "#834187",
    url: "/screening/entity",
  },
  ENTITY_OBTAIN: {
    text: "ENTITY_OBTAIN",
    color: "#19967d",
    url: "/screening/entity",
  },
  ENTITY_REJECTED: {
    text: "ENTITY_REJECTED",
    color: "#c92e39",
    url: "/screening/entity/result",
  },
  ENTITY_PENDING: {
    text: "ENTITY_PENDING",
    color: "#fcc438",
    url: "/screening/entity/result",
  },
  END_USER: {
    text: "END_USER",
    color: "#fcc438",
    url: "/screening/end-user",
  },
  END_USER_REJECTED: {
    text: "END_USER_REJECTED",
    color: "#fcc438",
    url: "/screening/end-user/result",
  },
  END_USER_PENDING: {
    text: "END_USER_PENDING",
    color: "#fcc438",
    url: "/screening/end-user/result",
  },
  LICENSING: {
    text: "LICENSING",
    color: "#45fe77",
    url: "/license/matching",
  },
  READY: {
    text: "READY",
    color: "#45fe77",
    url: "/license/matching",
  },
};

export const redirectFlow = (status: FlowStatus, flowId: number) => {
  let uri = "";
  const statusEnum = flowStatus[status];

  if (statusEnum) {
    uri = `/flow/${flowId}${flowStatus[status].url}`;
  } else {
    uri = `/flow/${flowId}/classification`;
  }

  return uri;
};

export const redirectFlowLicense = (status: LicenseStatus, flowId: number) => {
  let uri = "";
  const statusEnum = licenseStatus[status];

  if (statusEnum) {
    uri = flowId + licenseStatus[status].url;
  } else {
    uri = "license/search";
  }

  return uri;
};

export const generateSelectOption = (options: any[] | undefined) => {
  if (options) {
    const sortedOptions = options
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    return sortedOptions.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ));
  }

  return null;
};

export const generateQueryString = (object: { [key: string]: any }) => {
  const str = [];

  for (const key in object) {
    //alert(object[key])
    if (object[key] !== undefined && object[key] !== "") {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`);
    }
  }

  return str.join("&");
};

export const generateLicenseTypeOptions = (options: LicenseType[]) => {
  return options.map((option, index) => {
    if (option === "GLOBAL_OGEL") {
      return (
        <option key={index} value={"GLOBAL"}>
          {i18n.t(`global.license-type.${option}`)}
        </option>
      );
    }
    return (
      <option key={index} value={option}>
        {i18n.t(`global.license-type.${option}`)}
      </option>
    );
  });
};

export function formatNumber(number: number): string {
  return number?.toLocaleString("fr-FR");
}

export function unformatNumber(str: string): number {
  return Number(str.replace(/\s/g, ""));
}
