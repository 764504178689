/* Styles import */
import styles from "../admin.module.css";
import i18n from "i18next";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
} from "../../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ProgressUpload from "../../../components/progressUpload";
import { getToLocaleDate } from "../../../utils/date";
import Api from "../../../utils/api";
import axios from "axios";

interface State {
  filename: string;
  file_date: string;
  filename_exclus: string;
  file_date_exclus: string;
  filename_russia: string;
  file_date_russia: string;
}
interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const GermanEcc: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    filename: "",
    file_date: "",
    filename_exclus: "",
    file_date_exclus: "",
    filename_russia: "",
    file_date_russia: "",
    filename_exceptions: "",
    file_date_exceptions: "",
    filename_french_eccn: "",
    file_date_french_eccn: "",
    
  });

  // eslint-disable-next-line
  useEffect(() => {
    const fetchData = async () => {
      const policy2 = await api.get_file_codes_Russia_file();
      const policy3 = await api.get_file_codes_exceptions_file();
      const policy4 = await api.get_file_french_eccn();
      rootStore.controlReasonsStore.getUploadGermanEccnVersion();
      rootStore.controlReasonsStore.getUploadAnnexeVersion();
      rootStore.controlReasonsStore.getUploadEccnsVersion();
      rootStore.controlReasonsStore.getUploadECCN_EXCLUS();
      rootStore.controlReasonsStore.getUploadCountriesVersion();
      rootStore.controlReasonsStore.getUploadEccnsVersion();
      rootStore.controlReasonsStore.getUploadList744Version();

      const policy = await api.get_norway_file();
      if (policy) {
        setState({
          ...state,
          file_date: policy[0].date_maj,
          filename: policy[0].file_name,
        });
      }

      const policy1 = await api.get_file_eccn_exclus_file();
      if (policy1) {
        setState({
          ...state,
          file_date_exclus: policy1[0].date_maj,
          filename_exclus: policy1[0].file_name,
        });
      }

      if (policy2) {
        setState({
          ...state,
          file_date_russia: policy2[0].date_maj,
          filename_russia: policy2[0].file_name,
        });
      }
      if (policy3) {
        setState({
          ...state,
          file_date_exceptions: policy3[0].date_maj,
          filename_exceptions: policy3[0].file_name,
        });
      }
     if (policy4) {
        setState({
          ...state,
          file_date_french_eccn: policy4[0].date_maj,
          filename_french_eccn: policy4[0].file_name,
        });
      }
      
    };

    fetchData();
  }, []);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="EUROPEAN_RULES" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title="EU 2021/821 Annex II" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadEccnsExclus(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={`Upload the EU 2021/821 Annex II Letter I ECCNs`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${
              state.file_date_exclus
            }
                ${i18n.t("pages.admin.upload.filename")}: ${
                  state.filename_exclus
                }`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.eu-annexe.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadAnnexe(file)
            }
            progress={rootStore.controlReasonsStore.annexePourcent}
            uploadState={rootStore.controlReasonsStore.annexeState}
            label={i18n.t("pages.admin.eu-annexe.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${getToLocaleDate(
              rootStore.controlReasonsStore.annexeVersion.date
            )}
                ${i18n.t("pages.admin.upload.filename")}: ${
                  rootStore.controlReasonsStore.annexeVersion.fileName
                }`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title="French ECCN administration" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadFrenchEccns(file)
            }
            progress={rootStore.controlReasonsStore.frenchEccnPourcent}
            uploadState={rootStore.controlReasonsStore.frenchEccnState}
            label="Upload the French ECCNs table"
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                  ${getToLocaleDate(
                    state.file_date_french_eccn
                  )}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${state.filename_french_eccn}`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.german-eccn-reasons.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadGermanEccns(file)
            }
            progress={rootStore.controlReasonsStore.germanEccnPourcent}
            uploadState={rootStore.controlReasonsStore.germanEccnState}
            label={i18n.t("pages.admin.german-eccn-reasons.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                  ${getToLocaleDate(
                    rootStore.controlReasonsStore.germanEccnVersion.date
                  )}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${rootStore.controlReasonsStore.germanEccnVersion.fileName}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.norway-sanction.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.norwaysanction(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={i18n.t("pages.admin.norway-sanction.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename}`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title="EU 833/2014 – Sanctions on Russia " />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesRussia(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent4}
            uploadState={rootStore.controlReasonsStore.EccnExclusState4}
            label={`Upload the Russia prohibited HS codes`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${
              state.file_date_russia
            }
                ${i18n.t("pages.admin.upload.filename")}: ${
                  state.filename_russia
                }`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.control-reasons.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCountries(file)
            }
            progress={rootStore.controlReasonsStore.countriesPourcent}
            uploadState={rootStore.controlReasonsStore.countriesState}
            label={i18n.t("pages.admin.control-reasons.country-reasons")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${getToLocaleDate(
              rootStore.controlReasonsStore.countriesVersion.date
            )}
                ${i18n.t("pages.admin.upload.filename")}: ${
                  rootStore.controlReasonsStore.countriesVersion.fileName
                }`}
          />

          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.uploadEccns(file)}
            progress={rootStore.controlReasonsStore.eccnsPourcent}
            uploadState={rootStore.controlReasonsStore.eccnsState}
            label={i18n.t("pages.admin.control-reasons.eccn-reasons")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${getToLocaleDate(
              rootStore.controlReasonsStore.eccnsVersion.date
            )}
                  ${i18n.t("pages.admin.upload.filename")}: ${
                    rootStore.controlReasonsStore.eccnsVersion.fileName
                  }`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.list744.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadList744(file)
            }
            progress={rootStore.controlReasonsStore.list744Pourcent}
            uploadState={rootStore.controlReasonsStore.list744State}
            label={i18n.t("pages.admin.list744.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}:
                  ${getToLocaleDate(
                    rootStore.controlReasonsStore.list744Version.date
                  )}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${rootStore.controlReasonsStore.list744Version.fileName}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title="US license exceptions" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesexceptions(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent4}
            uploadState={rootStore.controlReasonsStore.EccnExclusState4}
            label={`Upload the US license exceptions`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${
              state.file_date_exceptions
            }
                ${i18n.t("pages.admin.upload.filename")}: ${
                  state.filename_exceptions
                }`}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(GermanEcc));
