import React, { ReactNode, ButtonHTMLAttributes, SFC } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type Props = {
  className?: string;
  /** Determines the color of the button */
  primary?: boolean;
  /** Renders a ghost button */
  ghost?: boolean;
  /** The size of the button */
  size?: "extra-large" | "large" | "medium" | "small" | "extra-small";
  /** TODO: Icon child autodetection */
  withIcon?: boolean;
  /** The content of the button */
  children?: ReactNode;
};

type ButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: SFC<ButtonProps> = ({
  primary,
  ghost,
  size,
  withIcon,
  children,
  className,
  ...rest
}) => (
  <button
    className={cx(
      classes["ds-button"],
      {
        [classes["ds-button--primary-1"]]: primary,
        [classes["ds-button--ghost"]]: ghost,
        [classes["ds-button--extra-large"]]: size === "extra-large",
        [classes["ds-button--large"]]: size === "large",
        [classes["ds-button--medium"]]: size === "medium",
        [classes["ds-button--small"]]: size === "small",
        [classes["ds-button--extra-small"]]: size === "extra-small",
        [classes["ds-button--with-icon"]]: withIcon,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </button>
);

Button.defaultProps = {
  primary: false,
};

export default Button;
