import React, { SFC, HTMLAttributes, ReactEventHandler } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type Props = {
  className?: string;
  /** Shadow depth */
  elevation?: 1 | 2 | 4 | 8;
  onClick?: ReactEventHandler<{}>;
};

type PaperProps = Props & HTMLAttributes<HTMLDivElement>;

const Paper: SFC<PaperProps> = ({
  elevation = 0,
  className,
  onClick,
  children,
}) => (
  <div
    className={cx(
      classes["ds-paper"],
      {
        [classes[`ds-paper--elevation${elevation}`]]: elevation !== 0,
      },
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Paper;
