import React, { SFC, ReactEventHandler } from "react";
import cx from "classnames";
import IconButton from "../IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classes from "../Dialog/styles.module.scss";

type Props = {
  /** Callback fired when the component wants to be closed. */
  onClose?: ReactEventHandler<{}>;
};

const DialogHeader: SFC<Props> = ({ onClose, children }) => (
  <div className={cx(classes["ds-modal__header"])}>
    {children}
    {onClose && (
      <IconButton className={classes["ds-button--close"]} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    )}
  </div>
);

export default DialogHeader;
