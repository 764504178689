import React, { SFC } from "react";
import cx from "classnames";
import classes from "../GridContainer/styles.module.scss";

type Props = {
  /**
   * Defines the `justify-content` style property.
   */
  justify?: "start" | "center" | "end" | "space-around" | "space-between";
  /**
   * Defines the `align-items` style property.
   */
  alignItems?: "start" | "center" | "end";

  noMargin?: boolean;
};

const GridRow: SFC<Props> = ({ justify, alignItems, children }) => (
  <div
    className={cx(classes["ds-row"], classes["ds-row-grid"], {
      [classes["ds-justify-content-start"]]: justify === "start",
      [classes["ds-justify-content-center"]]: justify === "center",
      [classes["ds-justify-content-end"]]: justify === "end",
      [classes["ds-justify-content-around"]]: justify === "space-around",
      [classes["ds-justify-content-between"]]: justify === "space-between",
      [classes["ds-align-items-start"]]: alignItems === "start",
      [classes["ds-align-items-center"]]: alignItems === "center",
      [classes["ds-align-items-end"]]: alignItems === "end",
    })}
  >
    {children}
  </div>
);

export default GridRow;
