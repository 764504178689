import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  IconButton,
  TextInput,
  Button,
  Loading,
} from "../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import i18n from "i18next";
import styles from "./login.module.css";
import axios from "axios";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}
interface State {
  username: string;
  password: string;
  showPassword: boolean;
}

const Login: React.FC<Props> = ({ rootStore }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (username && password) {
      rootStore.authStore.login(username, password);
    }
  };

  const handleClick = async () => {
    if (username) {
      rootStore.authStore.loading = true;
      rootStore.authStore.clearError();
      try {
        await axios
          .get(`${process.env.REACT_APP_SCREEN}/usersemail?email=` + username)
          .then((response) => {
            if (response.data !== 0) {
              alert(i18n.t("pages.activate.modif_password"));
            } else alert(i18n.t("pages.login.not_email"));
          })
          .catch((error) => {
            console.error("Erreur lors de la récupération des pays :", error);
          });
      } catch (error) {
        rootStore.authStore.setError(error as string);
      }
    }
    rootStore.authStore.loading = false;
  };

  useEffect(() => {
    if (rootStore.authStore.isLogged) {
      navigate("/");
    }
  }, [rootStore.authStore.isLogged, navigate]);

  return (
    <div className={styles.container}>
      <Card className={styles.cardLogin}>
        <CardHeader title={i18n.t("pages.login.title")} />

        <CardContent>
          <form id="login-form" onSubmit={onSubmit}>
            <FormGroup>
              <TextInput
                id="username"
                label={i18n.t("pages.login.username")}
                value={username}
                autoComplete="username"
                required={true}
                errorText={i18n.t("errors.username-not-defined")}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <div className={styles.row}>
                <TextInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  label={i18n.t("pages.login.password")}
                  value={password}
                  required={true}
                  errorText={i18n.t("errors.password-not-defined")}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <IconButton
                  style={{
                    marginLeft: "400px",
                    position: "absolute",
                    bottom: "0px",
                    right: "10px",
                  }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </div>
            </FormGroup>

            {rootStore.authStore.error && (
              <div className={styles.error}>
                <p>{rootStore.authStore.error}</p>
              </div>
            )}

            {rootStore.authStore.loading ? (
              <Loading />
            ) : (
              <Button id="button" type="submit" primary>
                {i18n.t("pages.login.signin")}
              </Button>
            )}
            <br />
            <a
              href="#"
              className={styles.moreDetails}
              id="moreDetails"
              onClick={(e) => handleClick()}
            >
              {i18n.t("pages.login.passwordforgot")}
            </a>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Login));
