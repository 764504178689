/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

/* Custom import */
import SubHeader from "../subHeader";

interface Props {
  pages: Pages;
}
type Pages = "SEARCH" | "ADD";

const headerFlowComponent = ({ pages }: Props) => {
  return (
    <SubHeader>
      <Link
        id="search-flow"
        className={`${styles.link} ${
          pages === "SEARCH" ? styles.selected : {}
        }`}
        to="/search-flow"
      >
        {i18n.t("pages.search-flow.title")}
      </Link>

      <Link
        id="new-flow"
        className={`${styles.link} ${pages === "ADD" ? styles.selected : {}}`}
        to="/new-flow"
      >
        {i18n.t("pages.new-flow.title")}
      </Link>
    </SubHeader>
  );
};

export default headerFlowComponent;
