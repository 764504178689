/* Styles import */
import styles from "../license.module.css";

/* Global import */
import React, { Component, Fragment, useEffect, useState } from "react";
import { Title } from "../../../components/horizon-components-react/src/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderLicense from "../../../components/headerLicense";
import MatchingLinkedComponent from "../../../components/matching/linked";
import MatchingEquipmentComponent from "../../../components/matching/equipment";
import MatchingLicenseComponent from "../../../components/matching/license";
import Loader from "../../../components/loader";
import TimeLine from "../../../components/timeLine";
import {
  flowUrl,
  formatNumber,
  unformatNumber,
} from "../../../utils/generator";
import Api from "../../../utils/api";
import { flow, toJS } from "mobx";
import clsx from "clsx";
import { regulation } from "../../../utils/constant";
import license from "../../../components/matching/license";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface State {
  selectedEquipmentId?: number;
  type?: string;
  qtyImput: { [key: string]: number };
  amountImput: { [key: string]: number };
  amountInputFormatted: { [key: string]: string };
  qtyActual: { [key: string]: string };
  qtyOrdered: string;
  qtyInitial: { [key: string]: string };
  qtyVraieInitial: string;
  amountInitial: string;
  amountOrdered: string;
  vertical: string;
  horizontal: string;
}

interface Props extends InjectedProps {}

interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}

const ImputLicence: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const api = new Api();
  const goBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState<boolean>(false);

  const { id, type } = useParams() as {
    id?: string;
    type?: string;
  };

  const [isNegative, setIsNegative] = useState<{ [key: string]: boolean }>({});

  const [state, setState] = useState<State>({
    vertical: "top",
    horizontal: "center",
    type: type ? type : "NATIONAL",
    qtyImput: {},
    amountImput: {},
    amountInputFormatted: {},
    qtyActual: {},
    qtyOrdered: "",
    qtyInitial: {},
    qtyVraieInitial: "",
    amountInitial: "" as string,
    amountOrdered: "" as string,
  });

  const { vertical, horizontal } = state;
  const [open, setSnackOpen] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [statusSnack, setStatusSnack] = useState(0);
  const handleClose = () => {
    setSnackOpen(false);
  };

  const renameKeys = (obj: any) => {
    const keyMap = {
      amountOrdered: "amount_ordered",
      amountDelivered: "amount_delivered",
      quantityOrdered: "quantity_ordered",
      quantityDelivered: "quantity_delivered",
      unitPrice: "unit_price",
    } as any;

    return Object.keys(obj).reduce((acc: any, key: any) => {
      const newKey = keyMap[key] || key;
      acc[newKey] = obj[key];
      return acc;
    }, {});
  };

  useEffect(() => {
    const fetch = async () => {
      Object.keys(state.qtyImput).forEach((key) => {
        let currentIsNegative =
          state.qtyImput[key] < 0 || state.amountImput[key] < 0;

        if (currentIsNegative !== isNegative[key]) {
          setIsNegative((prevIsNegative) => ({
            ...prevIsNegative,
            [key]: currentIsNegative,
          }));
        }
      });
      const flowId = id;
      const type = state.type ? state.type : "";
      let promises: Promise<any>[] = [];
      let initialAmountInputFormatted: { [key: string]: string } = {}; // Pour stocker les montants formatés
      let initialQtyImput: { [key: string]: number } = {};
      let initialAmountImput: { [key: string]: number } = {};

      if (flowId) {
        setLoading(true);
        rootStore.licenseStore.getMatching(parseInt(flowId));
        rootStore.licenseStore.matching?.flow.equipments.forEach(
          (equipment) => {
            if (
              (state.type === "NATIONAL" && equipment.nationalEccn) ||
              (state.type === "US" && equipment.usEccn)
            ) {
              initialQtyImput[equipment.id.toString()] = equipment.quantity;
              initialAmountImput[equipment.id.toString()] = equipment.amount;
              initialAmountInputFormatted[equipment.id.toString()] =
                formatNumber(equipment.amount);

              promises.push(
                rootStore.licenseStore.loadLinkedEquipment(equipment.id, type)
              );
            }
          }
        );

        // Attendre la résolution de toutes les promesses associées à loadLinkedEquipment
        await Promise.all(promises);
        let qtyVraieInitial = "";
        let qtyOrdered = "";
        let amountInitialLicence = "";
        let amountOrderedLicence = "";
        const matching = toJS(rootStore.licenseStore.matching);
        if (matching?.linked && matching.linked.length > 0) {
          matching.linked.forEach((link) => {
            const currLicence = renameKeys(link.license);
            qtyVraieInitial = currLicence.quantity.toString() || "";
            qtyOrdered = currLicence.quantity_ordered.toString() || "";
            amountInitialLicence = currLicence.amount_initial.toString() || "";
            amountOrderedLicence = currLicence.amount_ordered.toString() || "";

            const qtyInitial = currLicence.quantity_actual || 0;
            const amountInitial = currLicence.amount_actual || 0;
            const qtyImput = initialQtyImput[link.equipment.id.toString()] || 0;
            const amountImput =
              initialAmountImput[link.equipment.id.toString()] || 0;

            rootStore.licenseStore.initializeRemainingValues(
              link.equipment.id.toString(),
              qtyInitial,
              amountInitial,
              qtyImput,
              amountImput
            );
          });
        }
        setLoading(false);
        setState({
          ...state,
          qtyImput: initialQtyImput,
          amountImput: initialAmountImput,
          amountInputFormatted: initialAmountInputFormatted,
          qtyVraieInitial: qtyVraieInitial,
          qtyOrdered: qtyOrdered,
          amountInitial: amountInitialLicence,
          amountOrdered: amountOrderedLicence,
        });
      }
    };
    rootStore.countriesStore.getCountries();
    fetch();
  }, []);

  const handleSubmit = async (licence: MatchingDto) => {
    /*
    Quantité commandée = quantité commandée + quantité dans l’équipement du flux
    Montant commandé = montant commandé + montant dans l’équipement du flux
    */
    const payload = {
      license_id: 0,
      equipment_id: 0,
      qtyRemaining: 0,
      amountRemaining: 0,
      amountEquip: parseInt(state.amountOrdered),
      quantityEquip: parseInt(state.qtyOrdered),
    };

    licence.linked.forEach((item) => {
      payload.license_id = item.license_id;
      payload.equipment_id = item.equipment_id;
      payload.qtyRemaining = 0; // => correspond à "actual" pas besoin pour FR/ML
      payload.amountRemaining = 0; // => correspond à "actual" pas besoin pour FR/ML
      payload.amountEquip = payload.amountEquip + item.equipment.amount;
      payload.quantityEquip = payload.amountEquip + item.equipment.quantity;
    });
    try {
      await rootStore.licenseStore.sendLicenseUpdate(payload);
      setMessageNotification(i18n.t("notifications.form.success"));
      setStatusSnack(0);
      setSnackOpen(true);
      setTimeout(() => {
        goBack();
      }, 2000);
    } catch (error) {
      setMessageNotification(i18n.t("notifications.form.failure"));
      setStatusSnack(1);
      setSnackOpen(true);
    }

    setLoading(false);
    //navigate(flowUrl("/shipping", id));
  };

  const handleInputChange =
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const numericValue = unformatNumber(e.target.value);
      handleAmountChange(id)(numericValue);
    };
  const handleQtyChange = (link: any) => (event: any) => {
    const newValue = event.target.value;
    setState({
      ...state,
      qtyImput: { ...state.qtyImput, [link.equipment.id]: newValue },
    });

    const qtyInitial = link.license.quantity_actual || 0;
    const qtyRemaining = qtyInitial - newValue;

    rootStore.licenseStore.updateQtyRemaining(
      link.equipment.id.toString(),
      qtyRemaining
    );
  };

  const handleAmountChange = (link: any) => (event: any) => {
    const id = link.equipment.id.toString();
    const formattedValue = event.target.value;
    const numericValue = unformatNumber(formattedValue);

    setState((prevState) => ({
      ...prevState,
      amountImput: {
        ...prevState.amountImput,
        [id]: numericValue,
      },
      amountInputFormatted: {
        ...prevState.amountInputFormatted,
        [id]: formatNumber(numericValue),
      },
    }));

    const amountInitial = link.license.amount_actual || 0;
    let amountRemaining = amountInitial - numericValue;
    if (link.license.regulation === "FR_MUNITIONS_LIST") {
      amountRemaining = parseInt(state.amountInitial) - numericValue;
    }
    rootStore.licenseStore.updateAmountRemaining(id, amountRemaining);
  };

  const renderLinkTable = (link: any) => {
    let qtyInitial = link.license.quantity_actual || 0;
    let amountInitial = link.license.amount_actual || 0;

    const qtyImput = state.qtyImput[link.equipment.id.toString()] || 0;
    const amountImput = state.amountImput[link.equipment.id.toString()] || 0;

    let qtyRemaining =
      rootStore.licenseStore.qtyRemaining[link.equipment.id.toString()] || 0;
    const amountRemaining =
      rootStore.licenseStore.amountRemaining[link.equipment.id.toString()] || 0;

    // if (link.license.regulation === "FR_MUNITIONS_LIST") {
    //   amountInitial = parseInt("123") - parseInt("123");
    //   qtyRemaining =
    //     parseInt(state.qtyVraieInitial) - parseInt(state.qtyOrdered);
    // }

    const insufficient = qtyRemaining < 0 || amountRemaining < 0;

    const isQtyNegative = qtyRemaining < 0;
    const isAmountNegative = amountRemaining < 0;

    const formatNumberWithSpaces = (number: { toString: () => string }) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const amountRemainingFRML = (base: any, order: any, input: string) => {
      //return base - order - parseInt(input?.replace(/\s/g, ""));

      const inputNumber = parseInt(input?.replace(/\s/g, ""));

      // Calculer la différence et formater le résultat avec des séparateurs d'espaces pour chaque millier
      const result = base - order - inputNumber;
      const formattedResult = result.toLocaleString();

      return formattedResult;
    };

    return (
      <div style={{ marginTop: "5%" }}>
        <h2 style={{ textAlign: "center" }}>
          Licence : {link.license.license_number}
        </h2>
        <Table key={link.id}>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.designation")}</b>
              </TableCell>
              <TableCell align="center">
                <b>Part Number</b>
              </TableCell>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.actual_quantity")}</b>
              </TableCell>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.imput_quantity")}</b>
              </TableCell>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.left_quantity")}</b>
              </TableCell>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.actual_amount")}</b>
              </TableCell>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.imput_amount")}</b>
              </TableCell>
              <TableCell align="center">
                <b>{i18n.t("pages.license.imput.left_amount")}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={link.id}>
              <TableCell align="center">{link.equipment.model}</TableCell>
              <TableCell align="center">{link.equipment.part_number}</TableCell>
              <TableCell align="center">
                {" "}
                {link.license.regulation === "FR_MUNITIONS_LIST"
                  ? link.license.quantity_initial - link.license.quantityOrdered
                  : qtyInitial}
              </TableCell>
              <TableCell align="center">
                <TextField
                  type="number"
                  variant="outlined"
                  value={qtyImput}
                  onChange={handleQtyChange(link)}
                  InputProps={{
                    style: { height: "40px", width: "70px" },
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <span
                  className={
                    !isQtyNegative ? styles.cellPositive : styles.cellNegative
                  }
                >
                  {link.license.regulation === "FR_MUNITIONS_LIST"
                    ? link.license.quantity_initial -
                      link.license.quantityOrdered -
                      qtyImput
                    : qtyRemaining}
                </span>
              </TableCell>
              <TableCell align="center">
                <span>
                  {/* ini{link.license.amount_initial} - order
                  {link.license.amountOrdered} - */}
                  {link.license.regulation === "FR_MUNITIONS_LIST"
                    ? formatNumberWithSpaces(
                        parseInt(link.license.amount_initial) -
                          parseInt(link.license.amountOrdered)
                      )
                    : link.license.amount_initial}
                  {/* truc qui marche juste pour FRML {formatNumberWithSpaces(
                    parseInt(link.license.amount_initial) -
                      parseInt(link.license.amountOrdered)
                  )} */}
                </span>
              </TableCell>
              <TableCell align="center">
                <TextField
                  type="text"
                  variant="outlined"
                  value={
                    state.amountInputFormatted[link.equipment.id.toString()] ||
                    ""
                  }
                  onChange={handleAmountChange(link)}
                  InputProps={{
                    style: { height: "40px", width: "170px" },
                  }}
                />
              </TableCell>

              <TableCell align="center">
                <span
                  className={
                    !isAmountNegative
                      ? styles.cellPositive
                      : styles.cellNegative
                  }
                >
                  {link.license.regulation === "FR_MUNITIONS_LIST"
                    ? amountRemainingFRML(
                        link.license.amount_initial,
                        link.license.amountOrdered,
                        state.amountInputFormatted[
                          link.equipment.id.toString().trim()
                        ]
                      )
                    : amountRemaining.toLocaleString()}
                  {/* {formatNumber(amountRemaining)} */}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {insufficient && (
          <div style={{ marginTop: "1%" }}>
            <p>
              {i18n.t("pages.license.imput.insufisant_quantity")}{" "}
              <span style={{ fontWeight: "bold" }}>
                {i18n.t("pages.license.imput.word_insufisant")}
              </span>
            </p>
            <p>{i18n.t("pages.license.imput.insufisant_quantity2")}</p>
          </div>
        )}
      </div>
    );
  };

  const matching = rootStore.licenseStore.matching;
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />
      <HeaderLicense pages="MATCHING" rootStore={rootStore} />
      {loading && <Loader />}
      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.license.imput.title-frml")}</Title>
        {/* <Title tag="h2">{i18n.t("pages.license.imput.title")}</Title> */}
        <p>{i18n.t("pages.license.imput.textHeader")}</p>
        {matching?.linked && matching.linked.length > 0 ? (
          matching.linked.map((link) => renderLinkTable(link))
        ) : (
          <p>Aucun lien trouvé.</p>
        )}
      </div>
      {matching && matching?.flow.equipments.length >= 0 && (
        <Footer
          justify="flex-end"
          buttons={[
            {
              id: "previous",
              onClick: () => goBack(),
              label: i18n.t("global.previous"),
              //  disabled: !activeSaveButton(),
            },
            {
              id: "save",
              //onClick: () => handleSave(),
              onClick: () => handleSubmit(matching),
              label: i18n.t("global.save"),
              //  disabled: !activeSaveButton(),
            },
            {
              id: "next",
              onClick: () => {
                navigate(flowUrl("/shipping", id));
              },

              label: i18n.t("global.sub-expo-l_a"),

              primary: true,
              //  disabled: !activeSubmitButtonShipping(),
            },
          ]}
        >
          <TimeLine step={3} rootStore={rootStore} />
          <Snackbar
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: state.vertical as any,
              horizontal: state.horizontal as any,
            }}
            open={open}
            onClose={handleClose}
            message=""
            key={vertical + horizontal}
          >
            <Alert
              onClose={handleClose}
              severity={statusSnack === 1 ? "error" : "success"}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {messageNotification}
            </Alert>
          </Snackbar>
        </Footer>
      )}
    </div>
  );
};

export default inject("rootStore")(observer(ImputLicence));
