import React, { SFC } from "react";
import Title from "../Title";

type Props = {};

const DialogTitle: SFC<Props> = ({ children }) => (
  <Title tag="h4" style={{ marginBottom: "24px" }}>
    {children}
  </Title>
);

export default DialogTitle;
