/* Styles import */
import styles from "./screeningResult.module.css";

/* Global import */
import React, { Component } from "react";
import {
  Title,
  Link,
  Button,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import { Tooltip } from "@material-ui/core";
import rootStore from "../../stores/rootStore";
import RootStore from "../../stores/rootStore";
interface Props {
  result: ScreeningDetails;
  destination: string;
  pdebut: string;
  rootStore: RootStore;
}

const ScreeningResult: React.FC<Props> = ({
  result,
  destination,
  pdebut,
  rootStore,
}) => {
  const bref_pays = (s: string) => {
    if (
      i18n.language === "fr-FR" ||
      i18n.language === "fr" ||
      i18n.language === "FR"
    ) {
      s = s.replace("Belgium", "belge");
      s = s.replace("Canada", "canadienne");
      s = s.replace("France", "française");
      s = s.replace("Germany", "allemande");
      s = s.replace("Spain", "espagnole");
      s = s.replace("Sweden", "suédoise");
      s = s.replace("Netherlands", "néerlandaise");
    } else {
      if (i18n.language.substring(0, 2).toUpperCase() === "DE") {
        s = s.replace("France", "Französische");
        s = s.replace("Germany", "Deutsche");
        s = s.replace("Spain", "Spanische");
      } else {
        s = s.replace("Belgium", "Belgian");
        s = s.replace("Canada", "Canadian");
        s = s.replace("France", "French");
        s = s.replace("Germany", "German");
        s = s.replace("Spain", "Spanish");
        s = s.replace("Sweden", "Swedish");
        s = s.replace("Netherlands", "Dutch");
      }
    }

    //console.log(props)

    return s;
  };
  return (
    <div className={styles.result}>
      <div
        className={`${styles.traficLightBox} ${
          styles[result.trafficLightColor.toLowerCase()]
        }`}
      ></div>

      <img
        className={styles.traficLight}
        src={require(
          `../../../statics/images/traffic-${result.trafficLightColor.toLowerCase()}.svg`,
        )}
        alt={"traficLight"}
      />

      <div>
        {destination != "IMPORT" &&
          !result.eccnCode.includes("Sanctions") &&
          result.exportabilityRule !== "RULE_SANCTIONED" &&
          result.exportabilityRule !== "RULE_744" &&
          result.exportabilityRule !== "REST_IMPORT" &&
          result.exportabilityRule !== "RUSSIA_NC" &&
          result.exportabilityRule !== "EXCEPTION" && (
            <Title className={styles.title} tag="h3">
              {!result.usOrigin &&
                (i18n.language !== "fr-FR" &&
                i18n.language !== "fr" &&
                i18n.language !== "FR"
                  ? bref_pays(
                      destination +
                        i18n.t("pages.exportability.results.applicable"),
                    )
                  : bref_pays(
                      i18n.t("pages.exportability.results.applicable") +
                        destination,
                    ))}
              {result.usOrigin &&
                (i18n.language !== "fr-FR" &&
                i18n.language !== "fr" &&
                i18n.language !== "FR"
                  ? `U.S. ` + i18n.t("pages.exportability.results.applicable")
                  : i18n.t("pages.exportability.results.applicable") + ` U.S.`)}
            </Title>
          )}

        {result.exportabilityRule === "RULE_SANCTIONED" && (
          <Title className={styles.title} tag="h3">
            Corporate policy
          </Title>
        )}
        {(result.exportabilityRule === "REST_IMPORT" ||
          result.exportabilityRule === "RUSSIA_NC" ||
          result.exportabilityRule === "EXCEPTION") && (
          <Title className={styles.title} tag="h3">
            {bref_pays(result.eccnCode)}
          </Title>
        )}

        {result.exportabilityRule === "RULE_744" && (
          <Title className={styles.title} tag="h3">
            {i18n.language !== "fr-FR" &&
            i18n.language !== "fr" &&
            i18n.language !== "FR"
              ? `U.S. Jurisdiction`
              : `Réglementation U.S.`}
          </Title>
        )}

        {destination != "IMPORT" &&
          result.eccnCode.includes("Sanctions") &&
          result.eccnCode.substr(0, 8) !== "Sanction" &&
          result.exportabilityRule !== "RULE_SANCTIONED" &&
          result.exportabilityRule !== "RULE_744" &&
          result.exportabilityRule !== "EXCEPTION" && (
            <Title className={styles.title} tag="h3">
              {bref_pays(result.eccnCode)}
            </Title>
          )}
        {destination != "IMPORT" &&
          result.eccnCode.includes("Sanctions") &&
          result.eccnCode.substr(0, 8) === "Sanction" &&
          result.exportabilityRule !== "RULE_SANCTIONED" &&
          result.exportabilityRule !== "RULE_744" && (
            <Title className={styles.title} tag="h3">
              {pdebut + ` ` + result.eccnCode}
            </Title>
          )}

        <p>
          {result.message && (
            <div
              className="post__content"
              dangerouslySetInnerHTML={{ __html: result.message }}
            ></div>
          )}
        </p>

        {result.controlReasonsForRule4 && (
          <div className={styles.reasons}>
            <label>{i18n.t("pages.exportability.results.reason")}</label>
            {result.controlReasonsForRule4.map((reason, index) => {
              return (
                <Tooltip key={index} title={reason.description}>
                  <div className={styles.badge}>{reason.code}</div>
                </Tooltip>
              );
            })}
          </div>
        )}

        {result.moreDetailsLink && (
          <Link
            href={result.moreDetailsLink}
            className={styles.moreDetails}
            id="moreDetails"
            target="_blank"
          >
            {result.moreDetailsLabel
              ? result.moreDetailsLabel
              : i18n.t("pages.exportability.results.message.more-details")}
          </Link>
        )}

        {result.additionalMessageLink && (
          <Link
            href={result.additionalMessageLink}
            className={styles.moreDetails}
            id="additionalMessage"
            target="_blank"
          >
            {result.additionalMessageLabel
              ? result.additionalMessageLabel
              : i18n.t("pages.exportability.results.message.more-details")}
          </Link>
        )}
        {result.additionalMessageLink1 && (
          <Link
            href={result.additionalMessageLink1}
            className={styles.moreDetails}
            id="additionalMessage"
            target="_blank"
          >
            {result.additionalMessageLabel1
              ? result.additionalMessageLabel1
              : i18n.t("pages.exportability.results.message.more-details")}
          </Link>
        )}
        {result.document && (
          <Button
            className="document"
            onClick={() =>
              rootStore.exportabilityStore.getDocument(result.document!.name)
            }
          >
            {`${i18n.t("pages.exportability.results.message.download")} ${
              result.document.label
            }`}
          </Button>
        )}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(ScreeningResult));
