import React, { useCallback, useEffect, useState } from "react";
import {
  GridCol,
  FormGroup,
  Checkbox,
  TextInput,
  Button,
  Card,
  CardHeader,
} from "../../components/horizon-components-react/src/components";
import { observer } from "mobx-react";
import i18n from "i18next";
import styles from "../../pages/license/license.module.css";
import { generateSelectOption } from "../../utils/generator";
import { FormLabel, FormControlLabel, CardContent } from "@material-ui/core";
import AutoCompleteComponent from "../autoComplete/autoCompleteComponent";
import { CCountry } from "../../utils/constant";

interface IProps {
  rootStore: any;
  onSearch: Function;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDestinationCountry: (destinationCountry: string) => void;
  handleRegulation: (regulationValue: string) => void;
  handleDepartureCountry: (departureCountry: string) => void;
  handlePartNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndUserChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClassifcationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRegulationOptions: (s: number) => void;
  loading: boolean;
  filteredLicenses: any[];
  selectedEquipmentId: number | undefined;
  selectedDepartureCountry: number | undefined;
  selectedRegulation: any;
  selectedDestinationCountry: number | undefined;
  partNumber: string;
  endUser: string;
  classification: string;
  checkboxStates: any;
  regulationOptions: any[];
  countries: CCountry[]; // le "?" rend cette prop optionnelle
}
const FiltersMatchingComponent = observer((props: IProps) => {
  const {
    rootStore,
    onSearch,
    handleCheckboxChange,
    handleDestinationCountry,
    handleRegulation,
    handleDepartureCountry,
    handlePartNumberChange,
    handleEndUserChange,
    handleClassifcationChange,
    handleRegulationOptions,
    loading,
    filteredLicenses,
    selectedEquipmentId,
    selectedDepartureCountry,
    selectedRegulation,
    selectedDestinationCountry,
    partNumber,
    endUser,
    classification,
    checkboxStates,
    regulationOptions,
    countries,
  } = props;

  return (
    <div className={styles.marginTop}>
      <Card className={styles.flowSection}>
        <CardHeader
          title={i18n.t("pages.license.matching.flowFilter")}
        ></CardHeader>
        <CardContent>
          <div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <GridCol md={6} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <div style={{ width: "190px" }}>
                        <Checkbox
                          id="departure-country-checkbox"
                          large
                          label={i18n.t(
                            "pages.exportability.departure-country"
                          )}
                          checked={checkboxStates["departure-country-checkbox"]}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <select
                        className={styles.widthTextfield}
                        id="departure-country"
                        value={selectedDepartureCountry || ""}
                        onChange={(e) => handleDepartureCountry(e.target.value)}
                      >
                        <option value="" disabled>
                          {i18n.t("pages.new-flow.select-value")}
                        </option>
                        {countries.map((country) => (
                          <option key={country.value} value={country.id}>
                            {country.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </FormLabel>
                </FormGroup>
              </GridCol>
              <GridCol md={6} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "190px" }}>
                        <Checkbox
                          id="regulation-checkbox"
                          large
                          label={i18n.t("pages.license.add.regulation")}
                          checked={checkboxStates["regulation-checkbox"]}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <select
                        className={styles.widthTextfield}
                        id="regulation"
                        value={selectedRegulation?.value || ""}
                        required={true}
                        onChange={(e) =>
                          handleRegulation(e.target.value as LicenseRegulation)
                        }
                      >
                        <option value="">
                          {i18n.t("pages.new-flow.select-value")}
                        </option>

                        {generateSelectOption(regulationOptions)}
                      </select>
                    </div>
                  </FormLabel>
                </FormGroup>
              </GridCol>

              <GridCol md={6} xs={12}>
                <FormGroup>
                  <FormLabel className={styles.label}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "190px" }}>
                        <Checkbox
                          id="destination-checkbox"
                          large
                          label={i18n.t("pages.new-flow.general.pays_des")}
                          checked={checkboxStates["destination-checkbox"]}
                          onChange={handleCheckboxChange}
                        />
                      </div>

                      <AutoCompleteComponent
                        key={selectedDestinationCountry}
                        id="destination-country"
                        label={""}
                        errorText={i18n.t("errors")}
                        onChange={handleDestinationCountry}
                        options={rootStore.countriesStore.countriesToOptions}
                        value={selectedDestinationCountry}
                      />
                    </div>
                  </FormLabel>
                </FormGroup>
              </GridCol>
              <GridCol md={6} xs={12}>
                <FormGroup>
                  <FormLabel className={styles.label}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "190px" }}>
                        <Checkbox
                          id="enduser-checkbox"
                          large
                          label={i18n.t("pages.license.add.end-user")}
                          checked={checkboxStates["enduser-checkbox"]}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <TextInput
                        id="enduser"
                        label={""} // caractère d'espace insécable
                        value={endUser || ""}
                        errorText={i18n.t("errors")}
                        onChange={handleEndUserChange}
                        className={`${styles.textInputNoMargin} ${styles.widthTextfield}`}
                      />
                    </div>
                  </FormLabel>
                </FormGroup>
              </GridCol>
              <GridCol md={6} xs={12}>
                <FormGroup>
                  <FormLabel className={styles.label}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "190px" }}>
                        <Checkbox
                          id="classification-checkbox"
                          large
                          label={i18n.t("pages.license.add.classification")}
                          checked={checkboxStates["classification-checkbox"]}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <TextInput
                        id="classification"
                        value={classification || ""}
                        errorText={i18n.t("errors")}
                        onChange={handleClassifcationChange}
                        className={`${styles.textInputNoMargin} ${styles.widthTextfield}`}
                      />
                    </div>
                  </FormLabel>
                </FormGroup>
              </GridCol>
              <GridCol md={6} xs={12}>
                <FormGroup>
                  <FormLabel className={styles.label}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "190px" }}>
                        <Checkbox
                          id="partnumber-checkbox"
                          large
                          label={i18n.t(
                            "pages.new-flow.product.equipment.part-number"
                          )}
                          checked={checkboxStates["partnumber-checkbox"]}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <TextInput
                        id="part-number"
                        label={""} // caractère d'espace insécable
                        value={partNumber || ""}
                        errorText={i18n.t("errors")}
                        onChange={handlePartNumberChange}
                        className={`${styles.textInputNoMargin} ${styles.widthTextfield}`}
                      />
                    </div>
                  </FormLabel>
                </FormGroup>
              </GridCol>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
});

export default FiltersMatchingComponent;
