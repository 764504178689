import Moment from "moment";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  IconButton,
  //DialogActions,
  //DialogContent,
  //Dialog ,
  Button,
  TextInput,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableCell,
  TableBody,
  Loading,
  SearchInput,
} from "../../../components/horizon-components-react/src/components";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import { ROLES } from "../../../utils/constant";
import { Hidden, Tooltip } from "@material-ui/core";
/* Styles import */
import styles from "../admin.module.css";
import { blue, green } from "@material-ui/core/colors";
import { reaction } from "mobx";

interface State {
  search: string;
  firstname: string;
  lastname: string;
  AtenantId: string;
  expiration_date: string;
  email: string;
  tenantId: string;
  role: string;
  siteId: number;
  open: boolean;
  open1: boolean;
  desId: number;
  R_checklist: R_entite[];
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const AdminUsers: React.FC<Props> = ({ rootStore }) => {
  const [state, setState] = useState({
    search: "",
    firstname: "",
    lastname: "",
    AtenantId: "",
    email: "",
    expiration_date: "",
    tenantId: "",
    role: "",
    siteId: 0,
    open: false,
    open1: false,
    desId: 0,
    //R_checklist: [] as any,
  });

  const [R_checklist, setRCheckList] = useState<[]>([]);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const tenant = rootStore.userStore.user?.tenantId;
        const response1 = await fetch(
          `${process.env.REACT_APP_SCREEN}/get_entiteall?tenant=` +
            encodeURIComponent(tenant || "")
        );
        const data1 = await response1.json();
        setRCheckList(data1);
        //setState({ ...state, R_checklist: data1 });
      } catch (error) {
        // Gérer les erreurs ici si nécessaire
        console.error(
          "Une erreur s'est produite lors de la récupération des données :",
          error
        );
      }
    };

    const fetchData = async () => {
      await rootStore.userStore.getUsers();
      await rootStore.userStore.getSites();
      await rootStore.tenantStore.getTenants();
      await rootStore.countriesStore.getCountriesDeparture();
    };

    fetchData();
    fetchData2();
  }, []);

  const submitDelete = async (userId: number) => {
    //const { firstname, lastname, email } = state
    await rootStore.userStore.submitDelete(userId);

    // setState({ ...state, firstname: '', lastname: '', email: '' })
  };

  const submitDesactive = async (userId: number) => {
    //const { firstname, lastname, email } = state

    await rootStore.userStore.submitDesactive(userId);

    // setState({ ...state, firstname: '', lastname: '', email: '' })
  };

  const submit = async () => {
    const { firstname, lastname, AtenantId, email, expiration_date } = state;

    await rootStore.userStore.addUser(
      firstname,
      lastname,
      AtenantId,
      email,
      expiration_date
    );
    setState({
      ...state,
      ...state,
      firstname: "",
      lastname: "",
      email: "",
      expiration_date: "",
    });
  };

  const format_pays = (pays: string) => {
    if (pays == "United Kingdom (UK)") return "UK";
    if (pays == "United States of America (USA)") return "USA";

    return pays;
  };

  const addUser = () => {
    return (
      rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"]) && (
        <React.Fragment>
          <TableRow>
            <TableCell>
              <span style={{ marginLeft: "500px" }}>Expiration </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div className={styles.create_account_name}>
                <TextInput
                  placeholder={i18n.t("pages.admin.users.firstname")}
                  value={state.firstname || ""}
                  onChange={(e) =>
                    setState({ ...state, firstname: e.target.value })
                  }
                />
                <TextInput
                  placeholder={i18n.t("pages.admin.users.lastname")}
                  value={state.lastname || ""}
                  onChange={(e) =>
                    setState({ ...state, lastname: e.target.value })
                  }
                />
                <select
                  style={{ height: "34px" }}
                  onChange={(e) =>
                    setState({ ...state, AtenantId: e.target.value })
                  }
                  value={state.AtenantId || ""}
                  disabled={
                    !rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"])
                  }
                >
                  <option key={""} value={""}>
                    {""}
                  </option>
                  {rootStore.tenantStore.tenants.map((tenantId) => {
                    return (
                      <option key={tenantId.name} value={tenantId.name || ""}>
                        {tenantId.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  style={{ height: "34px" }}
                  placeholder={`Date Expiration`}
                  id="creation-date-begin"
                  type="date"
                  value={state.expiration_date || ""}
                  onChange={(e) =>
                    setState({ ...state, expiration_date: e.target.value })
                  }
                />

                <TextInput
                  placeholder={i18n.t("pages.admin.users.email")}
                  value={state.email || ""}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                />

                <Button
                  disabled={
                    !(
                      state.expiration_date &&
                      state.firstname &&
                      state.lastname &&
                      state.email &&
                      state.AtenantId
                    )
                  }
                  primary
                  style={{ height: "34px" }}
                  onClick={() => submit()}
                >
                  {i18n.t("pages.admin.users.add")}
                </Button>
              </div>
            </TableCell>

            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </React.Fragment>
      )
    );
  };

  const handleOpen = () => setState({ ...state, open: true });
  const handleClose = () => setState({ ...state, open: false });
  const handleOpen1 = () => setState({ ...state, open1: true });
  const handleClose1 = () => setState({ ...state, open1: false });
  Moment.locale("fr");
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="USERS" rootStore={rootStore} />

      <Card className={styles.body}>
        <div className={styles.headerCard}>
          <CardHeader
            title={
              i18n.t("pages.admin.users.title") +
              " (" +
              rootStore.userStore.otherUsers.filter(
                (otherUsers) =>
                  `${otherUsers.firstname} ${otherUsers.lastname} ${otherUsers.tenantId}`
                    .toLowerCase()
                    .indexOf(state.search.toLowerCase()) !== -1
              ).length +
              " users)"
            }
          />

          <SearchInput
            id="search"
            value={state.search || ""}
            onChange={(e) => setState({ ...state, search: e.target.value })}
            placeholder={i18n.t("pages.admin.users.search-placeholder")}
          />
        </div>
        <CardContent>
          {addUser()}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t("pages.admin.users.name")}</TableCell>
                <TableHeaderCell>
                  {i18n.t("pages.admin.users.status")}
                </TableHeaderCell>
                <TableHeaderCell>
                  {i18n.t("pages.admin.users.email")}
                </TableHeaderCell>
                <TableHeaderCell>{"Date expiration"}</TableHeaderCell>
                <TableHeaderCell>
                  {i18n.t("pages.admin.users.tenant")}
                </TableHeaderCell>
                <TableHeaderCell>{"Entite"}</TableHeaderCell>
                <TableHeaderCell>
                  {i18n.t("pages.admin.users.role")}
                </TableHeaderCell>
                <TableHeaderCell>
                  {i18n.t("pages.admin.users.departure_country")}
                </TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rootStore.userStore.otherUsers
                .filter(
                  (otherUsers) =>
                    `${otherUsers.firstname} ${otherUsers.lastname} ${otherUsers.tenantId}`
                      .toLowerCase()
                      .indexOf(state.search.toLowerCase()) !== -1
                )
                .map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell>{`${user.firstname} ${user.lastname}`}</TableCell>

                      <TableCell>
                        {user.status == "DEACTIVATE" ? "Disabled" : user.status}
                      </TableCell>

                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {
                          <input
                            style={{ height: "33px" }}
                            placeholder={`Date Expiration`}
                            id="creation-date-begin"
                            type="date"
                            disabled={
                              !rootStore.userStore.isAuthorized([
                                "SUPER_ADMINISTRATOR",
                              ])
                            }
                            value={Moment(user.expiration_date).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={(e) =>
                              rootStore.userStore.updateExpiration_date(
                                user.id,
                                e.target.value
                              )
                            }
                          />
                          //Moment(user.expiration_date).format('DD MMM YYYY')
                        }
                      </TableCell>

                      <TableCell>
                        <select
                          onChange={(e) =>
                            rootStore.userStore.updateTenant(
                              user.id,
                              e.target.value
                            )
                          }
                          value={user.tenantId || ""}
                          disabled={
                            !rootStore.userStore.isAuthorized([
                              "SUPER_ADMINISTRATOR",
                            ])
                          }
                        >
                          {rootStore.tenantStore.tenants.map((tenantId) => {
                            return (
                              <option
                                key={tenantId.name}
                                value={tenantId.name || ""}
                              >
                                {tenantId.name}
                              </option>
                            );
                          })}
                        </select>
                      </TableCell>

                      <TableCell>
                        <select
                          style={{ width: "120px" }}
                          onChange={(e) =>
                            rootStore.userStore.updateentite(
                              user.id,
                              e.target.value
                            )
                          }
                          disabled={
                            !rootStore.userStore.isAuthorized([
                              "SUPER_ADMINISTRATOR",
                            ])
                          }
                          value={user.entite || ""}
                        >
                          <option value="ALL">ALL</option>
                          {R_checklist.filter(
                            (entite: any) => entite.id_tenant === user?.tenantId
                          ).map((entite: any, index: number) => {
                            return (
                              <option
                                key={index}
                                value={entite.id_entite || ""}
                              >
                                {entite.id_entite}
                              </option>
                            );
                          })}
                        </select>
                      </TableCell>

                      <TableCell>
                        <select
                          onChange={(e) =>
                            rootStore.userStore.updateRole(
                              user.id,
                              e.target.value as UserRole
                            )
                          }
                          disabled={
                            !rootStore.userStore.isAuthorized([
                              "SUPER_ADMINISTRATOR",
                            ])
                          }
                          value={user.role || ""}
                        >
                          {ROLES.map((role, index) => {
                            return (
                              <option key={index} value={role.value || ""}>
                                {role.label}
                              </option>
                            );
                          })}
                        </select>
                      </TableCell>
                      <TableCell>
                        <select
                          onChange={(e) =>
                            rootStore.userStore.updateDeparture(
                              user.id,
                              e.target.value
                            )
                          }
                          value={user.departure_country || ""}
                          disabled={
                            !rootStore.userStore.isAuthorized([
                              "ADMINISTRATOR_GROUP",
                              "SUPER_ADMINISTRATOR",
                            ])
                          }
                        >
                          <option value="17">
                            {i18n.t("pages.admin.users.no-value")}
                          </option>

                          {rootStore.countriesStore.countriesDeparture.map(
                            (site) => {
                              return (
                                <option key={site.id} value={site.name || ""}>
                                  {format_pays(site.name)}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setState({ ...state, desId: user.id, open1: true });
                            //handleOpen1();
                          }}
                          style={{
                            display: rootStore.userStore.isAuthorized([
                              "SUPER_ADMINISTRATOR",
                            ])
                              ? "block"
                              : "none",
                          }}

                          //   disabled={ !rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) }
                        >
                          <Tooltip title="Supprimer">
                            <DeleteIcon style={{ color: "#0085ad" }} />
                          </Tooltip>
                        </IconButton>

                        <Dialog
                          open={state.open1}
                          title="Suppression utilisateur"
                          onClose={handleClose1}
                        >
                          <DialogContent>
                            L'utilisateur va etre supprimé ainsi que ses données
                            (flux et licences).
                            <br />
                            Etes-vous vraiment sûr ?
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose1}>Annuler</Button>
                            <Button
                              primary
                              onClick={() => {
                                submitDelete(state.desId);
                                handleClose1();
                              }}
                            >
                              Supprimer
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setState({ ...state, desId: user.id });
                            handleOpen();
                          }}
                          style={{
                            display: rootStore.userStore.isAuthorized([
                              "SUPER_ADMINISTRATOR",
                            ])
                              ? "block"
                              : "none",
                          }}

                          //disabled={ !rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) }
                        >
                          <Tooltip title="Désactiver">
                            <DeleteOutlineIcon style={{ color: "#0085ad" }} />
                          </Tooltip>
                        </IconButton>

                        <Dialog
                          open={state.open}
                          title="Désactivation utilisateur"
                          onClose={handleClose}
                        >
                          <DialogContent>
                            L'utilisateur va être désactivé : il ne pourra plus
                            se connecter, mais les données qu'il a créées seront
                            conservées.
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Annuler</Button>
                            <Button
                              primary
                              onClick={() => {
                                submitDesactive(state.desId);
                                handleClose();
                              }}
                            >
                              Désactiver
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          {rootStore.userStore.loading && <Loading />}
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(AdminUsers));
