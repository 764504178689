/* Styles import */
import styles from "./viewFlow.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  TextInput,
  Button,
  FormGroup,
  GridContainer,
  GridRow,
  GridCol,
} from "../../components/horizon-components-react/src/components";
import { FormLabel } from "@material-ui/core";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

/* Custom import */
import AppBar from "../../components/appBar";
import Footer from "../../components/footer";
import AutoComplete from "../../components/autoComplete";
import Loader from "../../components/loader";
import { generateFlowId } from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow/headerFlow";
import { flowType, productType } from "../../utils/constant";
import TimeLine from "../../components/timeLine";

interface State {
  fid?: number;
  sapId?: string;
  flowType?: FlowType;
  customerCompagny?: string;
  departureCountry?: number;
  flowConsignees: Consignee[];
  endUserCountry?: number;
  productType?: ProductType;
  modelOptions: string[];
  model?: string;
  quantity?: number;
  supplier?: string;
  partNumber?: string;
  designation?: string;
  nationalEccn?: string;
  usEccn?: string;
  equipments: Equipment[];
  loading: boolean;
  types: string[];
  delivery_date: string;
  order_num: string;
  status?: FlowStatus;
  transitCountryId?: number;
  commande_date: string;
  ordre_type: string;
  num_licence_export: string;
  num_licence_import: string;
  num_licence_us: string;
  link_licence_national: MatchingLinkedDto[];
  link_licence_us: MatchingLinkedDto[];
}

type EquipmentKey =
  | "equipmentType"
  | "model"
  | "quantity"
  | "partNumber"
  | "designation"
  | "nationalEccn"
  | "usEccn"
  | "supplier";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}
const ViewFlowArchived: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    fid: 0,
    sapId: "",
    flowType: undefined,
    customerCompagny: "",
    departureCountry: 0,
    flowConsignees: [],
    endUserCountry: 0,
    transitCountryId: 0,
    productType: undefined,
    modelOptions: [],
    model: "",
    quantity: 0,
    supplier: "",
    partNumber: "",
    designation: "",
    nationalEccn: "",
    usEccn: "",
    equipments: [],
    loading: false,
    types: [],
    delivery_date: "",
    commande_date: "",
    order_num: "",
    ordre_type: "Standard",
    num_licence_export: "",
    num_licence_import: "",
    num_licence_us: "",
    link_licence_national: [],
    link_licence_us: [],
    status: undefined,
  });
  const { id } = useParams();

  setState({
    ...state,
    ...state,
    flowConsignees: [
      {
        consigneeName: "",
        consigneeCountryId: 0,
      },
    ],
  });

  useEffect(() => {
    rootStore.countriesStore.getCountriesDeparture();
    rootStore.countriesStore.getCountries();
    if (id) {
      getFlow(parseInt(id));
    } else {
      setState({
        ...state,
        ...state,
        fid: undefined,
        sapId: undefined,
        flowType: "NONE",
        customerCompagny: "",
        departureCountry: undefined,
        flowConsignees: [{ consigneeName: "", consigneeCountryId: 0 }],
        endUserCountry: undefined,
        productType: "NONE",
        modelOptions: [],
        model: "",
        quantity: 0,
        partNumber: "",
        designation: "",
        nationalEccn: "",
        usEccn: "",
        equipments: [
          {
            equipmentType: "NONE",
            model: "",
            modelOptions: [],
            quantity: 0,
            partNumber: "",
            designation: "",
            nationalEccn: "",
            usEccn: "",
          },
        ],
        loading: false,
        types: [],
      });
    }
  }, []);

  const getFlow = async (fid: number) => {
    setState({ ...state, loading: true });

    const flow = await rootStore.flowStore.getById(fid);

    if (flow) {
      const types = await rootStore.classificationStore.getTypes(flow.itemType);
      let modelOptions: string[] | undefined = [];

      if (flow.itemType !== "EQUIPMENT") {
        modelOptions = await rootStore.classificationStore.getModels(
          flow.itemType,
        );
      }

      const equipments = await Promise.all(
        flow.flowEquipments.map(async (equipment: Equipment) => {
          const equipmentModelOptions =
            await rootStore.classificationStore.getOptionModels(
              equipment.equipmentType || "",
              flow.itemType,
            );

          equipment.modelOptions = equipmentModelOptions || [];
          return equipment;
        }),
      );

      setState({
        ...state,
        fid,
        sapId: flow.sapId,
        flowType: flow.flowType,
        supplier: flow.supplier,
        customerCompagny: flow.customer,
        departureCountry: flow.departureCountryId,
        endUserCountry: flow.destinationCountryId,
        flowConsignees:
          flow.flowConsignees.length > 0
            ? flow.flowConsignees
            : [{ consigneeName: "", consigneeCountryId: 0 }],
        productType: flow.itemType,
        model: flow.flowAircraft ? flow.flowAircraft.model : undefined,
        quantity: flow.flowAircraft ? flow.flowAircraft.quantity : undefined,
        partNumber: flow.flowAircraft
          ? flow.flowAircraft.partNumber
          : undefined,
        designation: flow.flowAircraft
          ? flow.flowAircraft.designation
          : undefined,
        nationalEccn: flow.flowAircraft
          ? flow.flowAircraft.nationalEccn
          : undefined,
        usEccn: flow.flowAircraft ? flow.flowAircraft.usEccn : undefined,
        equipments:
          equipments.length > 0
            ? equipments
            : [{ quantity: 0, modelOptions: [] }],
        types: types || [],
        modelOptions: modelOptions || [],
        status: flow.status,
      });
    }

    setState({ ...state, loading: false });
  };

  const addConsignee = () => {
    const { flowConsignees } = state;

    flowConsignees.push({
      consigneeName: "",
      consigneeCountryId: 0,
    });

    setState({ ...state, flowConsignees });
  };

  const updateConsignee = (
    index: number,
    input: "country" | "name",
    value: number | string,
  ) => {
    const { flowConsignees } = state;

    if (input === "country") {
      flowConsignees[index].consigneeCountryId = value as number;
    } else {
      flowConsignees[index].consigneeName = value as string;
    }

    setState({ ...state, flowConsignees });
  };

  const addEquipment = () => {
    const equipments = state.equipments;

    equipments.push({
      quantity: 0,
      modelOptions: [],
    });

    setState({ ...state, equipments });
  };

  const updateEquipment = async (
    key: EquipmentKey,
    index: number,
    value: any,
  ) => {
    const equipments = state.equipments;

    if (key === "equipmentType") {
      const modelOptions = await rootStore.classificationStore.getOptionModels(
        value,
        state.productType,
      );

      equipments[index].modelOptions = modelOptions || [];
      equipments[index].quantity = 0;
      equipments[index].partNumber = undefined;
      equipments[index].designation = undefined;
      equipments[index].nationalEccn = undefined;
      equipments[index].usEccn = undefined;
    }

    if (key === "model" && state.departureCountry) {
      const eccns = await rootStore.classificationStore.getEccnsByModel(
        state.departureCountry,
        value,
      );

      if (eccns) {
        equipments[index].nationalEccn = eccns.nationalClassification;
        equipments[index].usEccn = eccns.usClassification;
        if (equipments[index].quantity === 0) {
          equipments[index].quantity = 1;
        }
        equipments[index].partNumber = undefined;
        equipments[index].designation = undefined;
      }
    }

    equipments[index][key] = value;

    setState({ ...state, equipments });
  };

  const reinitEquipment = () => {
    setState({
      ...state,
      model: "",
      modelOptions: [],
      quantity: 0,
      partNumber: "",
      designation: "",
      nationalEccn: "",
      usEccn: "",
      equipments: [
        {
          quantity: 0,
          partNumber: "",
          designation: "",
          nationalEccn: "",
          usEccn: "",
          modelOptions: [],
        },
      ],
    });
  };

  const handleProductType = async (productType: ProductType) => {
    reinitEquipment();

    setState({ ...state, productType });

    const types = await rootStore.classificationStore.getTypes(productType);

    if (productType !== "EQUIPMENT") {
      const modelOptions =
        await rootStore.classificationStore.getModels(productType);

      setState({ ...state, modelOptions: modelOptions || [] });
    }

    setState({ ...state, types: types || [] });
  };

  const handleDepartureCountry = async (departureCountry: string) => {
    const intDepartureCountry = parseInt(departureCountry);

    setState({ ...state, departureCountry: intDepartureCountry });
  };

  const handleModel = async (model: string) => {
    const departureCountry = state.departureCountry;

    let newQuantity = 1;

    if (state.quantity) {
      if (state.quantity > 1) {
        newQuantity = state.quantity;
      }
    }

    setState({
      ...state,
      model,
      quantity: newQuantity,
      partNumber: "",
      designation: "",
    });

    if (departureCountry) {
      const modelEccn = await rootStore.classificationStore.getEccnsByModel(
        departureCountry,
        model,
      );

      if (modelEccn) {
        setState({
          ...state,
          nationalEccn: modelEccn.nationalClassification,
          usEccn: modelEccn.usClassification,
        });
      }
    }
  };

  const activeSaveButton = () => {
    const {
      flowType,
      customerCompagny,
      departureCountry,
      endUserCountry,
      status,
    } = state;

    return (
      flowType &&
      customerCompagny &&
      departureCountry &&
      endUserCountry &&
      (status === "CLASSIFICATION" || !status)
    );
  };

  const cleanConsignees = (consignees: Consignee[]) => {
    return consignees.filter(
      (consignee) =>
        consignee.consigneeName !== "" && consignee.consigneeCountryId !== 0,
    );
  };

  const cleanEquipments = (equipments: Equipment[]) => {
    return equipments
      .filter((equipment) => equipment.quantity !== 0)
      .map((equipment) => {
        return {
          id: equipment.id,
          designation: equipment.designation,
          equipmentType: equipment.equipmentType,
          model: equipment.model,
          nationalEccn: equipment.nationalEccn,
          partNumber: equipment.partNumber,
          supplier: equipment.supplier,
          quantity: equipment.quantity,
          usEccn: equipment.usEccn,
        };
      });
  };

  const handleSave = async () => {
    const {
      fid,
      sapId,
      flowType,
      customerCompagny,
      departureCountry,
      flowConsignees,
      endUserCountry,
      productType,
      model,
      quantity,
      partNumber,
      designation,
      nationalEccn,
      usEccn,
      equipments,
    } = state;

    if (flowType && customerCompagny && departureCountry && endUserCountry) {
      setState({ ...state, loading: true });

      const flowId = await rootStore.flowStore.saveFlow({
        id: fid,
        sapId,
        flowType,
        customerCompagny,
        departureCountry,
        endUserCountry,
        productType,
        equipments: cleanEquipments(equipments),
        flowConsignees: cleanConsignees(flowConsignees),
        model,
        quantity,
        partNumber,
        designation,
        nationalEccn,
        usEccn,
      });

      setState({ ...state, loading: false });

      if (flowId) {
        navigate({ pathname: `/flow/${flowId}/classification` });
      }
    }
  };

  const handleSubmit = async () => {
    const {
      fid,
      sapId,
      flowType,
      customerCompagny,
      departureCountry,
      flowConsignees,
      endUserCountry,
      productType,
      model,
      quantity,
      supplier,
      partNumber,
      designation,
      nationalEccn,
      usEccn,
      equipments,
    } = state;

    if (
      flowType &&
      customerCompagny &&
      departureCountry &&
      endUserCountry &&
      productType
    ) {
      setState({ ...state, loading: true });
      const flowId = await rootStore.flowStore.submitFlow({
        id: fid,
        sapId,
        flowType,
        customerCompagny,
        departureCountry,
        endUserCountry,
        productType,
        equipments: cleanEquipments(equipments),
        flowConsignees: cleanConsignees(flowConsignees),
        model,
        quantity,
        supplier,
        partNumber,
        designation,
        nationalEccn,
        usEccn,
      });

      setState({ ...state, loading: false });

      if (flowId) {
        navigate({ pathname: `/flow/${flowId}/exportability/results` });
      }
    }
  };

  const renderGeneralStep = () => {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.general.new_title")}
            </Title>

            <GridRow>
              <GridCol md={4}>
                <AutoComplete
                  id="departure-country"
                  label={i18n.t("pages.new-flow.general.departure-country")}
                  required={true}
                  disabled={false}
                  options={rootStore.countriesStore.countriesDepartureToOptions}
                  errorText={i18n.t("errors.departure-country-not-defined")}
                  value={state.departureCountry || ""}
                />
              </GridCol>

              <GridCol md={4}>
                <AutoComplete
                  id="end-user-country"
                  label={i18n.t("pages.new-flow.general.pays_des")}
                  required={true}
                  errorText={i18n.t("errors")}
                  disabled={false}
                  options={rootStore.countriesStore.countriesToOptions}
                  value={state.endUserCountry || ""}
                />
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  onChange={() => {}}
                  id="customer-company"
                  label={i18n.t("pages.new-flow.general.customer-compagny")}
                  value={state.customerCompagny || ""}
                  required={true}
                  errorText={i18n.t("errors")}
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.flowTypeLabel} required={true}>
                    {i18n.t("pages.new-flow.general.flow-type")}
                  </FormLabel>
                  <select
                    id="flow-type"
                    value={state.flowType || ""}
                    required={true}
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {flowType.map((type, index) => {
                      return (
                        <option key={index} value={type.value || ""}>
                          {type.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  onChange={() => {}}
                  id="order_num"
                  label={i18n.t("pages.new-flow.general.order_num")}
                  value={state.order_num || ""}
                  // required={ true }
                  errorText={i18n.t("errors")}
                />
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  onChange={() => {}}
                  id="delivery_date"
                  label={i18n.t("pages.new-flow.general.delivery_date")}
                  value={state.delivery_date || ""}
                  //  required={ true }
                  errorText={i18n.t("errors")}
                />
              </GridCol>
            </GridRow>

            <div>
              {state.flowConsignees.map((value, index) => {
                return (
                  <div className={styles.equipmentBox} key={index}>
                    <div className={styles.equipmentHeader}>
                      {`${i18n.t(
                        "pages.new-flow.general.consignees.consignee",
                      )} ${index + 1}`}
                    </div>

                    <GridRow>
                      <GridCol md={4}>
                        <AutoComplete
                          id="consignee-country"
                          label={i18n.t(
                            "pages.new-flow.general.consignees.country",
                          )}
                          options={rootStore.countriesStore.countriesToOptions}
                          value={value.consigneeCountryId || ""}
                        />
                      </GridCol>

                      <GridCol md={4}>
                        <TextInput
                          onChange={() => {}}
                          id="consignee-name"
                          label={i18n.t(
                            "pages.new-flow.general.consignees.name",
                          )}
                          value={value.consigneeName || ""}
                          errorText={i18n.t("errors")}
                        />
                      </GridCol>
                    </GridRow>
                  </div>
                );
              })}

              {state.flowConsignees.length < 4 && (
                <Button
                  id="add-consignee"
                  type="button"
                  size="medium"
                  onClick={() => addConsignee()}
                >
                  {i18n.t("pages.new-flow.general.consignees.add")}
                </Button>
              )}
            </div>
          </GridContainer>
        </div>
      </div>
    );
  };

  const renderProductStep = () => {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.product.title")}
            </Title>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.flowTypeLabel} required={true}>
                    {i18n.t("pages.new-flow.product.item-type")}
                  </FormLabel>
                  <select
                    id="product-type"
                    value={state.productType || ""}
                    required={true}
                  >
                    <option>{i18n.t("pages.new-flow.select-value")}</option>
                    {productType.map((product, index) => {
                      return (
                        <option key={index} value={product.value || ""}>
                          {product.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </GridCol>
            </GridRow>

            {(state.productType === "AIRCRAFT" ||
              state.productType === "HELICOPTER" ||
              state.productType === "SPACECRAFT") &&
              renderOtherStep()}

            {state.productType === "EQUIPMENT" &&
              renderEquipmentStep("equipment")}
          </GridContainer>
        </div>
      </div>
    );
  };

  const renderEquipmentStep = (key: string) => {
    return (
      <div>
        {state.equipments.map((equipment, index) => {
          return (
            <div className={styles.equipmentBox} key={index}>
              <div className={styles.equipmentHeader}>
                {`${i18n.t(`pages.new-flow.product.${key}.label`)} ${
                  index + 1
                }`}
              </div>

              <GridRow>
                <GridCol md={3}>
                  <FormGroup>
                    <FormLabel className={styles.flowTypeLabel}>
                      {i18n.t(`pages.new-flow.product.${key}.type`)}
                    </FormLabel>
                    <select
                      id="equipment-type"
                      value={equipment.equipmentType || ""}
                    >
                      <option value="">
                        {i18n.t("pages.new-flow.select-value")}
                      </option>
                      {state.types.map((type, index) => {
                        return (
                          <option key={index} value={type || ""}>
                            {type}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </GridCol>

                <GridCol md={3}>
                  <FormGroup>
                    <FormLabel className={styles.flowTypeLabel}>
                      {i18n.t("pages.new-flow.product.equipment.model.label")}
                    </FormLabel>
                    <select id="equipment-model" value={equipment.model || ""}>
                      <option value="">
                        {i18n.t("pages.new-flow.select-value")}
                      </option>
                      {equipment.modelOptions &&
                        equipment.modelOptions.map((equipmentModel, index) => {
                          return (
                            <option key={index} value={equipmentModel || ""}>
                              {equipmentModel}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    id="equipment-quantity"
                    label={i18n.t("pages.new-flow.product.equipment.quantity")}
                    value={equipment.quantity || ""}
                    type="number"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    id="equipment-supplier"
                    label={i18n.t("pages.new-flow.product.equipment.supplier")}
                    value={equipment.supplier || ""}
                    errorText={i18n.t("errors")}
                  />
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    id="equipment-part-number"
                    label={i18n.t(
                      "pages.new-flow.product.equipment.part-number",
                    )}
                    value={equipment.partNumber || ""}
                    errorText={i18n.t("errors")}
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    id="equipment-designation"
                    label={i18n.t(
                      "pages.new-flow.product.equipment.designation",
                    )}
                    value={equipment.designation || ""}
                    errorText={i18n.t("errors")}
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    label={i18n.t(
                      "pages.new-flow.product.equipment.national-eccn",
                    )}
                    value={equipment.nationalEccn || ""}
                    disabled
                    errorText={i18n.t("errors")}
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    label={i18n.t("pages.new-flow.product.equipment.us-eccn")}
                    value={equipment.usEccn || ""}
                    disabled
                    errorText={i18n.t("errors")}
                  />
                </GridCol>
              </GridRow>
            </div>
          );
        })}

        <Button
          id="add-equipment"
          type="button"
          onClick={() => addEquipment()}
          size="medium"
        >
          {i18n.t(`pages.new-flow.product.${key}.add`)}
        </Button>
      </div>
    );
  };

  const renderOtherStep = () => {
    return (
      <div>
        <GridRow>
          <GridCol md={3}>
            <FormGroup>
              <FormLabel className={styles.flowTypeLabel}>
                {i18n.t("pages.new-flow.product.equipment.model.label")}
              </FormLabel>
              <select id="model" value={state.model || ""}>
                <option></option>
                {state.modelOptions.map((model, index) => {
                  return (
                    <option key={index} value={model || ""}>
                      {model}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          </GridCol>

          <GridCol md={3}>
            <TextInput
              id="quantity"
              label={i18n.t("pages.new-flow.product.equipment.quantity")}
              value={state.quantity || ""}
              type="number"
              min={0}
              step={1}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                setState({ ...state, quantity: parseInt(value.target.value) })
              }
            />
          </GridCol>
          <GridCol md={3}>
            <TextInput
              id="supplier"
              label={i18n.t("pages.new-flow.product.equipment.supplier")}
              value={state.supplier || ""}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                setState({ ...state, supplier: value.target.value })
              }
            />
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol md={3}>
            <TextInput
              id="part-number"
              label={i18n.t("pages.new-flow.product.equipment.part-number")}
              value={state.partNumber || ""}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                setState({ ...state, partNumber: value.target.value })
              }
            />
          </GridCol>

          <GridCol md={3}>
            <TextInput
              id="designation"
              label={i18n.t("pages.new-flow.product.equipment.designation")}
              value={state.designation || ""}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                setState({ ...state, designation: value.target.value })
              }
            />
          </GridCol>

          <GridCol md={3}>
            <TextInput
              onChange={() => {}}
              label={i18n.t("pages.new-flow.product.equipment.national-eccn")}
              value={state.nationalEccn || ""}
              disabled
            />
          </GridCol>

          <GridCol md={3}>
            <TextInput
              onChange={() => {}}
              label={i18n.t("pages.new-flow.product.equipment.us-eccn")}
              value={state.usEccn || ""}
              disabled
            />
          </GridCol>
        </GridRow>

        <div className={styles.divider}></div>

        {renderEquipmentStep("optional-item")}
      </div>
    );
  };

  return (
    <div>
      {state.loading && <Loader />}
      <AppBar rootStore={rootStore} selectedPage={"FLOW"} />
      <HeaderFlow pages="ADD" />

      <form className={styles.form}>
        <Title tag="h2">{i18n.t("pages.new-flow.view")}</Title>
        {renderGeneralStep()}
        {renderProductStep()}
      </form>
      <Footer
        justify="flex-end"
        buttons={[
          {
            id: "save",
            onClick: () => handleSave(),
            label: i18n.t("global.save"),
            disabled: !activeSaveButton(),
          },
        ]}
      >
        {
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={0} />
            </GridCol>
          </GridRow>
        }
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ViewFlowArchived));
