/* Styles import */
import styles from "./uploadInput.module.css";

/* Global import */
import React from "react";
import i18n from "i18next";
import AttachFileIcon from "@material-ui/icons/AttachFile";

interface Props {
  onChange: (file: File) => void;
  value?: File;
  disabled?: boolean;
}

const UploadInput = ({ onChange, value, disabled }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      onChange(files[0]);
    }
  };

  return (
    <div className={disabled ? styles.input_disabled : styles.input}>
      <span>
        {value && value.name
          ? value.name.substring(0, 30)
          : i18n.t("components.upload-input.placeholder")}
      </span>

      <AttachFileIcon />

      <input
        id="input"
        type="file"
        disabled={disabled || false}
        onChange={handleChange}
        className={styles.input}
      />
    </div>
  );
};

export default UploadInput;
