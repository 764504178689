/* Global import */
import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  generatePath,
  useNavigate,
} from "react-router-dom";
import { Loading } from "./components/horizon-components-react/src/components";

/* Pages import */
import NewFlow from "./pages/newFlow";
import NewFlowArchived from "./pages/newFlowArchived";
import ViewFlow from "./pages/viewFlow";
import SearchEccn from "./pages/Search/search";
import ViewFlowArchived from "./pages/viewFlowArchived";
import Exportability from "./pages/exportability/exportability";
import Results from "./pages/exportability/results/results";
import ResultsFlow from "./pages/exportability/results/results-flow";
import ResultsFlow_new from "./pages/exportability/results_new/results-flow";
import EndUser from "./pages/screening/end-user";
import Entity from "./pages/screening/entity/entity";
import ChooseEntity from "./pages/screening/entity/chooseEntity";
import ResultEntity from "./pages/screening/entity/result";
import ResultEndUser from "./pages/screening/end-user/result";
import RequestLicense from "./pages/license/request";
import AddLicense from "./pages/license/add";
import EditLicense from "./pages/license/edit";
import MatchingLicense from "./pages/license/matching";
import Shipping from "./pages/shipping";
import EuScreening from "./pages/basicScreening/euScreening";
import AhScreening from "./pages/basicScreening/ahScreening";
import OtherScreening from "./pages/basicScreening/otherScreening";
import UsScreening from "./pages/basicScreening/usScreening";
import All from "./pages/basicScreening/All";
import ControlLists from "./pages/regulation/control-lists";
import ControlListsEdit from "./pages/regulation/control-lists/control-lists-edit";
import Sanctions from "./pages/regulation/sanctions";
import Sanctionsedit from "./pages/regulation/sanctions/sanctions-edit";
import StatePortal from "./pages/regulation/state-portals";
import StatePortaledit from "./pages/regulation/state-portals/state-portals-edit";
import Wassenaar from "./pages/regulation/wassenaar";
import Wassenaaredit from "./pages/regulation/wassenaar/wassenaar-edit";
import SendLicense from "./pages/license/request/send";
import SearchFlow from "./pages/searchFlow";
import SearchFlowArchive from "./pages/searchFlowArchive";
import ReportingDGA from "./pages/ReportingDGA";
import ReportingSBDU from "./pages/ReportingSBDU";
import ReportingUSITAR from "./pages/ReportingUSITAR";
import ReportingUSEAR from "./pages/ReportingUSEAR";
import Analytics from "./pages/analytics";
import BasicEntityChoose from "./pages/basicScreening/usScreening/basicEntityChoose";
import ResultBasicEntity from "./pages/basicScreening/usScreening/result";
import BasicEntityChooseeu from "./pages/basicScreening/euScreening/basicEntityChoose";
import BasicEntityChooseah from "./pages/basicScreening/ahScreening/basicEntityChoose";
import BasicEntityChoosefr from "./pages/basicScreening/otherScreening/basicEntityChoose";
import BasicEntityChoosecanada from "./pages/basicScreening/canadaScreening/basicEntityChoose";
import BasicEntityChooseAll from "./pages/basicScreening/All/basicEntityChoose";
import ResultBasicEntityeu from "./pages/basicScreening/euScreening/result";
import ResultBasicEntityah from "./pages/basicScreening/ahScreening/result";
import ResultBasicEntityALL from "./pages/basicScreening/All/result";
import ResultBasicEntityfr from "./pages/basicScreening/otherScreening/result";
import ResultBasicEntitycanada from "./pages/basicScreening/canadaScreening/result";

/* Administation pages */
import AdminUsers from "./pages/admin/users";
import Log from "./pages/admin/log";
import Logreplication from "./pages/admin/logreplication";
import AdminCountries from "./pages/admin/countries";
import Screening_tenant from "./pages/admin/screening_tenant";
import exportmsg from "./pages/admin/exportmsg";
import sanction from "./pages/admin/sanction";
import USRules from "./pages/admin/usRules";
import EuropeanRules from "./pages/admin/europeanRules";
import system from "./pages/admin/system";
import Screening from "./pages/admin/Screening";
import Tenants from "./pages/admin/tenants";
import ClassificationDatabase from "./pages/admin/classificationDatabase/classificationDatabase";
import BasicEntityChooseuk from "./pages/basicScreening/ukScreening/basicEntityChoose";
import ResultBasicEntityUk from "./pages/basicScreening/ukScreening/result/result";
import ImputLicence from "./pages/license/imput/imput";
import Report from "./pages/admin/report";
import Reportsm from "./pages/admin/reportsm";
import Exportmsg from "./pages/admin/exportmsg";
import Sanction from "./pages/admin/sanction";
import System from "./pages/admin/system";

import Login from "./pages/login";
import ActivateUser from "./pages/validationAccount/activate";
import Home from "./pages/home";

/* Custom import */
import RootStore from "./stores/rootStore";
import PrivateRoute from "./components/privateRoute";
import "./utils/i18n";
import { inject, observer } from "mobx-react";
import AppBar from "./components/appBar";
import SearchLicensev2 from "./pages/license/searchLicense/searchLicensev2";
import RecuperationAccount from "./pages/recuperationAccount";
import DueScreening from "./pages/basicScreening/dueScreening/dueScreening";
import ReportingPays from "./pages/Reportingpays/Reportingpays";
import Entite from "./pages/admin/entite";
import Entite_templete from "./pages/admin/entite_templete";
import ExampleGridComponent from "./pages/license/searchLicense/PageTableLicence";
import SearchLicensev3 from "./pages/license/searchLicense/PageTableLicence";
import PageTableLicence from "./pages/license/searchLicense/PageTableLicence";

interface Props {
  rootStore: RootStore; // Déclarez la prop 'rootStore' ici
}
interface State {}

const AppRouter: React.FC<Props> = ({ rootStore }) => {
  const matching = [
    {
      path: "/",
      element: <Home rootStore={rootStore} />,
    },
    {
      path: "/home",
      element: <Home rootStore={rootStore} />,
    },
    {
      path: "/search",
      element: <SearchEccn rootStore={rootStore} />,
    },
    {
      path: "/login",
      element: <Login rootStore={rootStore} />,
    },
    {
      path: "/users/:id/:token",
      element: <ActivateUser rootStore={rootStore} />,
    },
    {
      path: "/screening/entity/choose-entity",
      element: <ChooseEntity rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/us/choose-basic-entity",
      element: <BasicEntityChoose rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/due_diligence",
      element: <DueScreening rootStore={rootStore} />,
    },
    {
      path: "/new-flow",
      element: <NewFlow rootStore={rootStore} />,
    },
    {
      path: "/search-flow",
      element: <SearchFlow rootStore={rootStore} />,
    },
    {
      path: "/ReportingDGA",
      element: <ReportingDGA rootStore={rootStore} />,
    },
    {
      path: "/ReportingSBDU",
      element: <ReportingSBDU rootStore={rootStore} />,
    },
    {
      path: "/ReportingUSITAR",
      element: <ReportingUSITAR rootStore={rootStore} />,
    },
    {
      path: "/ReportingUSEAR",
      element: <ReportingUSEAR rootStore={rootStore} />,
    },
    {
      path: "/search-flowArchive",
      element: <SearchFlowArchive rootStore={rootStore} />,
    },
    {
      path: "/analytics",
      element: <Reportsm rootStore={rootStore} />,
    },
    {
      path: "/logtenant",
      element: <Analytics rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/classification",
      element: <NewFlow rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/classificationArchived",
      element: <NewFlowArchived rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/view",
      element: <ViewFlow rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/viewArchived",
      element: <ViewFlowArchived rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/exportability/results",
      element: <ResultsFlow rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/exportability/results_new",
      element: <ResultsFlow_new rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/entity/result",
      element: <ResultEntity rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/us/result",
      element: <ResultBasicEntity rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/all/result",
      element: <ResultBasicEntityALL rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/all/result",
      element: <ResultBasicEntityALL rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/eu/result",
      element: <ResultBasicEntityeu rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/ah/result",
      element: <ResultBasicEntityah rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/fr/result",
      element: <ResultBasicEntityfr rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/canada/result",
      element: <ResultBasicEntitycanada rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/uk/result",
      element: <ResultBasicEntityUk rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/entity/choose-entity",
      element: <ChooseEntity rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/us/choose-basic-entity",
      element: <BasicEntityChoose rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/all/choose-basic-entity",
      element: <BasicEntityChooseAll rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/eu/choose-basic-entity",
      element: <BasicEntityChooseeu rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/ah/choose-basic-entity",
      element: <BasicEntityChooseah rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/fr/choose-basic-entity",
      element: <BasicEntityChoosefr rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/canada/choose-basic-entity",
      element: <BasicEntityChoosecanada rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/uk/choose-basic-entity",
      element: <BasicEntityChooseuk rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/entity",
      element: <Entity rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/eu/",
      element: <EuScreening rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/ah/",
      element: <AhScreening rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/other/",
      element: <OtherScreening rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/us/",
      element: <UsScreening rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/basic/all/",
      element: <All rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/end-user/result",
      element: <ResultEndUser rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/screening/end-user",
      element: <EndUser rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/license/request",
      element: (
        <RequestLicense
          rootStore={rootStore}
          msgType={{
            code_msg: "",
            Regle: "",
            Message: "",
            Lib_url1: "",
            Url1: "",
            Lib_url2: "",
            Url2: "",
            Lib_url3: "",
            Url3: "",
          }}
        />
      ),
    },
    /*{
      path: "/flow/:id/:type/license/matching",
      element: <MatchingLicense rootStore={rootStore} />,
    },*/
    {
      path: "/flow/:id/:type/license/imput",
      element: <ImputLicence rootStore={rootStore} />,
    },
    {
      path: "/flow/:id/shipping",
      element: <Shipping rootStore={rootStore} />,
    },
    {
      path: "/exportability/results",
      element: <Results rootStore={rootStore} />,
    },
    {
      path: "/exportability",
      element: <Exportability rootStore={rootStore} />,
    },
    {
      path: "/screening/entity/result",
      element: <ResultEntity rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/us/result",
      element: <ResultBasicEntity rootStore={rootStore} />,
    },
    {
      path: "/screening/entity/choose-entity",
      element: <ChooseEntity rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/us/choose-basic-entity",
      element: <BasicEntityChoose rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/all/choose-basic-entity",
      element: <BasicEntityChooseAll rootStore={rootStore} />,
    },
    {
      path: "/screening/entity",
      element: <Entity rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/eu/",
      element: <EuScreening rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/ah/",
      element: <AhScreening rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/other/",
      element: <OtherScreening rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/all/",
      element: <All rootStore={rootStore} />,
    },
    {
      path: "/screening/basic/us/",
      element: <UsScreening rootStore={rootStore} />,
    },
    {
      path: "/screening/end-user/result",
      element: <ResultEndUser rootStore={rootStore} />,
    },
    {
      path: "/screening/end-user",
      element: <EndUser rootStore={rootStore} />,
    },
    {
      path: "/license/request/send/:regulation/:id",
      element: <SendLicense rootStore={rootStore} />,
    },
    {
      path: "/license/:id/request",
      element: (
        <RequestLicense
          msgType={{
            code_msg: "",
            Regle: "",
            Message: "",
            Lib_url1: "",
            Url1: "",
            Lib_url2: "",
            Url2: "",
            Lib_url3: "",
            Url3: "",
          }}
          rootStore={rootStore}
        />
      ),
    },
    {
      path: "/license/request",
      element: (
        <RequestLicense
          msgType={{
            code_msg: "",
            Regle: "",
            Message: "",
            Lib_url1: "",
            Url1: "",
            Lib_url2: "",
            Url2: "",
            Lib_url3: "",
            Url3: "",
          }}
          rootStore={rootStore}
        />
      ),
    },
    {
      path: "/license/:id/add",
      element: <AddLicense rootStore={rootStore} />,
    },
    {
      path: "/license/add",
      element: <AddLicense rootStore={rootStore} />,
    },
    {
      path: "/license/:id/edit",
      element: <AddLicense rootStore={rootStore} />,
    },
    {
      path: "/license/imput",
      element: <ImputLicence rootStore={rootStore} />,
    },
    /*{
      path: "/license/search",
      element: <SearchLicensev3 rootStore={rootStore} />,
    },*/
    {
      path: "/regulation/control-lists",
      element: <ControlLists rootStore={rootStore} />,
    },
    {
      path: "/regulation/control-lists-edit",
      element: <ControlListsEdit rootStore={rootStore} />,
    },
    {
      path: "/regulation/sanctions",
      element: <Sanctions rootStore={rootStore} />,
    },
    {
      path: "/regulation/sanctions-edit",
      element: <Sanctionsedit rootStore={rootStore} />,
    },
     {
      path: "/regulation/wassenaar-edit",
      element: <Wassenaaredit rootStore={rootStore} />,
    },
     {
      path: "/regulation/wassenaar",
      element: <Wassenaar rootStore={rootStore} />,
    },
    {
      path: "/regulation/state-portals",
      element: <StatePortal rootStore={rootStore} />,
    },
   
    {
      path: "/regulation/state-portals-edit",
      element: <StatePortaledit rootStore={rootStore} />,
    },
    {
      path: "/admin/users",
      element: <AdminUsers rootStore={rootStore} />,
    },
    {
      path: "/admin/tenants/entite",
      element: <Entite rootStore={rootStore} />,
    },
    {
      path: "/admin/tenants/entite_templete",
      element: <Entite_templete rootStore={rootStore} />,
    },
    {
      path: "/admin/log",
      element: <Log rootStore={rootStore} />,
    },
    {
      path: "/admin/logreplication",
      element: <Logreplication rootStore={rootStore} />,
    },
    {
      path: "/admin/report",
      element: <Report rootStore={rootStore} />,
    },
    {
      path: "/admin/reportsm",
      element: <Reportsm rootStore={rootStore} />,
    },
    {
      path: "/admin/countries",
      element: <AdminCountries rootStore={rootStore} />,
    },
    {
      path: "/admin/screening-tenant",
      element: <Screening_tenant rootStore={rootStore} />,
    },
    {
      path: "/admin/exportmsg",
      element: <Exportmsg rootStore={rootStore} />,
    },
    {
      path: "/admin/sanction",
      element: <Sanction rootStore={rootStore} />,
    },
    {
      path: "/admin/us-rules",
      element: <USRules rootStore={rootStore} />,
    },
    {
      path: "/admin/european-rules",
      element: <EuropeanRules rootStore={rootStore} />,
    },
    {
      path: "/admin/system",
      element: <System rootStore={rootStore} />,
    },
    {
      path: "/admin/screening",
      element: <Screening rootStore={rootStore} />,
    },
    {
      path: "/admin/classification-database",
      element: <ClassificationDatabase rootStore={rootStore} />,
    },
    {
      path: "/userspassword",
      element: <RecuperationAccount rootStore={rootStore} />,
    },
    {
      path: "/admin/tenants",
      element: <Tenants rootStore={rootStore} />,
    },
    {
      path: "/license/search",
      element: <PageTableLicence rootStore={rootStore} />,
    },
    {
      path: "/reportingpays",
      element: <ReportingPays rootStore={rootStore} />,
    },
  ];

  const [userRoutes, setUserRoutes] = useState<any[]>([]);
  useEffect(() => {
    const fetchUserRoutes = async () => {
      try {
        const routes = await rootStore.userStore.getUserRoutes();
        if (routes !== undefined) {
          setUserRoutes(routes);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des routes :", error);
      }
    };
    fetchUserRoutes();
  }, [rootStore.authStore.isLogged]);

  const userPathRegex = /^\/ui\/users\/([^\/]+)\/([^\/]+)$/;
  const userPwdRegex = /^\/ui\/userspassword\?id=\d+&token=[\w\d]+$/;

  if (!rootStore.authStore.isLogged) {
    if (
      location.pathname !== "/ui/login" &&
      !userPathRegex.test(location.pathname) &&
      !userPwdRegex.test(window.location.pathname + window.location.search)
    ) {
      window.location.replace("/ui/login");
    }
    return (
      <Router basename="/ui">
        <Routes>
          <Route path="/login" element={<Login rootStore={rootStore} />} />
          <Route path="/home" element={<Home rootStore={rootStore} />} />
          <Route path="/" element={<Home rootStore={rootStore} />} />
          <Route
            path="/userspassword"
            element={<RecuperationAccount rootStore={rootStore} />}
          />
          <Route
            path="/users/:id/:token"
            element={<ActivateUser rootStore={rootStore} />}
          />
          <Route element={<AppBar rootStore={rootStore} />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router basename="/ui">
        <Routes>
          <Route path="/" element={<Home rootStore={rootStore} />} />
          <Route path="/home" element={<Home rootStore={rootStore} />} />
          <Route path="/login" element={<Login rootStore={rootStore} />} />
          <Route
            path="/userspassword"
            element={<RecuperationAccount rootStore={rootStore} />}
          />
          <Route
            path="/users/:id/:token"
            element={<ActivateUser rootStore={rootStore} />}
          />
          <Route element={<AppBar rootStore={rootStore} />} />
          {userRoutes.map((userRoute) => {
            const matchingRoute = matching.find(
              (m) => m.path === userRoute.path
            );
            if (matchingRoute) {
              return (
                <Route
                  key={matchingRoute.path}
                  path={matchingRoute.path}
                  element={matchingRoute.element}
                />
              );
            }
            return null;
          })}
        </Routes>
      </Router>
    );
  }
};

export default inject("rootStore")(observer(AppRouter));
