type SortableItem = {
  [key: string]: string;
};

export function sortAlphabeticallyByKeySimple(
  array: SortableItem[],
  key: string,
): SortableItem[] {
  return [...array].sort((a, b) => a[key].localeCompare(b[key]));
}

// utils.ts

export function sortAlphabeticallyByKeyString<T extends { [key: string]: any }>(
  array: T[],
  key: string,
): T[] {
  return [...array].sort((a, b) =>
    String(a[key]).localeCompare(String(b[key])),
  );
}
