/* Styles import */
import styles from "../matching.module.css";

/* Global import */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import numbro from "numbro";
import i18n from "i18next";
import fileDownload from "js-file-download";
import Loader from "../../loader";
import equipment from "../equipment";
import EquipmentComponent from "../../equipment/equipment";
import { licenseStatus } from "../../../utils/generator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Stack from "@mui/material/Stack";
import { Typography } from "@material-ui/core";
import endUser from "../../../pages/screening/end-user";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
  license: MatchingLicenseDto;
  className?: "selected" | "deleted";
  selectedEquipmentItem?: any;
  clickable?: boolean;
  linked?: boolean;
  endUserFromLicense: string;
  partNumber: string;
  onClick?: (id: number) => void;
}

const downloadFile = (file: File) => fileDownload(file, file.name);

const MatchingLicenseComponent = ({
  license,
  className,
  selectedEquipmentItem,
  clickable,
  onClick,
  linked,
  rootStore,
  endUserFromLicense,
  partNumber,
}: Props) => {
  const licenseNumber = license?.license_number?.toString() ?? "";
  const equipmentUnitPrice =
    selectedEquipmentItem?.unitPrice || selectedEquipmentItem?.unit_price;
  const equipmentQuantityActual = selectedEquipmentItem?.quantity;
  const equipmentAmountActual = selectedEquipmentItem?.amount;
  const expirationDate = moment(
    license.expirationDate || license.expiration_date
  );
  const now = moment();
  const diffInmonths = Math.abs(expirationDate.diff(now, "months"));
  const [isErrorPresent, setError] = useState<boolean>(true);
  useEffect(() => {
    checkErrors();
  }, []);

  const hasFieldError = (field: string) => {
    const equip = selectedEquipmentItem;
    switch (field) {
      case "date":
        return (
          <div
            className={` ${
              moment().isAfter(expirationDate) && isErrorPresent
                ? styles.expired
                : styles.duration
            }`}
          >
            {moment().isBefore(expirationDate)
              ? i18n.t("pages.license.matching.expiredMessage", {
                  code: diffInmonths,
                })
              : i18n.t("pages.license.matching.expired")}
          </div>
        );
      case "qty":
        return (
          <div
            className={` ${
              (license.regulation === "FR_MUNITIONS_LIST" &&
                equip.quantity >
                  license.quantity_initial - license.quantity_ordered) ||
              (license.regulation !== "FR_MUNITIONS_LIST" &&
                equip.quantity > license.quantity_actual)
                ? styles.expired
                : styles.regular
            }`}
          >
            {license.regulation === "FR_MUNITIONS_LIST" &&
            license.quantity_initial - license.quantity_ordered
              ? i18n.t("pages.license.add.quantity") +
                " : " +
                (
                  license.quantity_initial - license.quantity_ordered
                ).toLocaleString()
              : i18n.t("pages.license.add.quantity") +
                  " : " +
                  license.quantity_actual
                ? i18n.t("pages.license.add.quantity") +
                  " : " +
                  license.quantity_actual
                : ""}
          </div>
        );
      case "price":
        return (
          <div
            className={` ${
              (license.unit_price &&
                equipmentUnitPrice > license.unit_price &&
                isErrorPresent) ||
              !license.unit_price
                ? styles.expired
                : styles.regular
            }`}
          >
            {license.unit_price
              ? i18n.t("pages.license.matching.unit_price") +
                " : " +
                license.unit_price
              : i18n.t("pages.license.matching.unit_price") + " : -"}
          </div>
        );
      case "amount":
        return (
          <div
            className={` ${
              equip.amount > license.amount_initial - license.amount_ordered &&
              isErrorPresent
                ? styles.expired
                : styles.regular
            }`}
          >
            {license.amount_initial && license.amount_ordered
              ? i18n.t("pages.license.add.amount") +
                " : " +
                (
                  license.amount_initial - license.amount_ordered
                ).toLocaleString()
              : i18n.t("pages.license.add.amount") + " : -"}
          </div>
        );
      default:
        return null; // Si le champ n'est pas reconnu
    }
  };

  const displayValuesErrors = () => {
    return (
      <Box>
        {hasFieldError("date")}
        <b>{i18n.t("pages.license.add.part-number") + " : "}</b>
        {partNumber ? partNumber : " - "}
        {hasFieldError("qty")}
        {hasFieldError("price")}
        {hasFieldError("amount")}
        <b>{i18n.t("pages.license.add.end-user") + " : "}</b>
        {endUserFromLicense ? endUserFromLicense : " - "}
      </Box>
    );
  };
  const getErrorMessageFRML = () => {
    const equip = selectedEquipmentItem;

    if (equip.quantity > license.quantity_initial - license.quantity_ordered) {
      return license.quantity_initial - license.quantity_ordered
        ? i18n.t("pages.license.add.quantity") +
            " : " +
            (
              license.quantity_initial - license.quantity_ordered
            ).toLocaleString()
        : i18n.t("pages.license.add.quantity");
    }
    if (equip.amount > license.amount_initial - license.amount_ordered) {
      return license.amount_initial && license.amount_ordered
        ? i18n.t("pages.license.add.amount") +
            " : " +
            (license.amount_initial - license.amount_ordered).toLocaleString()
        : i18n.t("pages.license.add.amount");
    }
    if (license.unit_price && equip.unit_price > license.unit_price) {
      return equipmentUnitPrice
        ? i18n.t("pages.license.matching.unit_price") +
            " : " +
            equipmentUnitPrice
        : i18n.t("pages.license.matching.unit_price");
    }

    return moment().isBefore(expirationDate)
      ? i18n.t("pages.license.matching.expiredMessage", { code: diffInmonths })
      : i18n.t("pages.license.matching.expired");
  };

  const getErrorMessage = () => {
    // Vérification de l'expiration de la licence
    if (moment().isAfter(expirationDate)) {
      return i18n.t("pages.license.matching.expired");
    }

    // Vérification du montant
    if (
      license.amount_actual === undefined ||
      license.amount_actual === null ||
      equipmentAmountActual > license.amount_actual
    ) {
      return license.amount_initial && license.amount_ordered
        ? i18n.t("pages.license.add.amount") +
            " : " +
            (license.amount_initial - license.amount_ordered).toLocaleString()
        : i18n.t("pages.license.add.amount");
    }

    // Vérification de la quantité
    if (
      license.quantity_actual === undefined ||
      license.quantity_actual === null ||
      equipmentQuantityActual > license.quantity_actual
    ) {
      return license.quantity_initial - license.quantity_ordered
        ? i18n.t("pages.license.add.quantity") +
            " : " +
            (
              license.quantity_initial - license.quantity_ordered
            ).toLocaleString()
        : i18n.t("pages.license.add.quantity");
    }

    // Vérification du prix unitaire de la licence
    if (
      license.unit_price === undefined ||
      license.unit_price === null ||
      equipmentUnitPrice > license.unit_price
    ) {
      return equipmentUnitPrice
        ? i18n.t("pages.license.matching.unit_price") +
            " : " +
            equipmentUnitPrice
        : i18n.t("pages.license.matching.unit_price");
    }

    if (
      equipmentUnitPrice === undefined ||
      equipmentUnitPrice === null ||
      equipmentUnitPrice > license.unit_price
    ) {
      return equipmentUnitPrice
        ? i18n.t("pages.license.matching.unit_price") +
            " : " +
            equipmentUnitPrice
        : i18n.t("pages.license.matching.unit_price");
    }

    // Si aucune des conditions ci-dessus n'est vraie, retourne un message par défaut
    return moment().isBefore(expirationDate)
      ? i18n.t("pages.license.matching.expiredMessage", { code: diffInmonths })
      : i18n.t("pages.license.matching.expired");
  };

  const checkErrors = () => {
    if (license.tmpStatus === "validFRML") {
      setError(false);
    } else {
      const expirationDate = moment(
        license.expirationDate || license.expiration_date
      );
      // debug
      // console.log("______________________");
      // console.log(licenseNumber);
      // console.log(license.regulation);
      // console.log(equipmentUnitPrice);
      // console.log("0 : ", moment().isAfter(expirationDate));
      // console.log("2 : ", license.amount_actual === undefined);
      // console.log("3 : ", license.amount_actual === null);
      // console.log("4 : ", equipmentAmountActual, " > ", license.amount_actual); // <- valable pour les licences PAS FR ML, sinon il faut prendre [initial - ordered]
      // console.log("5 : ", license.quantity_actual === undefined);
      // console.log("6 : ", license.quantity_actual === null);
      // console.log("7 : ", equipmentQuantityActual > license.quantity_actual); // <- valable pour les licences PAS FR ML, sinon il faut prendre [initial - ordered]
      // console.log("8 : ", license.unit_price === undefined);
      // console.log("9 : ", license.unit_price === null);
      // console.log("10 : ", equipmentUnitPrice === undefined);
      // console.log("11 : ", equipmentUnitPrice === null);
      // console.log("12 : ", equipmentUnitPrice, " > ", license.unit_price);
      // console.log("13 : ", license.unit_price);
      // debug
      if (
        moment().isAfter(expirationDate) ||
        license.amount_actual === undefined ||
        license.amount_actual === null ||
        (license.regulation !== "FR_MUNITIONS_LIST" &&
          equipmentAmountActual > license.amount_actual) ||
        license.quantity_actual === undefined ||
        license.quantity_actual === null ||
        (license.regulation !== "FR_MUNITIONS_LIST" &&
          equipmentQuantityActual > license.quantity_actual) ||
        license.unit_price === undefined ||
        license.unit_price === null ||
        // equipmentUnitPrice === undefined ||
        // equipmentUnitPrice === null ||
        equipmentUnitPrice > license.unit_price ||
        (license.regulation === "FR_MUNITIONS_LIST" &&
          equipmentQuantityActual >
            license.quantity_initial - license.quantity_ordered)
      ) {
        setError(true);
      } else {
        setError(false);
      }
    }
  };
  const [isLoading, setLoading] = useState(false);

  const calculQty = () => {
    let res = 0;
    if (license.regulation === "FR_MUNITIONS_LIST") {
      res =
        ((license.quantity_initial - license.quantity_ordered) * 100) /
          license.quantity_initial || 0;
      //(ini - order) * 100 / ini
    } else {
      res = (license.quantity_actual * 100) / license.quantity || 0;
    }
    return res;
  };

  const calculMts = () => {
    let res = 0;
    if (license.regulation === "FR_MUNITIONS_LIST") {
      res =
        ((license.amount_initial - license.amount_ordered) * 100) /
          license.amount_initial || 0;
      //(ini - order) * 100 / ini
    } else {
      res = (license.amount_actual * 100) / license.amount_initial || 0;
    }
    return res;
  };

  // debug utile
  // console.log("license : ", licenseNumber);
  // console.log("license REGULATION: ", license.regulation);
  // console.log("quantité : ", license.quantity);
  // console.log("quantité initiale: ", license.quantity_initial);
  // console.log("quantité actuelle: ", license.quantity_actual);
  // console.log("quantité commandée: ", license.quantity_ordered);
  // console.log("quantité livrée: ", license.quantity_delivered);
  // console.log("montant actuel : ", license.amount_actual);
  // console.log("montant initial : ", license.amount_initial);
  // console.log("montant commandé : ", license.amount_ordered);
  // console.log("montant livré : ", license.amount_delivered);

  const numbroParamsQty = {
    average: true,
  };
  const numbroParamsMts = {
    average: true,
    mantissa: 1,
  };

  const [hovered, setHovered] = useState(false);

  const handleMouseEnterInfo = () => {
    setHovered(true);
  };

  const handleMouseLeaveInfo = () => {
    setHovered(false);
  };
  return (
    <div
      // onMouseEnter={handleMouseEnterInfo}
      // onMouseLeave={handleMouseLeaveInfo}
      className={`${styles.license} ${styles[className || ""]} ${
        clickable ? styles.clickable : ""
      } ${isErrorPresent ? styles.expired_container : styles[className || ""]}`}
      onClick={() => {
        if (clickable && onClick) {
          onClick(license.id);
        }
      }}
    >
      <div className={styles.informations}>
        <Box
          sx={{
            width: !linked ? "50%" : "100%",
            transition: "background-color 0.8s",
            "&:hover": {},
          }}
        >
          <Box
            hidden={hovered}
            sx={{
              transition: "background-color 0.8s",
              "&:hover": {},
            }}
          >
            <>
              <div className={`${styles.licenseNumber} ${styles.maxLength}`}>
                {i18n.t("pages.license.matching.licenseNumber", {
                  interpolation: { escapeValue: false },
                  licenseNumber,
                })}
              </div>
              {license.type && (
                <div className={styles.type}>{license.type}</div>
              )}
              {/* <div className={styles.expiration}>
                {license.expiration_date &&
                  moment(license.expiration_date)
                    .locale(i18n.language)
                    .format(
                      i18n.language === "fr" ? "D MMM YYYY" : "MMM D, YYYY"
                    )}
              </div> */}
              {displayValuesErrors()}
            </>
          </Box>
          <Grow in={hovered} hidden={!hovered} mountOnEnter unmountOnExit>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Téléchargement en cours...");
              }}
            >
              Télécharger
            </Button> */}
            <Box
              sx={{ height: 125 }}
              component={Stack}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {isLoading && <Loader />}
              <div className={`${styles.licenseNumber} ${styles.maxLength}`}>
                {i18n.t("pages.license.matching.licenseNumber", {
                  interpolation: { escapeValue: false },
                  licenseNumber,
                })}
              </div>
              <Button
                sx={{ backgroundColor: "#0085AD" }}
                endIcon={<PictureAsPdf />}
                variant="contained"
                onClick={async (e) => {
                  setLoading(true);
                  const event = e || window.event;
                  event.stopPropagation();
                  if (license.media_id) {
                    const mediaLicense = await rootStore.licenseStore.getMedia(
                      license.media_id
                    );

                    if (mediaLicense) {
                      downloadFile(mediaLicense);
                    }
                  }
                  setLoading(false);
                }}
              >
                {i18n.t("pages.exportability.results.message.download")}{" "}
                {/* {licenseNumber} */}
              </Button>
            </Box>
          </Grow>
        </Box>
        {!linked && (
          <div className={styles.imputation}>
            <div>
              <CircularProgressbarWithChildren
                value={calculQty()}
                styles={buildStyles({
                  strokeLinecap: "round",
                  pathColor: "#0085ad",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              >
                {/* NUMERATEUR QTY : 
                  qty ini - qty ordered
                */}
                <div className={styles.imputationData}>
                  <div className={styles.left}>
                    {license.regulation === "FR_MUNITIONS_LIST" ? (
                      numbro(
                        license.quantity_initial - license.quantity_ordered
                      ).format(numbroParamsQty)
                    ) : license.quantity_actual ? (
                      numbro(license.quantity_actual).format(numbroParamsQty)
                    ) : (
                      <span className={styles.centeredText}>-</span>
                    )}
                  </div>

                  <div className={styles.imputationSeparator} />
                  <div className={styles.total}>
                    {license.regulation === "FR_MUNITIONS_LIST" ? (
                      numbro(license.quantity_initial).format(numbroParamsQty)
                    ) : license.quantity ? (
                      numbro(license.quantity).format(numbroParamsQty)
                    ) : (
                      <span className={styles.centeredText}>-</span>
                    )}
                  </div>
                </div>
                <div className={styles.labelGauge}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={require("../../../../statics/images/logo/logo_quantity_64.png")}
                      alt="Quantity Logo"
                      width={"35%"}
                    />
                  </div>
                </div>
              </CircularProgressbarWithChildren>
            </div>
            {/* {license?.regulation === "FR_MUNITIONS_LIST" && <div>allo</div>} */}
            <div>
              <CircularProgressbarWithChildren
                value={calculMts()}
                styles={buildStyles({
                  strokeLinecap: "round",
                  pathColor: "#0085ad",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              >
                {/* NUMERATEUR QTY : 
                  montant ini - montant ordered
                */}
                <div className={styles.imputationData}>
                  <div className={styles.left}>
                    {license.regulation === "FR_MUNITIONS_LIST" ? (
                      numbro(
                        license.amount_initial - license.amount_ordered
                      ).format(numbroParamsMts)
                    ) : license.amount_actual ? (
                      numbro(license.amount_actual).format(numbroParamsMts)
                    ) : (
                      <span className={styles.centeredText}>-</span>
                    )}{" "}
                  </div>
                  <div className={styles.imputationSeparator} />
                  <div className={styles.total}>
                    {license.amount_initial ? (
                      numbro(license.amount_initial).format(numbroParamsMts)
                    ) : (
                      <span className={styles.centeredText}>-</span>
                    )}{" "}
                  </div>
                </div>
                <div
                  className={styles.labelGauge}
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={
                      license?.regulation == "US_DUAL_USE_LIST_EAR" ||
                      license?.regulation == "US_MUNITIONS_LIST_ITAR"
                        ? require("../../../../statics/images/logo/logo_amount_dol_64.png")
                        : require("../../../../statics/images/logo/logo_amount_64.png")
                    }
                    alt="Amount Logo"
                    width={"50%"}
                  />
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchingLicenseComponent;
