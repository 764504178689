/* Styles import */
import countriesI18 from "i18n-iso-countries";
import styles from "../license.module.css";

/* Global import */
import React, { Component, SyntheticEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Title,
  GridContainer,
  GridRow,
  GridCol,
  FormGroup,
  TextInput,
  TextArea,
  RadioGroup,
  Radio,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import { FormLabel, FormControlLabel } from "@material-ui/core";
import Footer from "../../../components/footer";
import fileDownload from "js-file-download";
import {
  generateSelectOption,
  generateLicenseTypeOptions,
} from "../../../utils/generator";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router";
import moment from "moment";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderLicense from "../../../components/headerLicense";
import AutoComplete from "../../../components/autoComplete";
import ConsigneeComponent from "../../../components/consignee";
import EquipmentComponent from "../../../components/equipment";
import UploadInput from "../../../components/uploadInput";
import Loader from "../../../components/loader";
import {
  regulation,
  currency,
  countryCurrencyMap,
} from "../../../utils/constant";
import autoComplete from "../../../components/autoComplete";
import Api from "../../../utils/api";
import axios from "axios";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { Alert } from "@mui/material";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
countriesI18.registerLocale(require("i18n-iso-countries/langs/en.json"));
countriesI18.registerLocale(require("i18n-iso-countries/langs/fr.json"));

interface State {
  id?: number;
  licenseNumber?: string;
  model?: string;
  issuanceDate?: string;
  expirationDate?: string;
  uploadLicense?: File;
  uploadLicense1?: File;
  uploadLicense2?: File;
  uploadLicense3?: File;
  orderContract?: File;
  productInformation?: File;

  licenseType?: LicenseType;
  departureCountryId?: string;

  destinationCountryId?: number;
  customerDestinationCountryId?: number;
  firstDestinationCountryId?: number;
  intermediairDestinationCountryId?: number;
  endUser?: string;
  regulation?: LicenseRegulation;
  supplier?: string;
  destinataire?: string;
  provisos?: string;
  num_order?: string;
  manufacturer?: string;
  currency?: string;
  totalAmount?: number;
  equipments: LicenseEquipment[];
  consignees: Consignee[];
  endUse: EndUseType;
  countries: [];
  endUseComment?: string;
  comment?: string;
  cnr?: boolean;
  cuf?: boolean;
  firstRecipient?: string;
  temporaryLicense?: boolean;
  messageCnr1?: string;
  messageCnr2?: string;
  R_checklist: R_entite[];
  entite: string;
}
type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const AddLicense: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const msg = {
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  };
  const MlMsg = {
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [uploadState, setUploadState] = useState({
    uploadLicense: {},
    uploadLicense1: {},
    uploadLicense2: {},
    uploadLicense3: {},
    orderContract: {},
    productInformation: {},
  });

  const [state, setState] = useState({
    vertical: "top",
    horizontal: "center",
    currency: "",
    endUseComment: "",
    destinataire: "",
    issuanceDate: "",
    departureCountryId: "",
    licenseNumber: "",
    expirationDate: "",
    destinationCountryId: 0,
    endUser: "",
    totalAmount: 0,
    firstRecipient: "",
    equipments: [{} as LicenseEquipment],
    customerDestinationCountryId: 0,
    firstDestinationCountryId: 0,
    licenseType: "" as LicenseType,
    intermediairDestinationCountryId: 0,
    model: "",
    supplier: "",
    temporaryLicense: false,
    comment: "",
    cnr: false,
    provisos: "",
    num_order: "",
    manufacturer: "",
    id: 0,
    consignees: [
      {
        consigneeCountryId: 0,
        consigneeName: "",
      },
    ],
    endUse: "CIVIL" as EndUseType,
    messageCnr1: "",
    messageCnr2: "",
    //cnr:false,
    cuf: false,
    regulation: "" as LicenseRegulation,
    // entite: rootStore.userStore.user?.entite || "",
    R_checklist: [],
    entities: rootStore.tenantStore.entities,
  });
  const { vertical, horizontal } = state;
  const [open, setSnackOpen] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [statusSnack, setStatusSnack] = useState(0);
  const handleClose = () => {
    setSnackOpen(false);
  };
  const [disabledByRDO, setDisabledByRDO] = useState<boolean>(
    rootStore.userStore.isAuthorized(["L_R_A"]) ? true : false
  );
  const [loading, setLoading] = useState<boolean>(false);
  // const [entite, setEntite] = useState<string | undefined>(
  //   rootStore.userStore.user?.entite !== "ALL"
  //     ? rootStore.userStore.user?.entite
  //     : "ALL"
  // );
  const [entite, setEntite] = useState<string | undefined>(
    rootStore.licenseStore.entite !== "ALL"
      ? rootStore.licenseStore.entite
      : "ALL"
  );
  const [countries, setCountries] = useState([
    {
      label: "",
      value: "",
      id: 0,
    },
  ]);

  const { id } = useParams();
  // eslint-disable-next-line
  useEffect(() => {
    // Équivalent à componentDidMount
    rootStore.countriesStore.getCountries();
    rootStore.modelStore.getModels();

    const fetchData2 = async () => {
      const tenant = rootStore.userStore.user?.tenantId!;
      const response1 = await fetch(
        `${process.env.REACT_APP_SCREEN}/get_entite?tenant=` +
          encodeURIComponent(tenant || "")
      );
      const data1 = await response1.json();
      setState({ ...state, R_checklist: data1 });
    };

    const fetchData = async () => {
      try {
        const msg = "EXP068";
        const bmsg = "EXP064";

        // Récupérer les pays
        const countriesResponse = await axios.get(
          `${process.env.REACT_APP_SCREEN}/countries_departure`
        );
        setCountries(countriesResponse.data);

        // Effectuer les appels asynchrones pour convertir msg et MLmsg
        const msgResult = await api.GcodeToMsg(msg);
        const MLmsgResult = await api.GcodeToMsg(bmsg);

        // Récupérer les paramètres d'URL
        if (id) {
          const parsedId = parseInt(id, 10);
          if (!isNaN(parsedId)) {
            const licenseId = parseInt(id);
            if (licenseId) {
              // Effectuer des opérations avec la licence
              getLicense(licenseId);
            }
          }
        }
      } catch (error) {
        console.error("Erreur :", error);
      }
    };

    fetchData();
    fetchData2();
  }, [rootStore.licenseStore]);

  /** Check for regulation FR */

  const isRegulationApplicableFR = () => {
    const { regulation } = state;
    return (
      regulation === "FR_LIST_DOUBLE_USAGE" ||
      regulation === "EU_DUAL_USE_LIST" ||
      regulation === "FR_MUNITIONS_LIST" ||
      regulation === "EU_MUNITIONS_LIST"
    );
  };

  const isRegulationApplicableFR_DUAL = () => {
    const { regulation } = state;
    return (
      regulation === "FR_LIST_DOUBLE_USAGE" || regulation === "EU_DUAL_USE_LIST"
    );
  };

  const isRegulationApplicableFR_Munition = () => {
    const { regulation } = state;
    return (
      regulation === "FR_MUNITIONS_LIST" || regulation === "EU_MUNITIONS_LIST"
    );
  };

  const pdfSubmit = async (file: File) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        if (file) {
          data.append("file", file);
        }

        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/pdfsubmit`,
          headers: {
            "content-type": "multipart/form-data",
          },

          data,
        });
        if (response.data[20] === "FR") {
          handleFrenchPDF(response.data);
        } else if (response.data[20] === "FR_DU_GLOB") {
          handleFR_DU_GLOB_PDF(response.data);
        } else if (response.data[20] === "NL") {
          handleNlPDF(response.data);
        } else if (response.data[20] === "FR_ML") {
          handleFR_MLPDF(response.data);
        } else if (response.data[20] === "FR_EU001") {
          handleFR_EU001(response.data);
        }
        resolve(response.data);

        let emptyCount = 0;

        for (var i = 0; i <= 11; i++) {
          if (response.data[i] === "") {
            emptyCount++;
          }
        }

        if (emptyCount >= 8) {
          alert(i18n.t("pages.license.add.pdf-not-ok"));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleNlPDF = async (data: any) => {
    setState({
      ...state,
      expirationDate: moment(data[0]).format("YYYY-MM-DD"),
    });
    setState({ ...state, licenseNumber: data[1] });

    //setState({codedouanier:response.data[3]}) todo champs a rajouté
    //setState({equipments:response.data[4]})
    setState({ ...state, currency: "EUR" });
    setState({ ...state, endUseComment: data[7] });
    setState({ ...state, destinataire: data[9] });

    setState({ ...state, issuanceDate: moment(data[8]).format("YYYY-MM-DD") });

    setState({ ...state, departureCountryId: data[10] });
    if (data[11].includes("TWEEËRLEI GEBRUIK")) {
      setState({ ...state, regulation: "EU_DUAL_USE_LIST" });
    }

    const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

    /** Special Code */

    const specialCountryNames: Record<CountryCodeDest, string> = {
      CN: "China",
      UA: "Ukraine mainland",
      KR: "Korea, South",
      US: "United States of America (USA)",
    };

    let countryName = countriesI18.getName(countryCode, "en");
    if (specialCountryNames.hasOwnProperty(countryCode)) {
      countryName = specialCountryNames[countryCode];
    }

    const country = rootStore.countriesStore.countries.find(
      (country) => country.name === countryName
    );

    if (country) {
      setState({ ...state, destinationCountryId: country.id });
    }
    setState({ ...state, endUser: data[13] });

    setState({ ...state, licenseType: data[16] });
    let equipment_add: LicenseEquipment;
    equipment_add = {};
    let items = data[14];
    for (let i = 0; i < items.length; i++) {
      equipment_add = {};
      equipment_add.model = items[i][2].replace("\t\nF.S.\n0\t\n", "");
      equipment_add.eccnCode = items[i][4];
      equipment_add.keyword = items[i][3];
      equipment_add.amountInitial = parseFloat(items[i][5]);
      equipment_add.amountActual = parseFloat(items[i][5]);
      equipment_add.quantity = parseFloat(items[i][6]);
      equipment_add.quantityActual = parseFloat(items[i][6]);

      handleEquipment(i, equipment_add);
    }
  };

  const convertDateFormat = async (inputDate: any) => {
    // Convert "dd-mm-yyyy" to "mm/dd/yyyy"
    let [day, month, year] = inputDate.split("-");
    let formattedDate = `${month}/${day}/${year}`;

    let date = new Date(formattedDate);

    // Get the parts of the date
    let yearStr = date.getFullYear();
    let monthStr = (date.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-indexed
    let dayStr = date.getDate().toString().padStart(2, "0");

    // Convert to "yyyy-mm-dd"
    return `${yearStr}-${monthStr}-${dayStr}`;
  };

  const handleCloseSnackbar = (
    event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleFrenchPDF = async (data: any) => {
    let newState = { ...state };

    newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
    newState.licenseNumber = data[1];
    newState.currency = "EUR";
    newState.endUseComment = data[7];
    newState.destinataire = data[9];
    newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
    newState.departureCountryId = data[10];
    newState.regulation = data[11];
    newState.destinationCountryId = data[12] || 0;

    const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

    /** Special Code */
    const specialCountryNames: Record<CountryCodeDest, string> = {
      CN: "China",
      UA: "Ukraine mainland",
      KR: "Korea, South",
      US: "United States of America (USA)",
    };

    let countryName = countriesI18.getName(countryCode, "en");
    if (specialCountryNames.hasOwnProperty(countryCode)) {
      countryName = specialCountryNames[countryCode];
    }
    if (countryName && countryCode == ("TW" as CountryCodeDest)) {
      countryName = countryName
        ?.toLowerCase()
        .replace(", province of china", "")
        .trim();
      countryName = countryName.charAt(0).toUpperCase() + countryName.slice(1);
    }
    const country = rootStore.countriesStore.countries.find(
      (c) => c.name === countryName
    );

    if (country) {
      newState.destinationCountryId = country.id;
    }

    newState.endUser = data[13];
    newState.licenseType = data[16];

    let items = data[14];
    let newEquipments = [...state.equipments];
    items.forEach((item: any, i: number) => {
      let equipment_add = {
        model: item[2].replace("\t\nF.S.\n0\t\n", ""),
        eccnCode: item[4],
        keyword: item[3],
        amountInitial: parseFloat(item[5]),
        amountActual: parseFloat(item[5]),
        quantity: parseFloat(item[6]),
        quantityActual: parseFloat(item[6]),
      };

      if (i < newEquipments.length) {
        newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
      } else {
        newEquipments.push(equipment_add);
      }
    });
    newState = { ...newState, equipments: newEquipments ? newEquipments : [] };

    setState(newState);
  };

  const handleFR_DU_GLOB_PDF = async (data: any) => {
    let newState = { ...state };

    newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
    newState.licenseNumber = data[1];
    newState.currency = "EUR";
    newState.endUseComment = data[7];
    newState.destinataire = data[9];
    newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
    newState.departureCountryId = data[10];
    newState.regulation = data[11];

    const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

    /** Special Code */
    const specialCountryNames: Record<CountryCodeDest, string> = {
      CN: "China",
      UA: "Ukraine mainland",
      KR: "Korea, South",
      US: "United States of America (USA)",
    };

    let countryName = countriesI18.getName(countryCode, "en");
    if (specialCountryNames.hasOwnProperty(countryCode)) {
      countryName = specialCountryNames[countryCode];
    }

    const country = rootStore.countriesStore.countries.find(
      (c) => c.name === countryName
    );

    if (country) {
      newState.destinationCountryId = country.id;
    }

    newState.endUser = data[13];
    newState.licenseType = "GLOBAL";

    let items = data[14];
    let newEquipments = [...state.equipments];
    items.forEach((item: any, i: number) => {
      let equipment_add = {
        model: item[2].replace("\t\nF.S.\n0\t\n", ""),
        eccnCode: item[4],
        keyword: item[3],
        amountInitial: parseFloat(item[5]),
        amountActual: parseFloat(item[5]),
        quantity: parseFloat(item[6]),
        quantityActual: parseFloat(item[6]),
      };

      if (i < newEquipments.length) {
        newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
      } else {
        newEquipments.push(equipment_add); // Ajout d'un nouvel élément
      }
    });
    newState = { ...newState, equipments: newEquipments ? newEquipments : [] };

    setState(newState);
  };

  const handleFR_EU001 = async (data: any) => {
    let newState = { ...state };

    newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
    newState.licenseNumber = data[1];
    newState.currency = "EUR";
    newState.endUseComment = data[7];
    newState.destinataire = data[9];
    newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
    newState.departureCountryId = data[10];
    newState.regulation = data[11];

    const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

    /** Special Code */
    const specialCountryNames: Record<CountryCodeDest, string> = {
      CN: "China",
      UA: "Ukraine mainland",
      KR: "Korea, South",
      US: "United States of America (USA)",
    };

    let countryName = countriesI18.getName(countryCode, "en");
    if (specialCountryNames.hasOwnProperty(countryCode)) {
      countryName = specialCountryNames[countryCode];
    }

    // Je note que vous définissez une date d'expiration et un identifiant de pays de destination statiques ici.
    newState.expirationDate = moment("12/31/2030").format("YYYY-MM-DD");
    newState.destinationCountryId = 999; // Assurez-vous que cette valeur est correcte pour votre cas d'utilisation.
    newState.endUser = data[13];
    newState.licenseType = "EU001_AUTOR_GENERAL";

    let items = data[14];
    // Assurez-vous d'ajouter la logique pour gérer les éléments 'items'
    let newEquipments = [...state.equipments];
    items.forEach((item: any, i: number) => {
      let equipment_add = {
        model: item[2].replace("\t\nF.S.\n0\t\n", ""),
        eccnCode: item[4],
        keyword: item[3],
        amountInitial: parseFloat(item[5]),
        amountActual: parseFloat(item[5]),
        quantity: parseFloat(item[6]),
        quantityActual: parseFloat(item[6]),
      };

      if (i < newEquipments.length) {
        newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
      } else {
        newEquipments.push(equipment_add); // Ajout d'un nouvel élément
      }
    });
    newState = { ...newState, equipments: newEquipments ? newEquipments : [] };

    setState(newState);
  };

  const handleFR_MLPDF = async (data: any) => {
    let newState = { ...state };

    // Gérer les opérations asynchrones avant de mettre à jour l'état
    const formattedExpirationDate = await convertDateFormat(data[0]);
    const formattedIssuanceDate = await convertDateFormat(data[8]);

    newState.expirationDate = formattedExpirationDate;
    newState.licenseNumber = data[1];
    newState.currency = "EUR";
    newState.endUseComment = data[7];
    newState.destinataire = data[9];
    newState.issuanceDate = formattedIssuanceDate;
    newState.regulation = data[11];
    newState.departureCountryId = data[10];

    /** Special Code */
    const specialCountryNames: Record<CountryCodeDest, string> = {
      CN: "China",
      UA: "Ukraine mainland",
      KR: "Korea, South",
      US: "United States of America (USA)",
    };
    let countryNameInFrench = data[12];

    let countryCode = countriesI18.getAlpha2Code(countryNameInFrench, "fr");

    if (countryCode) {
      let countryNameInEnglish = countriesI18.getName(countryCode, "en");
      const country = rootStore.countriesStore.countries.find(
        (c) => c.name === countryNameInEnglish
      );

      if (country) {
        newState.destinationCountryId = country.id;
      }

      if (data[16] === "GLOBAL") {
        newState.destinationCountryId = 999; // Vérifiez que cette valeur est correcte.
      }

      newState.endUser = data[13];
      newState.licenseType = data[16];
      newState.totalAmount = data[21];
      newState.firstRecipient = data[22];
      newState.messageCnr1 = data[23];
      newState.messageCnr2 = data[24];
    }

    setState(newState);
  };

  const getLicense = async (id: number) => {
    const tenant = rootStore.userStore.user?.tenantId!;
    const response1 = await fetch(
      `${process.env.REACT_APP_SCREEN}/get_entite?tenant=` +
        encodeURIComponent(tenant || "")
    );
    const data1 = await response1.json();
    setState({ ...state, R_checklist: data1 });
    const licenseInfo = await rootStore.licenseStore.searchLicenseById(id);
    if (rootStore.licenseStore.entite) {
      setEntite(rootStore.licenseStore.entite);
    } else {
      setEntite(rootStore.userStore.user?.entite);
    }
    if (licenseInfo) {
      setState({
        ...state,
        id: licenseInfo.id as number,
        regulation: licenseInfo.regulation,
        temporaryLicense: licenseInfo.temporaryLicense as boolean,
        model: licenseInfo.model as string,
        supplier: licenseInfo.supplier as string,
        destinataire: licenseInfo.destinataire as string,
        provisos: licenseInfo.provisos as string,
        num_order: licenseInfo.num_order as string,
        manufacturer: licenseInfo.manufacturer as string,
        licenseType: licenseInfo.licenseType,
        departureCountryId: licenseInfo.departureCountryId,
        //entite: licenseInfo.entite,
        destinationCountryId: licenseInfo.destinationCountryId,
        endUser: licenseInfo.companyName,
        currency: licenseInfo.currency as string,
        cnr: licenseInfo.cnr as boolean,
        cuf: licenseInfo.cuf as boolean,
        customerDestinationCountryId:
          licenseInfo.customerDestinationCountryId as number,
        firstDestinationCountryId:
          licenseInfo.firstDestinationCountryId as number,
        intermediairDestinationCountryId:
          licenseInfo.intermediairDestinationCountryId as number,
        R_checklist: data1,
        consignees: (licenseInfo.consignees.length &&
          licenseInfo.consignees) || [
          {
            consigneeCountryId: 0,
            consigneeName: "",
          },
        ],
        totalAmount: licenseInfo.totalAmount as number,
        equipments: (licenseInfo.equipments.length &&
          licenseInfo.equipments) || [{}],
        endUse: licenseInfo.endUse || "CIVIL",
        endUseComment: licenseInfo.endUseComment || "",
        comment: licenseInfo.comment as string,
        licenseNumber: licenseInfo.licenseNumber as string,
        firstRecipient: licenseInfo.recipient as string,
        issuanceDate:
          (licenseInfo.issuanceDate &&
            moment(licenseInfo.issuanceDate).format("YYYY-MM-DD")) ||
          "",
        expirationDate:
          (licenseInfo.expirationDate &&
            moment(licenseInfo.expirationDate).format("YYYY-MM-DD")) ||
          "",
      });

      if (licenseInfo.mediaId) {
        const mediaLicense = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId
        );

        if (mediaLicense) {
          setUploadState({
            ...uploadState,
            uploadLicense: mediaLicense as File,
          });
        }
      }

      if (licenseInfo.mediaId1) {
        let mediaLicense1 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId1
        );

        if (mediaLicense1) {
          setUploadState({
            ...uploadState,
            uploadLicense1: mediaLicense1 as File,
          });
        }
      }

      if (licenseInfo.mediaId2) {
        let mediaLicense2 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId2
        );

        if (mediaLicense2) {
          setUploadState({
            ...uploadState,
            uploadLicense2: mediaLicense2 as File,
          });
        }
      }

      if (licenseInfo.mediaId3) {
        let mediaLicense3 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId3
        );

        if (mediaLicense3) {
          setUploadState({
            ...uploadState,
            uploadLicense3: mediaLicense3 as File,
          });
        }
      }

      if (licenseInfo.mediaId4) {
        let mediaLicense4 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId4
        );

        if (mediaLicense4) {
          setUploadState({
            ...uploadState,
            orderContract: mediaLicense4 as File,
          });
        }
      }
      if (licenseInfo.mediaId5) {
        let mediaLicense5 = await rootStore.licenseStore.getMedia(
          licenseInfo.mediaId5
        );

        if (mediaLicense5) {
          setUploadState({
            ...uploadState,
            productInformation: mediaLicense5 as File,
          });
        }
      }
    }
  };

  const handleEquipment = (index: number, equipment: LicenseEquipment) => {
    const equipments = [...state.equipments];
    equipments[index] = equipment;
    setState({ ...state, equipments });
  };

  const prepareEquipmentUpdate = (
    index: number,
    equipment: LicenseEquipment
  ) => {
    const newEquipments = [...state.equipments];
    newEquipments[index] = equipment;
    return newEquipments;
  };

  const removeEquipment = (index: number) => {
    const equipments = [...state.equipments];
    equipments.splice(index, 1);
    setState({ ...state, equipments });
  };

  const addEquipment = () => {
    const equipments = [...state.equipments];
    equipments.push({});
    setState({ ...state, equipments });
  };

  const handleConsignee = (index: number, consignee: Consignee) => {
    const consignees = [...state.consignees];

    consignees[index] = consignee;
    setState({ ...state, consignees });
  };

  const addConsignee = () => {
    const consignees = [...state.consignees];

    consignees.push({
      consigneeCountryId: 0,
      consigneeName: "",
    });
    setState({ ...state, consignees });
  };

  const downloadFile = (file: File) => {
    setLoading(true);
    fileDownload(file, file.name);
    setLoading(false);
  };

  const formatLocalDateTime = (date: string) => {
    const tmp = new Date(date);
    const year = tmp.getFullYear();
    const month = String(tmp.getMonth() + 1).padStart(2, "0");
    const day = String(tmp.getDate()).padStart(2, "0");
    const hours = String(tmp.getHours()).padStart(2, "0");
    const minutes = String(tmp.getMinutes()).padStart(2, "0");
    const seconds = String(tmp.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);

    const {
      licenseNumber,
      model,
      issuanceDate,
      expirationDate,

      supplier,
      temporaryLicense,
      comment,
      cnr,
      provisos,
      num_order,
      manufacturer,
      endUser,
      regulation,
      destinataire,
      licenseType,
      departureCountryId,
      customerDestinationCountryId,
      intermediairDestinationCountryId,
      firstDestinationCountryId,
      destinationCountryId,
      currency,
      totalAmount,
      equipments,
      consignees,
      endUse,
      endUseComment,
      cuf,
      firstRecipient,
      //entite,
    } = state;

    const {
      uploadLicense,
      uploadLicense1,
      uploadLicense2,
      uploadLicense3,
      orderContract,
      productInformation,
    } = uploadState;
    if (
      licenseNumber &&
      issuanceDate &&
      expirationDate &&
      uploadLicense &&
      licenseType &&
      destinationCountryId &&
      departureCountryId &&
      endUser &&
      regulation
      //entite
    ) {
      try {
        /*let entite = state.entite;

        if (rootStore.userStore.user?.entite !== "ALL") {
          entite = rootStore.userStore.user?.entite || "";
        }*/
        // if (rootStore.userStore.user?.entite !== "ALL") {
        //   setEntite(rootStore.userStore.user?.entite || "");
        // }
        await rootStore.licenseStore.postLicense(
          {
            entite: entite as string,
            id: state.id || undefined,
            licenseNumber: licenseNumber,
            issuanceDate: issuanceDate, //formatLocalDateTime(issuanceDate),
            expirationDate: expirationDate, //formatLocalDateTime(expirationDate),
            licenseType: licenseType,
            departureCountryId: departureCountryId,
            destinationCountryId: destinationCountryId,
            customerDestinationCountryId: customerDestinationCountryId,
            intermediairDestinationCountryId: intermediairDestinationCountryId,
            firstDestinationCountryId: firstDestinationCountryId,
            model: model,
            companyName: endUser,
            status: "ACTIVE",
            regulation: regulation,
            supplier: supplier,
            destinataire: destinataire,
            provisos: provisos,
            num_order: num_order,
            manufacturer: manufacturer,
            currency: currency,
            totalAmount: totalAmount,
            equipments: equipments,
            consignees: consignees,
            endUse: endUse,
            endUseComment: endUseComment,
            comment: comment,
            cnr: cnr,
            cuf: cuf,
            recipient: firstRecipient,
            temporaryLicense: temporaryLicense,
          },
          uploadLicense as File,
          uploadLicense1 as File,
          uploadLicense2 as File,
          uploadLicense3 as File,
          orderContract as File,
          productInformation as File
        );
        setLoading(false);
        setMessageNotification(i18n.t("notifications.form.success"));
        setStatusSnack(0);
        setSnackOpen(true);
        setTimeout(() => {
          navigate("/license/search");
        }, 2000);
      } catch (error) {
        setMessageNotification(i18n.t("notifications.form.failure"));
        setStatusSnack(1);
        setSnackOpen(true);
        setLoading(false);
      }
    } else {
      setMessageNotification(i18n.t("notifications.form.failure"));
      setStatusSnack(1);
      setSnackOpen(true);
      setLoading(false);
    }
  };

  const requestIsDisabled = () => {
    const {
      licenseType,
      departureCountryId,
      destinationCountryId,
      endUser,
      regulation,
    } = state;

    return (
      licenseType === undefined ||
      departureCountryId === undefined ||
      destinationCountryId === undefined ||
      endUser === undefined ||
      regulation === undefined
    );
  };

  const allEquipmentsValid = () => {
    const { equipments } = state;
    //on valide différement si c'est du ML
    return equipments.every((equipment) => {
      if (state.regulation === "FR_MUNITIONS_LIST") {
        return (
          equipment.amountInitial !== undefined &&
          equipment.amountInitial !== null &&
          equipment.quantityInitial !== undefined &&
          equipment.quantityInitial !== null &&
          equipment.quantity !== undefined &&
          equipment.quantity !== null &&
          Boolean(equipment.eccnCode)
        );
      } else {
        return (
          //todo : refacto cette partie pas ouf
          !isNaN(equipment.quantity as number) &&
          !isNaN(equipment.quantityActual as number) &&
          !isNaN(equipment.amountInitial as number) &&
          !isNaN(equipment.amountActual as number) &&
          equipment.quantity !== undefined &&
          equipment.quantity !== null &&
          equipment.quantityActual !== undefined &&
          equipment.quantityActual !== null &&
          equipment.amountInitial !== undefined &&
          equipment.amountInitial !== null &&
          equipment.amountActual !== undefined &&
          equipment.amountActual !== null &&
          Boolean(equipment.eccnCode)
        );
      }
    });
  };

  const submitIsDisabled = () => {
    let isDestinationAndEndUserUndefined = false;

    if (state.licenseType !== "EU001_AUTOR_GENERAL") {
      if (
        state.regulation !== "FR_MUNITIONS_LIST" ||
        state.licenseType !== "GLOBAL"
      ) {
        isDestinationAndEndUserUndefined =
          state.destinationCountryId === undefined ||
          state.endUser === undefined;
      }
    } else {
      // Si licenseType est "EU001_AUTOR_GENERAL", nous vérifions seulement endUser
      isDestinationAndEndUserUndefined = state.endUser === undefined;
    }
    return (
      state.licenseNumber === undefined ||
      state.issuanceDate === undefined ||
      state.expirationDate === undefined ||
      uploadState.uploadLicense === undefined ||
      state.licenseType === undefined ||
      state.departureCountryId === undefined ||
      isDestinationAndEndUserUndefined ||
      regulation === undefined ||
      disabledByRDO ||
      !allEquipmentsValid() // ajoutez cette ligne pour vérifier si tous les équipements sont valides
    );
  };

  const selection_pay = (s: string) => {
    var options = document.querySelectorAll("#regulation option");
    const sb = document.getElementById("regulation") as HTMLSelectElement;
    const currencyForCountry = getCurrencyForCountry(s);
    setState({ ...state, departureCountryId: s, currency: currencyForCountry });
    options.forEach((o) => o.remove());

    const option_tmp1 = new Option(i18n.t("pages.new-flow.select-value"), "");
    sb.options.add(option_tmp1, undefined);
    regulation.map((option, index) => {
      if (
        option.label.substring(0, 2) == "EU" &&
        (s == "BE" || s == "NL" || s == "SE" || s == "SG" || s == "IT")
      ) {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
      if (option.label.substring(0, 2) == "UK" && s == "GB") {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
    });

    regulation.map((option, index) => {
      if (
        option.label.substring(0, 2) == s ||
        option.label.substring(0, 2) == "US" ||
        s == "AL"
      ) {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
        //console.log(option_tmp);
      }
    });
  };

  const getCurrencyForCountry = (countryCode: string) => {
    const currencyCode = countryCurrencyMap[countryCode];
    const currencyObj = currency.find((cur) => cur.value === currencyCode);

    return currencyObj ? currencyObj.value : "";
  };

  const show_FR = isRegulationApplicableFR();
  const showFR_DUAL = isRegulationApplicableFR_DUAL();
  const showFR_Munition = isRegulationApplicableFR_Munition();
  const displayTitle = () => {
    if (id) {
      // licence : readonly (LR)
      if (rootStore.userStore.isAuthorized(["L_R_A"])) {
        return <Title tag="h2">{i18n.t("pages.license.menu.view")}</Title>;
      } else {
        return <Title tag="h2">{i18n.t("pages.license.menu.edit")}</Title>;
      }
    } else {
      return <Title tag="h2">{i18n.t("pages.license.menu.add")}</Title>;
    }
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />

      {rootStore.userStore.isAuthorized(["L_R_A"]) ? (
        <HeaderLicense pages="VIEW" rootStore={rootStore} id={id} />
      ) : (
        <HeaderLicense pages="ADD" rootStore={rootStore} />
      )}

      {loading && <Loader />}

      <div className={styles.body}>
        {displayTitle()}

        <GridRow>
          <GridCol md={9} xs={8} alignSelf="center">
            {!state.id && i18n.t("pages.license.menu.msg")}
          </GridCol>
          <GridCol md={3} xs={4} alignSelf="center">
            <FormLabel className={styles.label} required={true}>
              {i18n.t("pages.license.add.upload-license")}
            </FormLabel>
            <UploadInput
              value={uploadState.uploadLicense as File}
              disabled={disabledByRDO}
              onChange={(file) => {
                setUploadState({ ...uploadState, uploadLicense: file });

                pdfSubmit(file);
              }}
            />
            {uploadState.uploadLicense && (
              <Button
                type="button"
                className={styles.preview}
                onClick={() => downloadFile(uploadState.uploadLicense as File)}
              >
                {i18n.t("pages.license.add.preview-upload")}
              </Button>
            )}
          </GridCol>
        </GridRow>
        <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
          <GridContainer>
            <GridRow>
              <GridCol md={3} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.exportability.departure-country")}
                  </FormLabel>

                  <select
                    id="departure-country"
                    required={false}
                    value={state.departureCountryId}
                    disabled={disabledByRDO}
                    onChange={(e) => {
                      selection_pay(e.target.value);
                    }}
                  >
                    <option value="AL">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {generateSelectOption(countries)}
                  </select>
                </FormGroup>
              </GridCol>
              {/* {"entite: " + entite} */}
              {rootStore.tenantStore.entities.length >
                0 /*&& entite !== ""*/ && (
                // rootStore.userStore.user?.entite === "ALL" &&
                <GridCol md={3} xs={12} alignSelf="center">
                  <FormGroup>
                    <FormLabel className={styles.label} required={true}>
                      {i18n.t("pages.license.add.exporter")}
                    </FormLabel>
                    <select
                      onChange={
                        (e) => setEntite(e.target.value)
                        //setState({ ...state, entite: e.target.value })
                      }
                      value={entite}
                    >
                      {/* Option "ALL" ajoutée ici */}
                      <option value="ALL">ALL</option>
                      {rootStore.tenantStore.entities.map(
                        (entite: any, index) => {
                          return (
                            <option key={index} value={entite.id_entite || ""}>
                              {entite.id_entite}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </FormGroup>
                </GridCol>
              )}
              <GridCol md={3} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.license.add.regulation")}
                  </FormLabel>
                  <select
                    id="regulation"
                    value={state.regulation || ""}
                    required={true}
                    disabled={disabledByRDO}
                    onChange={(e) => {
                      setState({
                        ...state,
                        regulation: e.target.value as LicenseRegulation,
                      });
                    }}
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>

                    {generateSelectOption(regulation)}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={3} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.license.add.license-type")}
                  </FormLabel>
                  <select
                    id="license-type"
                    value={state.licenseType || ""}
                    required={true}
                    disabled={disabledByRDO}
                    onChange={(e) => {
                      setState({
                        ...state,
                        licenseType: e.target.value as LicenseType,
                      });
                    }}
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {state.regulation === "US_DUAL_USE_LIST_EAR" &&
                      generateLicenseTypeOptions(["INDIVIDUAL"])}

                    {state.regulation === "US_MUNITIONS_LIST_ITAR" &&
                      generateLicenseTypeOptions([
                        "DSP5_PERMANENT",
                        "DSP73_TEMPORARY",
                        "WDA_WAREHOUSE_AGREEMENT",
                      ])}

                    {(state.regulation === "EU_DUAL_USE_LIST" ||
                      state.regulation === "FR_ARRETE_DU_31_JUILLET_2014_HEL" ||
                      state.regulation === "FR_LIST_DOUBLE_USAGE") &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "EU001_AUTOR_GENERAL",
                        "PERMANENT",
                      ])}

                    {state.regulation === "FR_MUNITIONS_LIST" &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "LGT",
                      ])}

                    {state.regulation === "FR_IMPORTATION" &&
                      generateLicenseTypeOptions([
                        "AIPE",
                        "AIMG",
                        "AITMG",
                        "AGIMG",
                      ])}

                    {(state.regulation === "DE_DUAL_USE_GUTER" ||
                      state.regulation === "DE_MUNITIONS_LIST") &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "COLLECTIVE",
                      ])}

                    {(state.regulation === "UK_DUAL_USE_LIST" ||
                      state.regulation === "UK_MILITARY_LIST") &&
                      generateLicenseTypeOptions([
                        "GLOBAL_OGEL",
                        "INDIVIDUAL_UK",
                        "TEMPORARY_UK",
                      ])}

                    {(state.regulation === "ES_DUAL_USE_LIST" ||
                      state.regulation === "ES_MUNITION_LIST" ||
                      state.regulation === "EU_MUNITIONS_LIST") &&
                      state.departureCountryId != "NL" &&
                      generateLicenseTypeOptions([
                        "GLOBAL",
                        "IMPORT",
                        "INDIVIDUAL",
                        "TEMPORARY",
                      ])}

                    {state.departureCountryId === "NL" &&
                      state.regulation === "EU_MUNITIONS_LIST" &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "NL005_AUTOR_GENERAL",
                        "NL006_AUTOR_GENERAL",
                      ])}
                  </select>
                </FormGroup>
              </GridCol>
              <GridCol md={3} xs={12} alignSelf="center">
                {rootStore.userStore.user?.entite === "ALL" && (
                  <Checkbox
                    id="us-origin"
                    large
                    label={i18n.t("pages.license.add.temporary-licence")}
                    disabled={disabledByRDO}
                    checked={state.temporaryLicense}
                    onChange={(e) => {
                      setState({
                        ...state,
                        temporaryLicense: e.target.checked,
                      });
                    }}
                  />
                )}
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                {state.regulation === "FR_LIST_DOUBLE_USAGE" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{ __html: msg.Message }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={msg.Url1}
                      >
                        {msg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={msg.Url2}
                      >
                        {msg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={msg.Url3}
                      >
                        {msg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}
                {state.regulation === "EU_DUAL_USE_LIST" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{ __html: msg.Message }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={msg.Url1}
                      >
                        {msg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={msg.Url2}
                      >
                        {msg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={msg.Url3}
                      >
                        {msg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}
                {state.regulation === "FR_MUNITIONS_LIST" && (
                  <div>
                    <p>
                      <div
                        className="post__content"
                        dangerouslySetInnerHTML={{ __html: msg.Message }}
                      ></div>
                    </p>

                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={msg.Url1}
                      >
                        {msg.Lib_url1}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={msg.Url2}
                      >
                        {msg.Lib_url2}
                      </a>
                      <a
                        target="_blank"
                        style={{ marginLeft: "50px" }}
                        rel="noopener noreferrer"
                        href={msg.Url3}
                      >
                        {msg.Lib_url3}
                      </a>
                      <br />
                    </p>
                  </div>
                )}
              </GridCol>
            </GridRow>
            <GridRow alignItems="end"></GridRow>
            <GridRow>
              {/* ML Customer  Country */}
              {state.regulation == "FR_MUNITIONS_LIST" && (
                <GridCol md={3} xs={12}>
                  <AutoComplete
                    id="client-country"
                    label={i18n.t("pages.license.add.client-country-ml")}
                    errorText={i18n.t("errors")}
                    disabled={disabledByRDO}
                    onChange={(value) =>
                      setState({
                        ...state,
                        customerDestinationCountryId: value,
                      })
                    }
                    options={rootStore.countriesStore.countriesToOptions}
                    value={state.customerDestinationCountryId || ""}
                  />
                </GridCol>
              )}
              {/* ML First Destination Final Country */}
              {state.regulation == "FR_MUNITIONS_LIST" && (
                <GridCol md={3} xs={12}>
                  <AutoComplete
                    id="first-destination-country-ml"
                    label={i18n.t(
                      "pages.license.add.first-destination-country-ml"
                    )}
                    errorText={i18n.t("errors")}
                    disabled={disabledByRDO}
                    onChange={(value) => {
                      setState({
                        ...state,
                        firstDestinationCountryId: value,
                      });
                    }}
                    options={rootStore.countriesStore.countriesToOptions}
                    value={state.firstDestinationCountryId || ""}
                  />
                </GridCol>
              )}

              {/*  Destination Country */}
              {state.licenseType != "EU001_AUTOR_GENERAL" && (
                <GridCol md={3} xs={12}>
                  <AutoComplete
                    id="destination-country"
                    label={
                      state.regulation === "FR_MUNITIONS_LIST"
                        ? i18n.t("pages.license.add.destination-country-ml")
                        : i18n.t("pages.license.add.destination-country")
                    }
                    required={
                      state.regulation == "FR_MUNITIONS_LIST" &&
                      state.licenseType == "GLOBAL"
                        ? false
                        : true
                    }
                    errorText={i18n.t("errors")}
                    disabled={disabledByRDO}
                    onChange={(value) => {
                      setState({
                        ...state,
                        destinationCountryId: value,
                      });
                    }}
                    options={rootStore.countriesStore.countriesToOptions}
                    value={state.destinationCountryId || ""}
                  />
                </GridCol>
              )}

              {/* ML Intermediair Country */}
              {state.regulation == "FR_MUNITIONS_LIST" && (
                <GridCol md={3} xs={12}>
                  <AutoComplete
                    id="intermediair-country"
                    label={i18n.t("pages.license.add.intermediair-country")}
                    errorText={i18n.t("errors")}
                    disabled={disabledByRDO}
                    onChange={(value) => {
                      setState({
                        ...state,
                        intermediairDestinationCountryId: value,
                      });
                    }}
                    options={rootStore.countriesStore.countriesToOptions}
                    value={state.intermediairDestinationCountryId || ""}
                  />
                </GridCol>
              )}
              {state.regulation === "FR_LIST_DOUBLE_USAGE" &&
                state.licenseType != "EU001_AUTOR_GENERAL" && (
                  <GridCol md={3} xs={12}>
                    <TextInput
                      label={i18n.t("pages.license.add.destinataire")}
                      value={state.destinataire || ""}
                      disabled={disabledByRDO}
                      errorText={i18n.t("errors")}
                      onChange={(value) =>
                        setState({
                          ...state,
                          destinataire: value.target.value,
                        })
                      }
                      // required={ state.regulation !== 'FR_IMPORTATION'}
                    />
                  </GridCol>
                )}
              {state.regulation === "FR_MUNITIONS_LIST" && (
                <GridCol md={3} xs={12}>
                  <TextInput
                    label={i18n.t("pages.license.add.client")}
                    value={state.destinataire || ""}
                    disabled={disabledByRDO}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      setState({ ...state, destinataire: value.target.value })
                    }
                    // required={ state.regulation !== 'FR_IMPORTATION'}
                  />
                </GridCol>
              )}
              {state.regulation === "FR_MUNITIONS_LIST" && (
                <GridCol md={3} xs={12}>
                  <TextInput
                    id="first-recipient"
                    label={i18n.t("pages.license.add.first-recipient")}
                    value={state.firstRecipient || ""}
                    disabled={disabledByRDO}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      setState({
                        ...state,
                        firstRecipient: value.target.value,
                      })
                    }
                  />
                </GridCol>
              )}
              {state.licenseType != "EU001_AUTOR_GENERAL" && (
                <GridCol md={3} xs={12}>
                  <TextInput
                    id="end-user"
                    label={i18n.t("pages.license.add.end-user")}
                    value={state.endUser || ""}
                    disabled={disabledByRDO}
                    required={
                      state.regulation == "FR_MUNITIONS_LIST" &&
                      state.licenseType == "GLOBAL"
                        ? false
                        : true
                    }
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      setState({ ...state, endUser: value.target.value })
                    }
                  />
                </GridCol>
              )}
              {state.licenseType != "EU001_AUTOR_GENERAL" ||
                (state.regulation != "FR_MUNITIONS_LIST" && (
                  <GridCol md={3} xs={12}>
                    <TextInput
                      label={i18n.t("pages.license.add.supplier")}
                      value={state.supplier || ""}
                      disabled={disabledByRDO}
                      errorText={i18n.t("errors")}
                      onChange={(value) =>
                        setState({ ...state, supplier: value.target.value })
                      }
                    />
                  </GridCol>
                ))}
              {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                <GridCol md={3} xs={12}>
                  <TextInput
                    id="manufacturer"
                    label={i18n.t("pages.license.add.manufacturer")}
                    value={state.manufacturer || ""}
                    required={false}
                    disabled={disabledByRDO}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      setState({ ...state, manufacturer: value.target.value })
                    }
                  />
                </GridCol>
              )}
              <GridCol md={3} xs={12} alignSelf="center">
                {rootStore.userStore.user?.entite !== "ALL" && (
                  <Checkbox
                    id="us-origin"
                    large
                    label={i18n.t("pages.license.add.temporary-licence")}
                    disabled={disabledByRDO}
                    checked={state.temporaryLicense}
                    onChange={(e) => {
                      setState({
                        ...state,
                        temporaryLicense: e.target.checked,
                      });
                    }}
                  />
                )}
              </GridCol>
            </GridRow>
            <div className={styles.divider}></div>
            <GridRow>
              <GridCol md={3} xs={12}>
                <TextInput
                  id="license-number"
                  label={i18n.t("pages.license.add.license-number")}
                  value={state.licenseNumber || ""}
                  required={true}
                  disabled={disabledByRDO}
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    setState({ ...state, licenseNumber: value.target.value })
                  }
                />
              </GridCol>

              <GridCol md={3} xs={12}>
                <FormLabel className={styles.label} required={true}>
                  {i18n.t("pages.license.add.issuance-date")}
                </FormLabel>
                <input
                  id="issuance-date"
                  type="date"
                  value={state.issuanceDate || ""}
                  disabled={disabledByRDO}
                  required
                  onChange={(e) =>
                    setState({ ...state, issuanceDate: e.target.value })
                  }
                />
              </GridCol>
              {state.licenseType != "EU001_AUTOR_GENERAL" && (
                <GridCol md={3} xs={12}>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.license.add.expiration-date")}
                  </FormLabel>
                  <input
                    className={styles.opacity_1}
                    id="expiration-date"
                    type="date"
                    value={state.expirationDate || ""}
                    disabled={disabledByRDO}
                    required
                    onChange={(e) =>
                      setState({ ...state, expirationDate: e.target.value })
                    }
                  />
                </GridCol>
              )}
              {(state.regulation === "FR_LIST_DOUBLE_USAGE" ||
                state.regulation === "FR_MUNITIONS_LIST") &&
                state.licenseType != "EU001_AUTOR_GENERAL" && (
                  <GridCol md={3} xs={12}>
                    <TextInput
                      id="num_order"
                      label={i18n.t("pages.license.add.num_order")}
                      value={state.num_order || ""}
                      required={false}
                      disabled={disabledByRDO}
                      errorText={i18n.t("errors")}
                      onChange={(value) =>
                        setState({ ...state, num_order: value.target.value })
                      }
                    />
                  </GridCol>
                )}
            </GridRow>
            {/*  cas de ITAR DSP83  */}
            {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
              <GridRow>
                <GridCol md={4} xs={12}>
                  <div className={styles.input}>
                    {/*
                    <Checkbox
                      id="us-origin"
                      large
                      label={" DSP-83 required"}
                      disabled={disabledByRDO}
                      checked={state.cnr}
                      onChange={(e) => {
                        setState({ ...state, cnr e.target.checked });
                      }}
                    />
                    */}
                  </div>
                </GridCol>
                <GridCol>
                  <RadioGroup
                    aria-label="provisos"
                    name="provisos"
                    value={state.provisos || ""}
                    onChange={(e) =>
                      setState({ ...state, provisos: e.target.value })
                    }
                  >
                    <FormControlLabel
                      id="civil-button"
                      value="with provisos"
                      disabled={disabledByRDO}
                      control={<Radio onChange={() => {}} color="primary" />}
                      className={
                        disabledByRDO && state.provisos === "with provisos"
                          ? styles.disabledRadio
                          : styles.radio
                      }
                      label={"with provisos"}
                    />
                    <FormControlLabel
                      id="military-button"
                      value="approved"
                      disabled={disabledByRDO}
                      control={<Radio onChange={() => {}} color="primary" />}
                      className={
                        disabledByRDO && state.provisos === "approved"
                          ? styles.disabledRadio
                          : styles.radio
                      }
                      label={"approved"}
                    />
                  </RadioGroup>
                </GridCol>
              </GridRow>
            )}
            <GridRow>
              {state.regulation === "FR_MUNITIONS_LIST" && (
                <GridCol md={12} xs={12}>
                  {state.messageCnr1 && (
                    <p style={{ fontWeight: "bold" }}>
                      {state.messageCnr1}: {state.messageCnr2}
                    </p>
                  )}
                </GridCol>
              )}
            </GridRow>
            {/*  fichier a upload   */}
            <GridRow>
              {!showFR_DUAL && (
                <GridCol md={3} xs={12}>
                  {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.usupload-license2")}
                    </FormLabel>
                  )}

                  {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.itarupload-license1")}
                    </FormLabel>
                  )}
                  {!showFR_Munition &&
                    state.regulation !== "US_DUAL_USE_LIST_EAR" &&
                    state.regulation !== "US_MUNITIONS_LIST_ITAR" && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.CNR")}
                      </FormLabel>
                    )}

                  {showFR_Munition && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.CNR_FR")}
                    </FormLabel>
                  )}

                  <UploadInput
                    value={uploadState.uploadLicense2 as File}
                    disabled={disabledByRDO}
                    onChange={(file) =>
                      setUploadState({ ...uploadState, uploadLicense2: file })
                    }
                  />
                  {uploadState.uploadLicense2 && (
                    <Button
                      type="button"
                      className={styles.preview}
                      onClick={() =>
                        downloadFile(uploadState.uploadLicense2 as File)
                      }
                    >
                      {i18n.t("pages.license.add.preview-upload1")}
                    </Button>
                  )}
                </GridCol>
              )}
              {state.regulation !== "FR_MUNITIONS_LIST" &&
                state.regulation !== "EU_MUNITIONS_LIST" && (
                  <GridCol md={3} xs={12}>
                    {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.usupload-license1")}
                      </FormLabel>
                    )}

                    {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.itarupload-license2")}
                      </FormLabel>
                    )}

                    {!showFR_DUAL &&
                      state.regulation !== "US_DUAL_USE_LIST_EAR" &&
                      state.regulation !== "US_MUNITIONS_LIST_ITAR" && (
                        <FormLabel className={styles.label} required={false}>
                          {i18n.t("pages.license.add.CUF")}
                        </FormLabel>
                      )}

                    {/** For Fr */}
                    {showFR_DUAL && (
                      <FormLabel className={styles.label} required={false}>
                        {i18n.t("pages.license.add.endUserCertificate")}
                      </FormLabel>
                    )}

                    <UploadInput
                      value={uploadState.uploadLicense1 as File}
                      disabled={disabledByRDO}
                      onChange={(file) =>
                        setUploadState({ ...uploadState, uploadLicense1: file })
                      }
                    />
                    {uploadState.uploadLicense1 && (
                      <Button
                        type="button"
                        className={styles.preview}
                        onClick={() =>
                          downloadFile(uploadState.uploadLicense1 as File)
                        }
                      >
                        {i18n.t("pages.license.add.preview-upload1")}
                      </Button>
                    )}
                  </GridCol>
                )}
              {/**Fichier  Commande ou contrat */}
              {show_FR && (
                <GridCol md={3} xs={12}>
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.orderContract")}
                  </FormLabel>
                  <UploadInput
                    value={uploadState.orderContract as File}
                    disabled={disabledByRDO}
                    onChange={(file) =>
                      setUploadState({ ...uploadState, orderContract: file })
                    }
                  />
                  {uploadState.orderContract && (
                    <Button
                      type="button"
                      className={styles.preview}
                      onClick={() =>
                        downloadFile(uploadState.orderContract as File)
                      }
                    >
                      {i18n.t("pages.license.add.preview-upload1")}
                    </Button>
                  )}
                </GridCol>
              )}
              {/** Fiche Produit */}
              {show_FR && (
                <GridCol md={3} xs={12}>
                  {showFR_DUAL && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.productInformation")}
                    </FormLabel>
                  )}
                  {showFR_Munition && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.analyticInformation")}
                    </FormLabel>
                  )}
                  <UploadInput
                    value={uploadState.productInformation as File}
                    disabled={disabledByRDO}
                    onChange={(file) =>
                      setUploadState({
                        ...uploadState,
                        productInformation: file,
                      })
                    }
                  />
                  {uploadState.productInformation && (
                    <Button
                      type="button"
                      className={styles.preview}
                      onClick={() =>
                        downloadFile(uploadState.productInformation as File)
                      }
                    >
                      {i18n.t("pages.license.add.preview-upload1")}
                    </Button>
                  )}
                </GridCol>
              )}
              <GridCol md={3} xs={12}>
                {state.regulation === "US_DUAL_USE_LIST_EAR" && (
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.usupload-license3")}
                  </FormLabel>
                )}

                {state.regulation === "US_MUNITIONS_LIST_ITAR" && (
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.itarupload-license3")}
                  </FormLabel>
                )}
                {!show_FR &&
                  state.regulation !== "US_DUAL_USE_LIST_EAR" &&
                  state.regulation !== "US_MUNITIONS_LIST_ITAR" && (
                    <FormLabel className={styles.label} required={false}>
                      {i18n.t("pages.license.add.autre")}
                    </FormLabel>
                  )}

                {show_FR && (
                  <FormLabel className={styles.label} required={false}>
                    {i18n.t("pages.license.add.otherDocument")}
                  </FormLabel>
                )}

                <UploadInput
                  value={uploadState.uploadLicense3 as File}
                  disabled={disabledByRDO}
                  onChange={(file) =>
                    setUploadState({ ...uploadState, uploadLicense3: file })
                  }
                />
                {uploadState.uploadLicense3 && (
                  <Button
                    type="button"
                    className={styles.preview}
                    onClick={() =>
                      downloadFile(uploadState.uploadLicense3 as File)
                    }
                  >
                    {i18n.t("pages.license.add.preview-upload1")}
                  </Button>
                )}
              </GridCol>
            </GridRow>
            {/* Currency */}
            <GridRow>
              <GridCol md={3} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label}>
                    {i18n.t("pages.license.add.currency")}
                  </FormLabel>
                  <select
                    id="currency"
                    value={state.currency || ""}
                    disabled={disabledByRDO}
                    onChange={(e) =>
                      setState({ ...state, currency: e.target.value })
                    }
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {generateSelectOption(currency)}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={3} xs={12}>
                <TextInput
                  id="total-amount"
                  label={i18n.t("pages.license.add.total-amount")}
                  value={state.totalAmount || ""}
                  type="number"
                  disabled={disabledByRDO}
                  min={0}
                  step={1}
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    setState({
                      ...state,
                      totalAmount: parseInt(value.target.value),
                    })
                  }
                />
              </GridCol>
            </GridRow>
            {state.equipments.map((_, index) => {
              return (
                <EquipmentComponent
                  removeEquipment={() => removeEquipment(index)}
                  key={index}
                  index={index}
                  disabled={disabledByRDO}
                  historyValues={state.equipments[index]}
                  regulation={state.regulation}
                  onChange={(value) => handleEquipment(index, value)}
                  rootStore={rootStore}
                />
              );
            })}
            <Button
              id="add-equipment"
              className={styles.addButton}
              type="button"
              size="medium"
              disabled={disabledByRDO}
              onClick={() => addEquipment()}
            >
              {i18n.t("pages.license.add.add-equipment")}
            </Button>
            {(state.departureCountryId === "US" ||
              state.regulation?.includes("MUNITIONS_LIST")) &&
              state.consignees.map((consignee, index) => {
                return (
                  <ConsigneeComponent
                    key={index}
                    index={index}
                    disabled={disabledByRDO}
                    historyValues={state.consignees[index]}
                    countries={rootStore.countriesStore.countriesToOptions}
                    consignee={consignee}
                    onChange={(value) => handleConsignee(index, value)}
                  />
                );
              })}
            {(state.departureCountryId === "US" ||
              state.regulation?.includes("MUNITIONS_LIST")) && (
              <Button
                id="add-consignee"
                className={styles.addButton}
                type="button"
                disabled={disabledByRDO}
                size="medium"
                onClick={() => addConsignee()}
              >
                {i18n.t("pages.license.add.add-consignee")}
              </Button>
            )}
            {/*
              <GridRow>
                <GridCol md={12}>
                  <FormLabel>
                    {i18n.t("pages.license.add.end-use.label")}
                  </FormLabel>
                  <RadioGroup
                    aria-label="endUse"
                    name="endUse"
                    value={state.endUse || ""}
                    onChange={e =>
                      setState({ ...state, endUse: e.target.value as EndUseType })
                    }
                  >
                    <FormControlLabel
                      id="civil-button"
                      value="CIVIL"
                      disabled={disabledByRDO}
                      control={<Radio color="primary" />}
                      className={
                        disabledByRDO &&
                        state.endUse === "CIVIL"
                          ? styles.disabledRadio
                          : styles.radio
                      }
                      label={i18n.t("pages.license.add.end-use.civil")}
                    />
                    <FormControlLabel
                      id="military-button"
                      value="MILITARY"
                      disabled={disabledByRDO}
                      control={<Radio color="primary" />}
                      className={
                        disabledByRDO &&
                        state.endUse === "MILITARY"
                          ? styles.disabledRadio
                          : styles.radio
                      }
                      label={i18n.t("pages.license.add.end-use.military")}
                    />
                  </RadioGroup>
                </GridCol>
              </GridRow>

              */}
            <GridRow>
              <GridCol md={12}>
                <TextArea
                  id="end-use-comment"
                  label={i18n.t("pages.license.add.end-use-comment")}
                  placeholder={i18n.t("pages.license.add.end-use-comment")}
                  maxLength={4000}
                  required={state.licenseType !== "EU001_AUTOR_GENERAL"}
                  disabled={disabledByRDO}
                  value={state.endUseComment || ""}
                  onChange={(value) =>
                    setState({ ...state, endUseComment: value.target.value })
                  }
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol md={12}>
                <TextArea
                  id="comment"
                  label={i18n.t("pages.license.add.comment")}
                  placeholder={i18n.t("pages.license.add.comment")}
                  value={state.comment || ""}
                  //required={ false }
                  disabled={disabledByRDO}
                  onChange={(value) =>
                    setState({ ...state, comment: value.target.value })
                  }
                />
              </GridCol>
            </GridRow>
            <GridRow justify="end">
              {/* <Button
                id="submit"
                disabled={submitIsDisabled()}
                primary
                size="medium"
              >
                {i18n.t("pages.license.add.submit")}
              </Button> */}
            </GridRow>
          </GridContainer>
        </form>
      </div>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: state.vertical as any,
          horizontal: state.horizontal as any,
        }}
        open={open}
        onClose={handleClose}
        message=""
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity={statusSnack === 1 ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageNotification}
        </Alert>
      </Snackbar>
      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            disabled: submitIsDisabled(),
            label: i18n.t("pages.license.add.submit"),
            primary: true,
          },
        ]}
      ></Footer>
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
    </div>
  );
};
export default inject("rootStore")(observer(AddLicense));
