import React, { SFC } from "react";
import cx from "classnames";
import classes from "../Dialog/styles.module.scss";

type Props = {};

const DialogContent: SFC<Props> = ({ children }) => (
  <div
    className={cx(
      classes["ds-modal__content"],
      classes["ds-modal__content--fix-height"],
    )}
  >
    {children}
  </div>
);

export default DialogContent;
