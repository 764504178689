/* Styles import */
import styles from "./results.module.css";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
/* Global import */
import React, { Component } from "react";
import html2canvas from "html2canvas";
import { inject, observer } from "mobx-react";
import {
  Loading,
  Title,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import ScreeningResult from "../../../components/screeningResult/screeningResult";
import Footer from "../../../components/footer";
import { flowUrl } from "../../../utils/generator";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}

const Results: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };

  const getScreeningButton = () => {
    const screeningType =
      (rootStore.userStore.user && rootStore.userStore.user.screeningType) ||
      undefined;

    if (screeningType === "USE_BUILT_IN_SCREENING") {
      return {
        id: "screeningLink",
        onClick: () => navigate(flowUrl("/screening/basic/All", id)),
        label: i18n.t("global.screening"),
        primary: true,
      };
    }
    return {
      id: "screeningLink",
      onClick: () => navigate(flowUrl("/screening/entity", id)),
      label: i18n.t("global.screening"),
      primary: true,
    };
  };

  const getTravel = () => {
    let countryOfOrigin = undefined;
    let countryOfDestination = undefined;

    const result = rootStore.exportabilityStore.screeningCountryResult;

    if (result) {
      countryOfOrigin = result.departureCountry;
      countryOfDestination = result.countryOfDestination;
    }

    return (
      <p className={styles.arrow}>
        {countryOfOrigin ? countryOfOrigin.name : ""}
        <ArrowIcon />
        {countryOfDestination ? countryOfDestination.name : ""}
      </p>
    );
  };
  const getDepContry = (result1: ScreeningDetails) => {
    let countryOfOrigin = undefined;
    let countryOfDestination = undefined;

    const result = rootStore.exportabilityStore.screeningCountryResult;

    if (result) {
      countryOfOrigin = result.departureCountry;
      countryOfDestination = result.countryOfDestination;
    }
    // console.log("sanction => "+result1.eccnCode)
    if (result1.eccnCode == "United States of America (USA) Sanctions on ")
      return "United States of America (USA) Sanctions";
    else return countryOfOrigin?.name;
  };

  const renderMessage = (screeningCountryResult: ScreeningCountryResult) => {
    let lib1 = "";
    let lib2 = "";
    const useccn = rootStore.exportabilityStore.usEccn?.eccnCode;
    const nateccn = rootStore.exportabilityStore.nationalEccn?.eccnCode;
    const nateccn_key = rootStore.exportabilityStore.nationalEccn?.keyword;

    lib1 = i18n.t("pages.exportability.national-eccn");
    lib2 = i18n.t("pages.exportability.us-eccn");

    return (
      <div className={styles.messageBody} id="print">
        <div className={styles.row}>
          <img
            className={styles.traficLight}
            src={require(
              `../../../../statics/images/global-traffic-${screeningCountryResult.globalTrafficLightColor.toLowerCase()}.svg`,
            )}
            alt={"traffic"}
          />

          <div>
            {screeningCountryResult.countryOfDestination.name !== "IMPORT" ? (
              <React.Fragment>
                <Title tag="h2">
                  {i18n.t("pages.exportability.results.message.title")}
                </Title>
                <Title tag="h4">{getTravel()}</Title>
              </React.Fragment>
            ) : (
              <Title tag="h2">Import</Title>
            )}
          </div>

          <div style={{ paddingLeft: "480px" }}>
            <b>
              {" "}
              {screeningCountryResult.screeningDetails[0].keywork &&
                screeningCountryResult.screeningDetails[0].keywork !== "void" &&
                i18n.t("global.rapportkey") +
                  ` : ` +
                  screeningCountryResult.screeningDetails[0].keywork}
            </b>
            <br />
            <b>
              {" "}
              {nateccn && nateccn_key !== "void" && lib1 + ` : ` + nateccn}
            </b>
            <br />
            <b>
              {" "}
              {rootStore.exportabilityStore.usOrigin &&
                useccn &&
                lib2 + ` : ` + useccn}
            </b>
            <br />
          </div>
        </div>

        <div className={styles.results}>
          {screeningCountryResult.screeningDetails
            .filter((result) => result.exportabilityRule === "RULE_SANCTIONED")
            .map((result, index) => {
              return (
                <ScreeningResult
                  rootStore={rootStore}
                  key={index}
                  destination={getContry() || ""}
                  pdebut={getDepContry(result) || ""}
                  result={result}
                />
              );
            })}

          {screeningCountryResult.screeningDetails
            .filter((result) => result.exportabilityRule !== "RULE_SANCTIONED")
            .filter((result) => !result.eccnCode.includes("Sanction"))

            .filter((result) => result.exportabilityRule !== "EXCEPTION")

            .filter((result) => !result.usOrigin)
            .map((result, index) => {
              return (
                <ScreeningResult
                  rootStore={rootStore}
                  key={index}
                  destination={getContry() || ""}
                  pdebut={getDepContry(result) || ""}
                  result={result}
                />
              );
            })}

          {screeningCountryResult.screeningDetails
            .filter((result) => result.exportabilityRule !== "RULE_SANCTIONED")
            .filter((result) => !result.eccnCode.includes("Sanction"))
            .filter((result) => result.exportabilityRule !== "EXCEPTION")
            .filter((result) => result.usOrigin)
            .map((result, index) => {
              return (
                <ScreeningResult
                  rootStore={rootStore}
                  key={index}
                  destination={getContry() || ""}
                  pdebut={getDepContry(result) || ""}
                  result={result}
                />
              );
            })}

          {screeningCountryResult.screeningDetails
            .filter((result) => result.exportabilityRule !== "RULE_SANCTIONED")
            .filter((result) => !result.eccnCode.includes("Sanction"))
            .filter((result) => result.exportabilityRule === "EXCEPTION")
            .map((result, index) => {
              return (
                <ScreeningResult
                  rootStore={rootStore}
                  key={index}
                  destination={getContry() || ""}
                  pdebut={getDepContry(result) || ""}
                  result={result}
                />
              );
            })}

          {screeningCountryResult.screeningDetails
            .filter((result) => result.eccnCode.includes("Sanction"))
            .map((result, index) => {
              return (
                <ScreeningResult
                  rootStore={rootStore}
                  key={index}
                  destination={getContry() || ""}
                  pdebut={getDepContry(result) || ""}
                  result={result}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const getContry = () => {
    let countryOfOrigin = undefined;
    let countryOfDestination = undefined;

    const result = rootStore.exportabilityStore.screeningCountryResult;

    if (result) {
      countryOfOrigin = result.departureCountry;
      countryOfDestination = result.countryOfDestination;
    }
    return countryOfOrigin?.name;
  };
  //let  link=undefined
  const { screeningCountryState, screeningCountryResult } =
    rootStore.exportabilityStore;
  const auteur =
    rootStore.userStore.user?.firstname +
    " " +
    rootStore.userStore.user?.lastname;

  const societe = rootStore.userStore.user?.tenantId;

  const result = rootStore.exportabilityStore.screeningCountryResult;
  async function handleDownloadImage() {
    const element = document.getElementById("print")!;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    const maDate = new Date();
    var afDate = moment(maDate).format("YYYY-MM-DD");

    var doc = new jsPDF("p", "mm");
    let country = window.navigator.language;
    let datrap = moment().locale("en").format("ll");

    if (country === "fr-FR") datrap = moment().locale("fr").format("ll");

    doc.setFontSize(14);
    let width = doc.internal.pageSize.getWidth();
    doc.text(
      i18n.t("global.titrerapport"),
      doc.internal.pageSize.getWidth() / 2 - 20,
      10,
    );

    doc.setFontSize(10);
    doc.text(i18n.t("global.rapport") + " " + datrap, 10, 20);
    doc.text(i18n.t("global.autheur") + " : " + auteur, 10, 25);
    doc.text(i18n.t("global.societe") + " : " + societe, 10, 30);
    let dcountry = "";
    let acountry = "";
    if (result) {
      dcountry = result.departureCountry.name;
      acountry = result.countryOfDestination.name;
    }

    let height = doc.internal.pageSize.getHeight();

    let widthRatio = width / canvas.width;
    let heightRatio = height / canvas.height;

    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    doc.addImage(
      data,
      "JPEG",
      0,
      40,
      canvas.width * ratio,
      canvas.height * ratio,
    );

    doc.text(
      i18n.t("global.footer"),
      doc.internal.pageSize.getWidth() / 2 - 30,
      270,
    );
    doc.save(afDate + " " + dcountry + " to " + acountry + ".pdf");

    const link = document.createElement("a");
    // link.href = data;
    // link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  console.log(screeningCountryState);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="EXPORTABILITY" />

      {screeningCountryState === "PENDING" && <Loading />}

      {screeningCountryState === "DONE" &&
        screeningCountryResult &&
        renderMessage(screeningCountryResult)}

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "PDF",
            onClick: () => handleDownloadImage(),
            label: i18n.t("global.pdf"),
          },
          getScreeningButton(),
        ]}
      />
    </div>
  );
};

export default inject("rootStore")(observer(Results));
