/* Styles import */
import styles from "./newFlow.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  Checkbox,
  TextInput,
  Button,
  FormGroup,
  GridContainer,
  //DatePicker,
  GridRow,
  GridCol,
} from "../../components/horizon-components-react/src/components";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import axios from "axios";
import { FormLabel, IconButton, Tooltip } from "@material-ui/core";
import UploadInput from "../../components/uploadInput";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import DeleteIcon from "@material-ui/icons/DeleteSweep";

// eslint-disable-next-line
import { useParams, useNavigate } from "react-router-dom";
import {
  generateSelectOption,
  generateLicenseTypeOptions,
  flowUrl,
} from "../../utils/generator";
/* Custom import */
import AppBar from "../../components/appBar";
import Footer from "../../components/footer";
import AutoComplete from "../../components/autoComplete";
import Loader from "../../components/loader";
import { generateFlowId } from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow/headerFlow";
import {
  flowType,
  productType,
  OrderLine,
  CCountry,
  intangibleType,
  currency,
} from "../../utils/constant";
import Api from "../../utils/api";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SearchIcon from "@material-ui/icons/Search";
import fileDownload from "js-file-download";
import { sortAlphabeticallyByKeyString } from "../../utils/helpers";
import SearchEccn from "../../components/searchEccn";
import MatchingLicense from "../license/matching/matching";
interface Props extends InjectedProps {}

interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}

interface State {
  fid?: any;
  classificationIsOpen: boolean;
  ouClick: string;
  countries: CCountry[];
  sapId?: string;
  flowType?: FlowType;
  order_line?: OrderLineType;
  customerCompagny?: string;
  departureCountry?: number;
  flowConsignees: Consignee[];
  endUserCountry?: number;
  transitCountry?: number;
  uploadLicense?: File;
  transitCountryId?: number;
  upload_cnr?: File;
  productType?: ProductType;
  intangibleType?: IntangibleType;
  modelOptions: string[];
  model?: string;
  quantity?: number;
  amount?: number;
  supplier?: string;
  part_number?: string;
  designation?: string;
  nationalEccn?: string;
  us_eccn?: string;
  equipments: EquipmentFlow[];
  types: string[];
  delivery_date: string;
  commande_date: string;
  order_num: string;
  ordre_type: string;
  num_licence_export: string;
  num_licence_import: string;
  num_licence_us: string;
  nationalEccnError?: boolean;
  us_eccnError?: boolean;
  link_licence_national: MatchingLinkedDto[];
  link_licence_us: MatchingLinkedDto[];
  status?: FlowStatus;
  disabledInputs: boolean;
}
type EquipmentKey =
  | "equipment_type"
  | "model"
  | "quantity"
  | "part_number"
  | "designation"
  | "nationalEccn"
  | "delivery_date"
  | "currency"
  | "us_eccn"
  | "amount"
  | "cuf_cnr"
  | "validation_date"
  | "order_line"
  | "unit_price"
  | "upload_cnr"
  | "supplier";

// interface EquipmentFlow {
//   id: number;
//   equipment_type: string;
//   model: string;
//   quantity: number;
//   part_number: string;
//   designation: string | null;
//   us_eccn: string | null;
//   flow_id: number;
//   national_regulation: string | null;
//   us_regulation: string | null;
//   supplier: string | null;
//   amount: number;
//   order_line: string;
//   currency: string;
//   cuf_cnr: number;
//   upload_cnr: any | null;
//   delivery_date: string;
//   unit_price: number;
//   validation_date: string;
//   order_line_num: string | null;
//   license: any | null;
//   nationalEccn: string;
// }
// type numflow = { custorder_numomer: string; delivery_date: string };

const NewFlow: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const api = new Api();
  const [loading, setLoading] = useState(false);
  const [modalOpenMatching, setIsOpenMatching] = useState(false);
  const [urlMatching, setUrlMatching] = useState("");

  const [countries, setCountries] = useState<Country[]>([
    {
      label: "",
      value: "",
      name: "",
      id: 0,
    },
  ]);

  // const [link_licence, setLinkLicence] = useState({
  //   link_licence_national: [] as any,
  //   link_licence_us: [] as any,
  // });
  // setRegulation((linked?.linked[0].license.regulation as string) || "");

  const [regulation, setRegulation] = useState("");

  const [state, setState] = useState({
    vertical: "top",
    horizontal: "center",
    disabledInputs: false,
    classificationIsOpen: false,
    originalUrl: location.pathname,
    ouClick: "",
    flowConsignees: [
      {
        consigneeName: "",
        consigneeCountryId: 0,
      },
    ],
    order_num: "",
    productType: "EQUIPMENT",
    intangibleType: "PHONE_CALL",
    delivery_date: "",
    commande_date: "",
    // flowType: "NEW_SALE",
    num_licence_export: "",
    num_licence_import: "",
    num_licence_us: "",
    ordre_type: "Standard",
    nationalEccnError: false,
    us_eccnError: false,
    fid: undefined,
    sapId: "",
    order_line: "NEW_LINE",
    // customerCompagny: "",
    // departureCountry: 0,
    // endUserCountry: 0,
    transitCountryId: 0,
    uploadLicense: {} as any,
    transitCountry: 0,
    upload_cnr: {} as any,
    model: "",
    quantity: 0,
    amount: 0,
    supplier: "",
    part_number: "",
    designation: "",
    nationalEccn: "",
    us_eccn: "",
    // status: "",
  });
  const { vertical, horizontal } = state;
  const [open, setSnackOpen] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [statusSnack, setStatusSnack] = useState(0);
  const [currentFlow, setCurrentFlow] = useState<Flow>({} as Flow);
  const [currentEquipments, setCurrentEquipments] = useState<EquipmentFlow[]>(
    [] as EquipmentFlow[]
  );
  const [customerCompagny, setCustomer] = useState("");
  const [departureCountry, setDpCountry] = useState(0);
  const [currFlowType, setCurrFlowType] = useState("NEW_SALE");
  const [endUserCountry, setEndUserCountry] = useState(0);
  const [status, setStatus] = useState("");
  const [linkNat, setLinkNat] = useState([]);
  const [linkUs, setLinkUs] = useState([]);
  const handleClose = () => {
    setSnackOpen(false);
  };
  const handleChangeSearchType = (event: any) => {
    if (event.target.value !== null) {
      setState({ ...state, ordre_type: event.target.value });
    }
  };
  useEffect(() => {
    // rootStore.countriesStore.getCountriesDeparture();

    const fetchData = async () => {
      try {
        if (countries.length === 0) {
          const countriesResponse = await axios.get(
            `${process.env.REACT_APP_SCREEN}/countries_departure`
          );
          await rootStore.countriesStore.getCountries();
          setCountries(countriesResponse.data);
        }
        setCountries(rootStore.countriesStore.countriesDeparture);
        const flowId = id;
        if (flowId) {
          await getFlow(parseInt(flowId));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, [id]);
  const reg = rootStore?.licenseStore?.matching?.linked[0]?.license.regulation;

  const downloadFile = (file: File) => {
    setLoading(true);
    fileDownload(file, file.name);
    setLoading(false);
  };

  const pdfSubmit = async (file: File) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        if (file) {
          data.append("file", file);

          data.append("id_user", String(rootStore.userStore.user?.id!));

          data.append("id_tenant", rootStore.userStore.user?.tenantId!);
        }

        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_SCREEN}/importfileflow`,
          headers: {
            "content-type": "multipart/form-data",
          },

          data,
        });

        if (response.data[0] == 1) {
          // Afficher la snackbar en cas de succès
          setMessageNotification(i18n.t("notifications.form.success"));
          setStatusSnack(0);
          setSnackOpen(true);

          setTimeout(() => {
            navigate({ pathname: `/flow/${response.data[1]}/classification` });
          }, 2000);
        } else {
          setMessageNotification(i18n.t("notifications.form.success"));
          setStatusSnack(0);
          setSnackOpen(true);

          // Attendre 2000 ms avant de rediriger vers /search-flow
          setTimeout(() => {
            navigate({ pathname: `/search-flow` });
          }, 2000);
        }

        resolve(response.data);
      } catch (error) {
        setMessageNotification(
          i18n.t("notifications.form.failure") + " : " + error
        );
        setStatusSnack(1);
        setSnackOpen(true);

        reject(error);
      }
    });
  };
  const getFlow = async (fid: any) => {
    //setLoading(true)
    const flow = await api.newGetFlowById(fid);
    setCurrentFlow(flow);
    setCustomer(flow.customer);
    setDpCountry(flow.departure_country_id);
    setEndUserCountry(flow.destination_country_id);
    setStatus(flow.status);
    setCurrFlowType(flow.flow_type);
    // dégueulasse, mais bon pourquoi en DB c'est 0 1 2 3 4 si on utilise des String ?
    const updatedEquipments = flow.flowEquipments.map((equipment) => {
      let orderLineValue: OrderLineType;
      switch (parseInt(equipment.order_line)) {
        case 0:
          orderLineValue = "NEW_LINE";
          break;
        case 1:
          orderLineValue = "CONFIRM_LINE";
          break;
        case 2:
          orderLineValue = "PARTIAL_LINE";
          break;
        case 3:
          orderLineValue = "COMPLETE_LINE";
          break;
        default:
          orderLineValue = "NEW_LINE";
      }
      return {
        ...equipment,
        order_line: orderLineValue,
      };
    });

    setCurrentEquipments(updatedEquipments);
    let newLinkNat = [] as any;
    let newLinkUs = [] as any;
    flow.matchedLicenses.forEach((license) => {
      if (license.type === "NATIONAL") {
        newLinkNat.push(license.license_number);
      } else {
        newLinkUs.push(license.license_number);
      }
    });
    setLinkNat(newLinkNat);
    setLinkUs(newLinkUs);
    setState({
      ...state,
      fid,
      order_num: flow.order_num,
      intangibleType: flow.intangibleType ? (flow.intangibleType as any) : 0,
    });
  };

  const addConsignee = () => {
    const { flowConsignees } = state;

    flowConsignees.push({
      consigneeName: "",
      consigneeCountryId: 0,
    });

    setState({ ...state, flowConsignees });
  };

  const updateConsignee = (
    index: number,
    input: "country" | "name",
    value: number | string
  ) => {
    const { flowConsignees } = state;

    if (input === "country") {
      flowConsignees[index].consigneeCountryId = value as number;
    } else {
      flowConsignees[index].consigneeName = value as string;
    }

    setState({ ...state, flowConsignees });
  };

  const addEquipment = () => {
    setCurrentEquipments(
      (prevEquipments) =>
        [
          ...prevEquipments,
          {
            id: undefined,
            amount: 0,
            upload_cnr: null,
            supplier: "",
            order_line: "NEW_LINE",
            equipmentType: "",
            model: "",
            modelOptions: [],
            quantity: 0,
            partNumber: "",
            designation: "",
            nationalEccn: "",
            us_eccn: "",
            cufCnr: 0,
            validation_date: "",
            currency: "EUR",
            unit_price: 0,
            delivery_date: "",
            order_lineNum: null,
            license: null,
          },
        ] as EquipmentFlow[]
    );
  };

  const removeEquipment = (index: number) => {
    // const equipments = currentEquipments;
    // equipments.splice(index, 1);
    // setCurrentEquipments(equipments);

    const newEquipments = [...currentEquipments];
    newEquipments.splice(index, 1);
    setCurrentEquipments(newEquipments);
  };

  const updateEquipment1 = async (
    key: EquipmentKey,
    index: number,
    value: any
  ) => {
    // const equipments = currentEquipments;
    if (key == "us_eccn") {
      const validUs_eccn = validateUs_eccn(value as string);
      if (validUs_eccn) {
        setState({ ...state, us_eccnError: false });
      } else {
        setState({ ...state, us_eccnError: true });
      }
    }

    if (key == "nationalEccn") {
      const validNationalEccn = validateNationalEccn(value as string);
      if (validNationalEccn) {
        setState({ ...state, nationalEccnError: false });
      } else {
        setState({ ...state, nationalEccnError: true });
      }
    }

    const updatedEquipments = [...currentEquipments];

    // Mise à jour de la propriété spécifique dans la copie
    updatedEquipments[index] = {
      ...updatedEquipments[index],
      [key]: value,
    };

    // Mettez à jour l'état avec la nouvelle copie
    setCurrentEquipments(updatedEquipments);

    // equipments[index][key] = value as never;
    // console.log(equipments[index]);
    // setCurrentEquipments(equipments);
  };

  const updateEquipment = async (
    key: EquipmentKey,
    index: number,
    value: string
  ) => {
    const equipments = currentEquipments;

    if (key === "equipment_type") {
      // const modelOptions = await rootStore.classificationStore.getOptionModels(
      //   value,
      //   state.productType as ProductType
      // );

      equipments[index].quantity = 0;
      equipments[index].part_number = "";
      equipments[index].designation = "";
      equipments[index].nationalEccn = "";
      equipments[index].us_eccn = "";
      equipments[index].amount = 0;
      equipments[index].order_line = "";
      equipments[index].unit_price = 0;
      equipments[index].delivery_date = "";
      equipments[index].validation_date = "";
      equipments[index].cuf_cnr = false;
      equipments[index].currency = "";
    }

    if (key === "model" && departureCountry) {
      const eccns = await rootStore.classificationStore.getEccnsByModel(
        departureCountry,
        value
      );

      if (eccns) {
        equipments[index].nationalEccn = eccns.nationalClassification;
        equipments[index].us_eccn = eccns.usClassification;
        if (equipments[index].quantity === 0) {
          equipments[index].quantity = 1;
        }
        equipments[index].part_number = "";
        equipments[index].designation = "";
        equipments[index].amount = 0;
      }
    }

    equipments[index][key] = value as never;
    setCurrentEquipments(equipments);
  };

  const reinitEquipment = () => {
    // setState({
    //   ...state,
    //   model: "",
    //   quantity: 0,
    //   part_number: "",
    //   designation: "",
    //   nationalEccn: "",
    //   us_eccn: "",
    //   equipments: [
    //     {
    //       quantity: 0,
    //       part_number: "",
    //       designation: "",
    //       nationalEccn: "",
    //       us_eccn: "",
    //       modelOptions: [],
    //     },
    //   ] as any,
    // });
  };

  const handleProductType = async (productType: ProductType) => {
    // reinitEquipment();
    // setState({ ...state, productType });
    // const types = await rootStore.classificationStore.getTypes(productType);
    // if (productType !== "EQUIPMENT") {
    //   const modelOptions =
    //     await rootStore.classificationStore.getModels(productType);
    //   setState({
    //     ...state,
    //     modelOptions: (modelOptions as any) || ([] as any),
    //   });
    // }
    // setState({ ...state, types: (types as any) || ([] as any) });
  };

  const handleDepartureCountry = async (departureCountry: string) => {
    const intDepartureCountry = parseInt(departureCountry);

    // setState({ ...state, departureCountry: intDepartureCountry });
    setDpCountry(intDepartureCountry);
  };

  const handleModel = async (model: string) => {
    let newQuantity = 1;

    if (state.quantity) {
      if (state.quantity > 1) {
        newQuantity = state.quantity;
      }
    }

    setState({
      ...state,
      model,
      quantity: newQuantity,
      part_number: "",
      designation: "",
    });

    if (departureCountry) {
      const modelEccn = await rootStore.classificationStore.getEccnsByModel(
        departureCountry,
        model
      );

      if (modelEccn) {
        setState({
          ...state,
          nationalEccn: modelEccn.nationalClassification,
          us_eccn: modelEccn.usClassification,
        });
      }
    }
  };
  const fromRoman = (str: string) => {
    let result = 0;

    // the result is now a number, not a string
    const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    const roman = [
      "M",
      "CM",
      "D",
      "CD",
      "C",
      "XC",
      "L",
      "XL",
      "X",
      "IX",
      "V",
      "IV",
      "I",
    ];

    for (let i = 0; i <= decimal.length; i++) {
      // Check for the first characters in the string
      while (str.indexOf(roman[i]) === 0) {
        // Add the decimal value to our result counter
        result += decimal[i];

        // Remove the matched Roman letter from the beginning
        str = str.replace(roman[i], "");
      }
    }
    return result;
  };
  const validateUs_eccn = (value: string) => {
    if (value === "") {
      return true;
    }
    const us_eccnRegex = "^[0-9][A-E][0123569][0-9]{2}|^EAR99$";
    const usItarRegex =
      "^(Category )?((?=[DCLXVI])M*(C[D]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*))";
    let itarBetweenOneAndTwentyOne = false;
    const matchingItar = value.match(usItarRegex);

    if (matchingItar) {
      const matchingItarInt = fromRoman(matchingItar[2]);

      if (matchingItarInt >= 1 && matchingItarInt <= 21) {
        itarBetweenOneAndTwentyOne = true;
      }
    }

    return value.match(us_eccnRegex) || itarBetweenOneAndTwentyOne;
  };

  const validateNationalEccn = (value: string) => {
    if (value === "") {
      return true;
    }
    let nationalEccnRegex =
      "^[0-9][A-E][01234][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";
    if (departureCountry == 67)
      nationalEccnRegex =
        "^[0-9][A-E][012349][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";

    if (departureCountry == 191)
      nationalEccnRegex =
        "^[0-9][A-E][01234][0-9]{2}|5400|^NC$||^NL$|^NOT LISTED$|^PL[0-9]{4}";
    const europeanMlRegex = "^ML[0-9]{1,2}|^AMA";
    const germanMlRegex = "^00[0-2][0-9][a-z]?|^AWG$";
    const frenchHel = "^HEL|^hel";
    const hsRegex = "/^[0-9]+([s.][0-9]+)*$/";

    return (
      value.match(nationalEccnRegex) ||
      value.match(europeanMlRegex) ||
      value.match(germanMlRegex) ||
      value.match(frenchHel) ||
      value.match(hsRegex)
    );
  };
  const activeSaveButton = () => {
    // Vérifiez que tous les équipements ont une quantité, un modèle et un nationalEccn remplis
    const allEquipmentsValid = currentEquipments?.every(
      (equipment) =>
        Boolean(equipment.quantity) &&
        Boolean(equipment.amount) &&
        Boolean(equipment.nationalEccn)
    );
    if (state.ordre_type === "Standard") {
      return (
        allEquipmentsValid &&
        currFlowType &&
        customerCompagny &&
        departureCountry &&
        endUserCountry
      );
    } else {
      return (
        allEquipmentsValid &&
        currFlowType &&
        customerCompagny &&
        departureCountry &&
        endUserCountry &&
        (status === "CLASSIFICATION" || !status)
      );
    }
  };

  const activeSearchButton = () => {
    return (
      Boolean(customerCompagny) &&
      Boolean(departureCountry) &&
      Boolean(endUserCountry)
    );
  };

  const activeDepreciateButton = () => {
    const linked = rootStore.licenseStore.matching;
    if (linked && linked.linked.length > 0) {
      return true;
    }
  };
  const activeSubmitButtonShipping = () => {
    if (state.ordre_type === "Standard")
      return (
        flowType &&
        customerCompagny &&
        departureCountry &&
        endUserCountry &&
        (currentEquipments[0]?.us_eccn || currentEquipments[0]?.nationalEccn)
      );
    else
      return (
        flowType &&
        customerCompagny &&
        departureCountry &&
        (currentEquipments[0]?.us_eccn || currentEquipments[0]?.nationalEccn) &&
        endUserCountry &&
        (status === "CLASSIFICATION" || !status)
      );
  };

  const activeSubmitButton = () => {
    return (
      flowType &&
      customerCompagny &&
      departureCountry &&
      endUserCountry &&
      productType &&
      status !== "ARCHIVED"
    );
  };

  const cleanConsignees = (consignees: Consignee[]) => {
    return consignees.filter(
      (consignee) =>
        consignee.consigneeName !== "" && consignee.consigneeCountryId !== 0
    );
  };

  const cleanEquipments = (equipments: EquipmentFlow[]) => {
    return equipments
      .map((equipment) => {
        const minEquip =
          equipment.quantity === undefined ||
          (equipment.quantity === 0 &&
            Object.values(equipment).some((value) => value !== undefined))
            ? 1
            : equipment.quantity;
        const cleanedEquipment: any = {
          id: equipment.id,
          designation: equipment.designation || "",
          equipmentType: equipment.equipment_type,
          model: equipment.model,
          nationalEccn: equipment.nationalEccn,
          partNumber: equipment.part_number,
          cufCnr: equipment.cuf_cnr,
          currency: equipment.currency,
          amount:
            equipment.amount !== undefined
              ? parseInt(equipment.amount.toString(), 10)
              : 0,
          quantity: minEquip,
          orderLine:
            equipment.order_line !== "" ? equipment.order_line : "NEW_LINE",
          unitPrice: equipment.unit_price,
          usEccn: equipment.us_eccn,
        };
        if (equipment.validation_date) {
          cleanedEquipment.validationDate = equipment.validation_date;
        }
        if (equipment.delivery_date) {
          cleanedEquipment.deliveryDate = equipment.delivery_date;
        }
        return cleanedEquipment;
      })
      .filter((equipment) => equipment.quantity !== 0);
  };

  const handleSave = async () => {
    const {
      fid,
      sapId,
      // flowType,
      intangibleType,
      order_line,
      // customerCompagny,
      // departureCountry,
      flowConsignees,
      // endUserCountry,
      transitCountryId,
      productType,
      model,
      quantity,
      part_number,
      designation,
      nationalEccn,
      us_eccn,
      order_num,
      delivery_date,
      commande_date,
      num_licence_export,
      num_licence_import,
      num_licence_us,
    } = state;

    if (flowType && customerCompagny && departureCountry && endUserCountry) {
      // Clean equipments and log upload_cnr value
      const flowId = await rootStore.flowStore.saveFlow({
        id: fid,
        sapId: currentFlow.sapId,
        flowType: currFlowType as FlowType,
        intangibleType: intangibleType as IntangibleType,
        orderLine: order_line as OrderLineType,
        customerCompagny,
        departureCountry,
        endUserCountry,
        transitCountryId: currentFlow.transitCountryId,
        productType: productType as ProductType,
        equipments: cleanEquipments(currentEquipments) as any,
        flowConsignees: cleanConsignees(flowConsignees),
        model: "",
        quantity: 0,
        partNumber: "",
        designation: "",
        nationalEccn: "",
        usEccn: "",
      });

      // enregistrer les infos manquantes delivery_date,order_num
      let has_ml_eccn = 0;
      let has_du_eccn = 0;
      let has_itar_eccn = 0;
      let has_ear_eccn = 0;
      let has_nc_eccn = 0;
      let has_ear99_eccn = 0;
      let has_hs_code = 0;

      const us_eccnRegex = "^[0-9][A-E][0123569][0-9]{2}|^EAR99$";
      const usItarRegex =
        "^(Category )?((?=[DCLXVI])M*(C[D]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*))";
      let nationalEccnRegex =
        "^[0-9][A-E][01234][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";
      if (departureCountry == 67)
        nationalEccnRegex =
          "^[0-9][A-E][012349][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";

      if (departureCountry == 191)
        nationalEccnRegex =
          "^[0-9][A-E][01234][0-9]{2}|5400|^NC$||^NL$|^NOT LISTED$|^PL[0-9]{4}";
      const europeanMlRegex = "^ML[0-9]{1,2}|^AMA";
      const germanMlRegex = "^00[0-2][0-9][a-z]?|^AWG$";
      const frenchHel = "^HEL|^hel";
      const hsRegex = "/^[0-9]+([s.][0-9]+)*$/";

      currentEquipments.map((equipment, index) => {
        if (equipment.us_eccn !== "" && equipment.us_eccn) {
          if (equipment.us_eccn?.match(usItarRegex)) has_itar_eccn = 1;
          if (
            equipment.us_eccn?.match(us_eccnRegex) &&
            equipment.us_eccn !== "EAR99"
          )
            has_ear_eccn = 1;
        }
        if (equipment.us_eccn === "EAR99") has_ear99_eccn = 1;
        if (equipment.nationalEccn === "NC") has_nc_eccn = 1;
        if (equipment.nationalEccn?.toUpperCase() === "NOT LISTED")
          has_nc_eccn = 1;
        if (
          equipment.nationalEccn?.match(nationalEccnRegex) &&
          equipment.nationalEccn !== "NC" &&
          equipment.nationalEccn.toUpperCase() !== "NOT LISTED"
        )
          has_du_eccn = 1;

        if (equipment.nationalEccn?.match(europeanMlRegex)) has_ml_eccn = 1;
        if (equipment.nationalEccn?.match(germanMlRegex)) has_ml_eccn = 1;
        if (equipment.nationalEccn?.match(hsRegex)) has_hs_code = 1;
      });

      if (flowId) {
        try {
          const response = await api.updateflow(
            flowId,
            order_num,
            delivery_date,
            commande_date,
            num_licence_export,
            num_licence_import,
            num_licence_us,
            has_ml_eccn,
            has_du_eccn,
            has_itar_eccn,
            has_ear_eccn,
            has_nc_eccn,
            has_ear99_eccn,
            has_hs_code
          );
          setMessageNotification(i18n.t("notifications.form.success"));
          setStatusSnack(0);
          setSnackOpen(true);
        } catch (error) {
          // Gérer les erreurs éventuelles
          setMessageNotification(
            i18n.t("notifications.form.failure") + " : " + error
          );
          setStatusSnack(1);
          setSnackOpen(true);
        }
      }
      setLoading(false);

      if (flowId) {
        navigate({ pathname: `/flow/${flowId}/classification` });
      }
      // window.location.reload();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const {
      fid,
      sapId,
      // flowType,
      intangibleType,
      order_line,
      // customerCompagny,
      // departureCountry,
      flowConsignees,
      // endUserCountry,
      productType,
      model,
      quantity,
      supplier,
      part_number,
      designation,
      nationalEccn,
      us_eccn,
      amount,
      order_num,
      delivery_date,
      commande_date,
      num_licence_export,
      num_licence_import,
      num_licence_us,
    } = state;

    if (
      flowType &&
      customerCompagny &&
      departureCountry &&
      endUserCountry &&
      productType
    ) {
      const flowId = await rootStore.flowStore.submitFlow({
        id: fid,
        sapId,
        flowType: currFlowType as FlowType,
        intangibleType: intangibleType as IntangibleType,
        customerCompagny,
        departureCountry,
        endUserCountry,
        productType: productType as ProductType,
        equipments: cleanEquipments(currentEquipments) as any,
        flowConsignees: cleanConsignees(flowConsignees),
        model,
        quantity,
        amount,
        supplier,
        partNumber: part_number,
        designation,
        nationalEccn,
        usEccn: us_eccn,
      });

      // enregistrer les infos manquantes delivery_date,order_num
      let has_ml_eccn = 0;
      let has_du_eccn = 0;
      let has_itar_eccn = 0;
      let has_ear_eccn = 0;
      let has_nc_eccn = 0;
      let has_ear99_eccn = 0;
      let has_hs_code = 0;
      const us_eccnRegex = "^[0-9][A-E][0123569][0-9]{2}|^EAR99$";
      const usItarRegex =
        "^(Category )?((?=[DCLXVI])M*(C[D]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*))";
      let nationalEccnRegex =
        "^[0-9][A-E][01234][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";
      if (departureCountry == 67)
        nationalEccnRegex =
          "^[0-9][A-E][012349][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";

      if (departureCountry == 191)
        nationalEccnRegex =
          "^[0-9][A-E][01234][0-9]{2}|5400|^NC$||^NL$|^NOT LISTED$|^PL[0-9]{4}";
      const europeanMlRegex = "^ML[0-9]{1,2}|^AMA";
      const germanMlRegex = "^00[0-2][0-9][a-z]?|^AWG$";
      const frenchHel = "^HEL|^hel";
      const hsRegex = "/^[0-9]+([s.][0-9]+)*$/";

      currentEquipments.map((equipment, index) => {
        if (equipment.us_eccn?.match(usItarRegex)) has_itar_eccn = 1;
        if (
          equipment.us_eccn?.match(us_eccnRegex) &&
          equipment.us_eccn !== "EAR99"
        )
          has_ear_eccn = 1;

        if (equipment.us_eccn === "EAR99") has_ear99_eccn = 1;
        if (equipment.nationalEccn === "NC") has_nc_eccn = 1;
        if (equipment.nationalEccn?.toUpperCase() === "NOT LISTED")
          has_nc_eccn = 1;
        if (
          equipment.nationalEccn?.match(nationalEccnRegex) &&
          equipment.nationalEccn !== "NC" &&
          equipment.nationalEccn.toUpperCase() !== "NOT LISTED"
        )
          has_du_eccn = 1;

        if (equipment.nationalEccn?.match(europeanMlRegex)) has_ml_eccn = 1;
        if (equipment.nationalEccn?.match(germanMlRegex)) has_ml_eccn = 1;
        if (equipment.nationalEccn?.match(hsRegex)) has_hs_code = 1;
      });

      if (flowId) {
        await api.updateflow(
          flowId,
          order_num,
          delivery_date,
          commande_date,
          num_licence_export,
          num_licence_import,
          num_licence_us,
          has_ml_eccn,
          has_du_eccn,
          has_itar_eccn,
          has_ear_eccn,
          has_nc_eccn,
          has_ear99_eccn,
          has_hs_code
        );
      }

      setLoading(false);

      if (flowId) {
        if (
          rootStore.userStore.user?.role == "L_A" ||
          rootStore.userStore.user?.role == "L_R_A"
        ) {
          navigate(flowUrl("/shipping", id));
        } else {
          if (productType === "EQUIPMENT")
            navigate({
              pathname: `/flow/${flowId}/exportability/results_new`,
            });
          else
            navigate({
              pathname: `/flow/${flowId}/exportability/results`,
            });
        }
      }
    }
  };

  const handleSearchEccn = (eccn?: ClassificationEccn) => {
    setState({ ...state, classificationIsOpen: false, ouClick: "" });

    if (eccn) {
      if (eccn.usOrigin) {
        rootStore.exportabilityStore.setUsEccn(eccn);
      } else {
        rootStore.exportabilityStore.setNationalEccn(eccn);
      }
    }
  };

  const getCountriesListed = () => {
    const countriesListed = [
      { value: "63", label: i18n.t("global.countries.FR") },
      { value: "18", label: i18n.t("global.countries.BE") },
      { value: "67", label: i18n.t("global.countries.DE") },
      { value: "129", label: i18n.t("global.countries.EU3") },
      { value: "134", label: i18n.t("global.countries.EU1") },
      { value: "173", label: i18n.t("global.countries.EU2") },
      { value: "191", label: i18n.t("global.countries.UK") },
      { value: "202", label: i18n.t("global.countries.US") },
      { value: "33", label: i18n.t("global.countries.CA") },
      { value: "86", label: i18n.t("global.countries.IT") },
      { value: "168", label: i18n.t("global.countries.SP") },
      { value: "176", label: i18n.t("global.countries.TW") },
      { value: "160", label: i18n.t("global.countries.SG") },
      { value: "94", label: i18n.t("global.countries.KS") },
      { value: "38", label: i18n.t("global.countries.CN") },
    ];
    countriesListed.sort((a, b) => a.label.localeCompare(b.label));
    return countriesListed;
  };

  const renderLicenceInputs = () => {
    const inputs = linkNat.map((licenseNumber, index) => (
      <TextInput
        onChange={() => {}}
        key={index}
        disabled={true}
        id={`delivery_date_${index}`}
        label={i18n.t("pages.new-flow.general.num_licence_export")}
        value={licenseNumber}
        errorText={i18n.t("errors")}
      />
    ));

    if (linkNat.length === 0) {
      inputs.push(
        <TextInput
          key={"new"}
          disabled={false}
          id={`delivery_date_new`}
          label={i18n.t("pages.new-flow.general.num_licence_export")}
          value={
            currentFlow.num_licence_export !== "null"
              ? currentFlow.num_licence_export || ""
              : ""
          }
          errorText={i18n.t("errors")}
          onChange={(value) =>
            setCurrentFlow({
              ...currentFlow,
              num_licence_export: value.target.value,
            })
          }
        />
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>{inputs}</div>
    );
  };

  const renderLicenceUSInputs = () => {
    const inputs = linkUs.map((licenseNumber, index) => (
      <TextInput
        onChange={() => {}}
        key={index}
        disabled={true}
        id={`delivery_date_us_${index}`}
        label={i18n.t("pages.new-flow.general.num_licence_us")}
        value={licenseNumber}
        errorText={i18n.t("errors")}
      />
    ));

    if (linkUs.length === 0) {
      inputs.push(
        <TextInput
          key={"new_us"}
          disabled={false}
          id={`delivery_date_us_new`}
          label={i18n.t("pages.new-flow.general.num_licence_us")}
          value={
            currentFlow.num_licence_us !== "null"
              ? currentFlow.num_licence_us || ""
              : ""
          }
          errorText={i18n.t("errors")}
          onChange={(value) =>
            setCurrentFlow({
              ...currentFlow,
              num_licence_us: value.target.value,
            })
          }
        />
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>{inputs}</div>
    );
  };

  const renderGeneralStep = () => {
    const countriesListed = getCountriesListed();

    return (
      <div>
        <div className={styles.section}>
          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.general.new_title")}
            </Title>

            <GridRow>
              <GridCol md={3}>
                <FormLabel className={styles.flowTypeLabel} required={true}>
                  {i18n.t("pages.exportability.departure-country")}
                </FormLabel>
                <select
                  id="departure-country"
                  required={true}
                  value={departureCountry || ""} //disabled={ state.disabledInputs }
                  onChange={(e) => handleDepartureCountry(e.target.value)}
                >
                  <option value="" disabled>
                    {i18n.t("pages.new-flow.select-value")}{" "}
                    {/* replace with your actual placeholder */}
                  </option>
                  {rootStore.countriesStore.countriesDeparture.map(
                    (country) => (
                      <option key={country.value} value={country.id}>
                        {country.name}
                      </option>
                    )
                  )}
                </select>
              </GridCol>

              <GridCol md={3}>
                <AutoComplete
                  id="end-user-country"
                  label={i18n.t("pages.new-flow.general.pays_des")}
                  required={true}
                  errorText={
                    endUserCountry === undefined
                      ? i18n.t("errors.emptyField")
                      : undefined
                  }
                  onChange={
                    (value) => setEndUserCountry(value)
                    // setState({ ...state, endUserCountry: value })
                  }
                  options={rootStore.countriesStore.countriesToOptions}
                  value={endUserCountry || ""}
                />
              </GridCol>
              <GridCol md={3}>
                <AutoComplete
                  id="pays_transit"
                  label={
                    currFlowType === "TECHNOLOGY"
                      ? i18n.t("pages.new-flow.general.pays_transit_intangible")
                      : i18n.t("pages.new-flow.general.pays_transit")
                  }
                  onChange={(value) =>
                    setState({ ...state, transitCountryId: value })
                  }
                  options={rootStore.countriesStore.countriesToOptions}
                  value={currentFlow.transitCountryId || ""}
                />
              </GridCol>

              <GridCol md={3} xs={12}>
                <TextInput
                  id="customer-company"
                  label={i18n.t("pages.new-flow.general.customer-compagny")}
                  value={customerCompagny || ""}
                  required={true}
                  errorText={
                    customerCompagny === ""
                      ? i18n.t("errors.emptyField")
                      : undefined
                  }
                  onChange={
                    (e) => setCustomer(e.target.value)
                    // setState({ ...state, customerCompagny: e.target.value })
                  }
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol md={3} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.flowTypeLabel} required={true}>
                    {i18n.t("pages.new-flow.general.flow-type")}
                  </FormLabel>
                  <select
                    id="flow-type"
                    value={currFlowType || "NEW_SALE"}
                    required={true}
                    onChange={
                      (e) => setCurrFlowType(e.target.value)
                      // setState({
                      //   ...state,
                      //   flowType: e.target.value as FlowType,
                      // })
                    }
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {flowType.map((type, index) => {
                      return (
                        <option key={index} value={type.value || ""}>
                          {type.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={3} xs={12}>
                <TextInput
                  id="order_num"
                  label={
                    currFlowType === "TECHNOLOGY"
                      ? i18n.t("pages.new-flow.general.order_num_technology")
                      : i18n.t("pages.new-flow.general.order_num")
                  }
                  value={
                    state.order_num !== "null" ? state.order_num || "" : ""
                  }
                  // required={ true }
                  errorText={i18n.t("errors")}
                  onChange={(e) => {
                    setState({
                      ...state,
                      order_num: e.target.value,
                    });
                  }}
                />
              </GridCol>

              <GridCol md={3} xs={12}>
                <>
                  <FormLabel className={styles.label2}>
                    {currFlowType === "TECHNOLOGY"
                      ? i18n.t(
                          "pages.new-flow.general.commande_date_technology"
                        )
                      : i18n.t("pages.new-flow.general.commande_date")}
                  </FormLabel>
                  <input
                    className={`${styles.opacity_1} ${
                      currentFlow.commande_date &&
                      currentFlow.commande_date !== "null"
                        ? styles.opacity_1_set
                        : styles.opacity_1_default
                    }`}
                    id="pages.new-flow.general.delivery_date"
                    type="date"
                    value={
                      currentFlow.commande_date &&
                      currentFlow.commande_date !== "null"
                        ? currentFlow.commande_date
                        : ""
                    }
                    disabled={state.disabledInputs}
                    onChange={(e) =>
                      setState({ ...state, commande_date: e.target.value })
                    }
                    placeholder="dd/mm/yyyy"
                  />
                </>
              </GridCol>

              <GridCol md={3} xs={12}>
                {currFlowType === "TECHNOLOGY" ? (
                  <FormGroup>
                    <FormLabel className={styles.flowTypeLabel} required={true}>
                      {i18n.t("pages.new-flow.product.intangible-type")}
                    </FormLabel>
                    <select
                      id="intangible-type"
                      required={false}
                      value={state.intangibleType || ""}
                      onChange={(e) =>
                        setState({
                          ...state,
                          intangibleType: e.target.value as IntangibleType,
                        })
                      }
                    >
                      <option>{i18n.t("pages.new-flow.select-value")}</option>
                      {sortAlphabeticallyByKeyString(
                        intangibleType,
                        "label"
                      ).map((intangible, index) => {
                        return (
                          <option key={index} value={intangible.value || ""}>
                            {intangible.label}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                ) : null}
              </GridCol>
            </GridRow>

            <div className={styles.equipmentBox}>
              <div className={styles.equipmentHeader}>
                {i18n.t("pages.new-flow.general.titrelicence")}
              </div>

              <GridRow>
                <GridCol md={6} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {renderLicenceInputs()}

                    <Button
                      id="searchLicence"
                      primary
                      size="small"
                      style={{ marginLeft: "10px" }}
                      disabled={!activeSearchButton()}
                      /*
                      onClick={() => {
                        history.push(
                          flowUrl(
                            "/license/matching",
                            `${match.params.id}/NATIONAL`
                          )
                        );
                      }}
                      */
                      onClick={() => {
                        const originalUrl = window.location.href;

                        const newPath = flowUrl(
                          "/license/matching",
                          `${id}/NATIONAL`
                        );
                        const fullNewPath = `${newPath}`;
                        window.history.replaceState(null, "", fullNewPath);
                        setIsOpenMatching(true);
                        setUrlMatching(originalUrl);
                      }}
                    >
                      <span style={{ marginRight: "6px" }}>
                        {" "}
                        <SearchIcon fontSize="small" />
                      </span>
                      {i18n.t(
                        "pages.new-flow.general.button.num_licence_export"
                      )}
                    </Button>
                  </div>
                </GridCol>
                <GridCol md={6} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {renderLicenceUSInputs()}
                    <Button
                      id="searchUSLicence"
                      disabled={!activeSearchButton()}
                      primary
                      size="small"
                      style={{ marginLeft: "10px" }} // Ajoute une marge à gauche du bouton
                      onClick={() => {
                        const newPath = flowUrl(
                          "/license/matching",
                          `${id}/US`
                        );
                        window.history.replaceState(null, "", newPath);
                        setIsOpenMatching(true);
                      }}
                    >
                      <span style={{ marginRight: "6px" }}>
                        {" "}
                        <SearchIcon fontSize="small" />
                      </span>
                      {i18n.t("pages.new-flow.general.button.num_licence_us")}
                    </Button>
                  </div>
                </GridCol>
              </GridRow>
            </div>
          </GridContainer>
        </div>
      </div>
    );
  };

  const renderCommandeStep = () => {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>{renderCommandeStep1("equipment")}</GridContainer>
        </div>
      </div>
    );
  };

  function mapOrderLineValue(value: number): OrderLineType {
    switch (value) {
      case 0:
        return "NEW_LINE";
      case 1:
        return "CONFIRM_LINE";
      case 2:
        return "PARTIAL_LINE";
      case 3:
        return "COMPLETE_LINE";
      default:
        return "NEW_LINE"; // Valeur par défaut
    }
  }

  const renderCommandeStep1 = (key: string) => {
    const user = rootStore.userStore.user;

    let roles: string | undefined;

    if (user) {
      roles = user.role;
    }
    //const { classificationIsOpen } = state;

    return (
      <div>
        {currentEquipments &&
          currentEquipments.map((equipment, index) => {
            return (
              <div className={styles.equipmentBox} key={index}>
                <div>
                  <div
                    className={styles.equipmentHeader}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center", // pour aligner verticalement les éléments
                      width: "100%",
                    }}
                  >
                    <span>
                      {currFlowType === "TECHNOLOGY"
                        ? i18n.t("components.searchEccn.intangible")
                        : i18n.t("components.searchEccn.commande")}{" "}
                      {index + 1}
                    </span>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }} // gap pour un petit espace entre les éléments
                    >
                      {currFlowType != "TECHNOLOGY" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            width: "500px",
                            margin: 0,
                          }}
                        >
                          <FormLabel
                            className={styles.flowTypeLabel}
                            required={true}
                          >
                            {i18n.t("pages.new-flow.general.order-line.label")}
                          </FormLabel>
                          <select
                            id="flow-type"
                            style={{ width: "250px" }}
                            value={equipment.order_line}
                            required={true}
                            onChange={(e) => {
                              const value = e.target.value;
                              updateEquipment1("order_line", index, value);
                            }}
                          >
                            <option value="">
                              {i18n.t("pages.new-flow.select-value")}
                            </option>
                            {OrderLine.map((type, index) => {
                              return (
                                <option key={index} value={type.value || ""}>
                                  {type.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      {index !== 0 && (
                        <IconButton
                          onClick={() => removeEquipment(index)}
                          // ajoutez d'autres props ici si nécessaire
                        >
                          <Tooltip
                            title={i18n
                              .t("pages.search-flow.tooltip.delete")
                              .toString()}
                          >
                            <DeleteIcon style={{ color: "#e74c3c" }} />
                          </Tooltip>
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>

                <GridRow>
                  <GridCol md={3}>
                    <TextInput
                      id="equipment-part-number"
                      label={i18n.t(
                        "pages.new-flow.product.equipment.part-number"
                      )}
                      value={equipment.part_number || ""}
                      errorText={i18n.t("errors")}
                      onChange={(e) =>
                        updateEquipment1("part_number", index, e.target.value)
                      }
                    />
                  </GridCol>

                  {currFlowType != "TECHNOLOGY" && (
                    <GridCol md={2}>
                      <TextInput
                        id="equipment-quantity"
                        label={i18n.t(
                          "pages.new-flow.product.equipment.quantity"
                        )}
                        value={equipment.quantity || ""}
                        required={true}
                        type="number"
                        min={0}
                        step={1}
                        errorText={i18n.t("errors")}
                        onChange={(e) =>
                          updateEquipment1("quantity", index, e.target.value)
                        }
                      />
                    </GridCol>
                  )}
                  {currFlowType !== "TECHNOLOGY" && (
                    <GridCol md={2}>
                      <TextInput
                        id="equipment-amount"
                        label={i18n.t(
                          "pages.new-flow.product.equipment.amount"
                        )}
                        required={true}
                        type="number"
                        value={equipment.amount || ""}
                        errorText={i18n.t("errors")}
                        onChange={(e) =>
                          updateEquipment1("amount", index, e.target.value)
                        }
                      />
                    </GridCol>
                  )}
                  {currFlowType !== "TECHNOLOGY" && (
                    <GridCol md={2}>
                      <TextInput
                        id="equipment-unit-price"
                        label={i18n.t(
                          "pages.new-flow.product.equipment.unit-price"
                        )}
                        required={false}
                        type="number"
                        value={equipment.unit_price || ""}
                        errorText={i18n.t("errors")}
                        onChange={(e) =>
                          updateEquipment1("unit_price", index, e.target.value)
                        }
                      />
                    </GridCol>
                  )}
                  {currFlowType !== "TECHNOLOGY" && (
                    <GridCol md={2}>
                      <FormLabel className={styles.flowTypeLabel}>
                        {i18n.t("pages.new-flow.product.equipment.currency")}
                      </FormLabel>
                      <select
                        id="currency"
                        value={equipment.currency || ""}
                        onChange={(e) =>
                          updateEquipment1("currency", index, e.target.value)
                        }
                      >
                        <option value="">
                          {i18n.t("pages.new-flow.select-value")}
                        </option>
                        {generateSelectOption(currency)}
                      </select>
                    </GridCol>
                  )}
                </GridRow>

                <GridRow>
                  {currFlowType != "TECHNOLOGY" && (
                    <GridCol md={3}>
                      <TextInput
                        id="equipment-type"
                        label={i18n.t("components.searchEccn.line")}
                        value={equipment.equipment_type || ""}
                        errorText={i18n.t("errors")}
                        onChange={(e) =>
                          updateEquipment1(
                            "equipment_type",
                            index,
                            e.target.value
                          )
                        }
                      />
                    </GridCol>
                  )}
                  {currFlowType != "TECHNOLOGY" && (
                    <GridCol md={3}>
                      <TextInput
                        id="equipment-model"
                        label={i18n.t("components.searchEccn.Produit")}
                        value={equipment.model || ""}
                        errorText={i18n.t("errors")}
                        onChange={(e) =>
                          updateEquipment1("model", index, e.target.value)
                        }
                      />
                    </GridCol>
                  )}

                  {roles === "F_C" && (
                    <GridCol md={3}>
                      <div className={styles.row2}>
                        <div className={styles.input}>
                          <TextInput
                            id="national-eccn"
                            type="text"
                            label={i18n.t("pages.exportability.national-eccn")}
                            value={equipment.nationalEccn || ""}
                            required={true}
                            onChange={(e) =>
                              updateEquipment1(
                                "nationalEccn",
                                index,
                                e.target.value
                              )
                            }
                          />

                          <IconButton
                            id="search-national"
                            className={styles.searchButton}
                            onClick={() => {
                              setState({
                                ...state,
                                ouClick: "LoupeNat",
                                classificationIsOpen: true,
                              });
                            }}
                          >
                            <SearchIcon />
                          </IconButton>

                          {state.nationalEccnError && (
                            <span className={styles.eccnError}>
                              {i18n.t("pages.exportability.error-message")}
                            </span>
                          )}
                        </div>
                      </div>
                    </GridCol>
                  )}

                  {roles === "F_C" && (
                    <GridCol md={3}>
                      <div className={styles.row2}>
                        <div className={styles.input}>
                          <TextInput
                            label={i18n.t(
                              "pages.new-flow.product.equipment.us-eccn"
                            )}
                            value={equipment.us_eccn || ""}
                            onChange={(e) =>
                              updateEquipment1("us_eccn", index, e.target.value)
                            }
                          />
                          <IconButton
                            id="search-us"
                            className={styles.searchButton}
                            onClick={() => {
                              setState({
                                ...state,
                                ouClick: "LoupeUS",
                                classificationIsOpen: true,
                              });
                            }}
                          >
                            <SearchIcon />
                          </IconButton>

                          {state.us_eccnError && (
                            <span className={styles.eccnError}>
                              {i18n.t("pages.exportability.error-message")}
                            </span>
                          )}
                        </div>
                      </div>
                    </GridCol>
                  )}

                  {roles != "F_C" && (
                    <GridCol>
                      <TextInput
                        label={i18n.t(
                          "pages.new-flow.product.equipment.national-eccn"
                        )}
                        value={equipment.nationalEccn || ""}
                        required={true}
                        onChange={(e) =>
                          updateEquipment1(
                            "nationalEccn",
                            index,
                            e.target.value
                          )
                        }
                        errorText={i18n.t("errors.eccn-not-defined")}
                      />
                      {state.nationalEccnError && (
                        <span className={styles.eccnError}>
                          {i18n.t("pages.exportability.error-message")}
                        </span>
                      )}
                    </GridCol>
                  )}

                  {roles != "F_C" && (
                    <GridCol md={3}>
                      <TextInput
                        label={i18n.t(
                          "pages.new-flow.product.equipment.us-eccn"
                        )}
                        value={equipment.us_eccn || ""}
                        onChange={(e) =>
                          updateEquipment1("us_eccn", index, e.target.value)
                        }
                        errorText={i18n.t("errors.eccn-not-defined")}
                      />

                      {state.us_eccnError && (
                        <span className={styles.eccnError}>
                          {i18n.t("pages.exportability.error-message")}
                        </span>
                      )}
                    </GridCol>
                  )}
                  {/*
                <GridCol md={3} xs={12}>
                  <FormLabel className={styles.flowTypeLabel} required={false}>
                    {i18n.t("pages.new-flow.product.CNR")}
                  </FormLabel>

                  <UploadInput
                    value={equipment.upload_cnr}
                    onChange={file =>
                      updateEquipment1("upload_cnr", index, file)
                    }
                  />
                </GridCol>
                  */}

                  {currFlowType != "TECHNOLOGY" && (
                    <>
                      <GridCol md={3} alignSelf="center">
                        <Checkbox
                          id="us-origin"
                          large
                          label={i18n.t(
                            "pages.new-flow.product.equipment.cuf-cnr"
                          )}
                          disabled={state.disabledInputs}
                          checked={equipment.cuf_cnr}
                          onChange={(e) => {
                            updateEquipment1(
                              "cuf_cnr",
                              index,
                              e.target.checked
                            );
                          }}
                        />
                      </GridCol>
                    </>
                  )}

                  <GridCol md={3} alignSelf="center">
                    <div style={{ marginBottom: "5%" }}>
                      <FormLabel className={styles.label2}>
                        {i18n.t(
                          "pages.new-flow.product.equipment.validation-date"
                        )}
                      </FormLabel>
                      <input
                        className={`${styles.opacity_1} ${
                          currentFlow.commande_date &&
                          currentFlow.commande_date !== "null"
                            ? styles.opacity_1_set
                            : styles.opacity_1_default
                        }`}
                        id="pages.new-flow.product.equipment.validation-date"
                        type="date"
                        value={equipment.validation_date || ""}
                        disabled={state.disabledInputs}
                        onChange={(e) =>
                          updateEquipment1(
                            "validation_date",
                            index,
                            e.target.value
                          )
                        }
                        placeholder="dd/mm/yyyy"
                      />
                    </div>
                  </GridCol>
                  <GridCol md={3} alignSelf="center">
                    <div style={{ marginBottom: "5%" }}>
                      <FormLabel className={styles.label2}>
                        {i18n.t("pages.new-flow.general.delivery_date")}
                      </FormLabel>
                      <input
                        className={`${styles.opacity_1} ${
                          currentFlow.commande_date &&
                          currentFlow.commande_date !== "null"
                            ? styles.opacity_1_set
                            : styles.opacity_1_default
                        }`}
                        id="pages.new-flow.general.delivery_date"
                        type="date"
                        value={equipment.delivery_date || ""}
                        disabled={state.disabledInputs}
                        onChange={(e) =>
                          updateEquipment1(
                            "delivery_date",
                            index,
                            e.target.value
                          )
                        }
                        placeholder="dd/mm/yyyy"
                      />
                    </div>
                  </GridCol>
                </GridRow>
              </div>
            );
          })}

        <Button
          id="add-equipment"
          type="button"
          onClick={() => addEquipment()}
          size="medium"
        >
          {currFlowType === "TECHNOLOGY"
            ? i18n.t(`components.searchEccn.ajouter_intangible`)
            : i18n.t(`components.searchEccn.ajouter`)}
        </Button>
        <SearchEccn
          isOpen={state.classificationIsOpen}
          onClose={(eccn) => handleSearchEccn(eccn)}
          exportabilityMode={true}
          ouClick={state.ouClick}
          rootStore={rootStore}
        />
        <MatchingLicense
          flow={currentFlow}
          countries={countries}
          isOpenMatching={modalOpenMatching}
          onClose={async () => {
            window.history.replaceState(null, "", state.originalUrl);
            //navigate(state.originalUrl);
            setIsOpenMatching(false);
            const flowId = id;
            if (flowId) {
              const intFlowId = parseInt(flowId);

              getFlow(parseInt(flowId));
            }
          }}
          rootStore={rootStore}
        />
      </div>
    );
  };

  const renderProductStep = () => {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.product.title")}
            </Title>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.flowTypeLabel} required={true}>
                    {i18n.t("pages.new-flow.product.item-type")}
                  </FormLabel>
                  <select
                    id="product-type"
                    value={state.productType || ""}
                    required={true}
                    onChange={(e) =>
                      handleProductType(e.target.value as ProductType)
                    }
                  >
                    <option>{i18n.t("pages.new-flow.select-value")}</option>
                    {productType.map((product, index) => {
                      return (
                        <option key={index} value={product.value || ""}>
                          {product.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </GridCol>
            </GridRow>

            {state.productType === "EQUIPMENT" &&
              renderEquipmentStep("equipment")}
          </GridContainer>
        </div>
      </div>
    );
  };

  const renderEquipmentStep = (key: string) => {
    return (
      <div>
        {currentEquipments.map((equipment, index) => {
          return (
            <div className={styles.equipmentBox} key={index}>
              <div className={styles.equipmentHeader}>
                {`${i18n.t(`pages.new-flow.product.${key}.label`)} ${
                  index + 1
                }`}
              </div>
              <GridRow>
                <GridCol md={3}>
                  <TextInput
                    id="equipment-part-number"
                    label={i18n.t(
                      "pages.new-flow.product.equipment.part-number"
                    )}
                    value={equipment.part_number || ""}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateEquipment("part_number", index, e.target.value)
                    }
                  />
                </GridCol>
                <GridCol md={2}>
                  <TextInput
                    id="equipment-quantity"
                    label={i18n.t("pages.new-flow.product.equipment.quantity")}
                    value={equipment.quantity || ""}
                    type="number"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      updateEquipment(
                        "quantity" as EquipmentKey,
                        index as number,
                        value.target.value as string
                      )
                    }
                  />
                </GridCol>
                <GridCol md={3}>
                  <TextInput
                    id="equipment-supplier"
                    label={i18n.t("pages.new-flow.product.equipment.supplier")}
                    value={equipment.supplier || ""}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      updateEquipment(
                        "supplier" as EquipmentKey,
                        index as number,
                        value.target.value as string
                      )
                    }
                  />
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    label={i18n.t(
                      "pages.new-flow.product.equipment.national-eccn"
                    )}
                    value={equipment.nationalEccn || ""}
                    disabled
                    errorText={i18n.t("errors")}
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    label={i18n.t("pages.new-flow.product.equipment.us-eccn")}
                    value={equipment.us_eccn || ""}
                    disabled
                    errorText={i18n.t("errors")}
                  />
                </GridCol>
              </GridRow>
            </div>
          );
        })}

        <Button
          id="add-equipment"
          type="button"
          onClick={() => addEquipment()}
          size="medium"
        >
          {i18n.t(`pages.new-flow.product.${key}.add`)}
        </Button>
      </div>
    );
  };

  const user = rootStore.userStore.user;
  let roles: string | undefined;

  if (user) {
    roles = user.role;
  }
  let footerButtons = [
    {
      id: "save",
      onClick: () => handleSave(),
      label: i18n.t("global.save"),
      primary: true,
      disabled: !activeSaveButton(),
    },
  ];

  footerButtons.push({
    id: "next",
    onClick: () => handleSubmit(),
    label:
      roles === "L_A" || roles === "L_R_A"
        ? i18n.t("global.sub-expo-l_a")
        : roles === "F_E" || roles === "F" || roles === "F_C"
          ? i18n.t("global.sub-expo-f")
          : i18n.t("global.sub-expo"),
    primary: true,
    disabled: !activeSubmitButtonShipping(),
  });
  // }
  // }

  if (
    roles === "ADMINISTRATOR_GROUP" ||
    roles === "EXPORT_CONTROL_MANAGER" ||
    roles === "SUPER_ADMINISTRATOR" ||
    roles === "L_A" ||
    roles === "L_R_A"
  ) {
    footerButtons.push({
      id: "licensing",
      onClick: async () => {
        navigate(flowUrl("/license/imput", `${id}/NATIONAL`));
      },
      label:
        reg === "FR_MUNITIONS_LIST"
          ? i18n.t("pages.license.menu.book_licence")
          : i18n.t("pages.license.menu.depreciate_licence"),
      primary: true,
      disabled: !activeDepreciateButton(),
    });
  }

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"FLOW"} />

      <HeaderFlow pages="ADD" />
      {loading && <Loader />}
      <form className={styles.form}>
        <GridContainer>
          <GridRow>
            <GridCol md={9}>
              <Title tag="h2">{i18n.t("pages.new-flow.title")}</Title>
            </GridCol>
            <GridCol md={3}>
              <FormLabel className={styles.flowTypeLabel} required={false}>
                {i18n.t("pages.license.add.upload-license2")}
              </FormLabel>
              <UploadInput
                value={state.uploadLicense}
                disabled={state.disabledInputs}
                onChange={(file) => {
                  setState({ ...state, uploadLicense: file });
                  pdfSubmit(file);
                }}
              />
            </GridCol>
          </GridRow>
        </GridContainer>

        {renderGeneralStep()}

        {state.ordre_type === "Hierarchy" && renderProductStep()}
        {state.ordre_type === "Standard" && renderCommandeStep()}
      </form>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: state.vertical as any,
          horizontal: state.horizontal as any,
        }}
        open={open}
        onClose={handleClose}
        message=""
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity={statusSnack === 1 ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageNotification}
        </Alert>
      </Snackbar>
      <Footer justify="flex-end" buttons={footerButtons} />
    </div>
  );
};

export default inject("rootStore")(observer(NewFlow));
