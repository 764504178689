/* Styles import */
import styles from "../matching.module.css";

/* Global import */
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";

/* Custom import */
import MatchingEquipmentComponent from "../equipment";
import MatchingLicenseComponent from "../license";
import Loader from "../../loader";
import i18n from "i18next";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
  flowId: number;
  link: MatchingLinkedDto;
}

const handleClick = async (
  rootStore: import("../../../stores/rootStore").default,
  flowid: number,
  equipmentId: number,
  licenseId: number,
  type: string
) => {
  const result = await Swal.fire({
    title: i18n.t("pages.license.matching.confirm"),
    text: i18n.t("pages.license.matching.confirm-unlink.message"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: i18n.t(
      "pages.license.matching.confirm-unlink.confirm-button"
    ),
    cancelButtonText: i18n.t(
      "pages.license.matching.confirm-unlink.cancel-button"
    ),
  });

  if (result.value) {
    await rootStore.licenseStore.deleteLink(
      flowid,
      equipmentId,
      licenseId,
      type
    );
  }
};

const MatchingLinkedComponent = ({ link, flowId, rootStore }: Props) => {
  const [isHover, setHover] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // 666 : permet d'avoir les valeurs mts/qty quand la licence est matchée
  const renameKeys = (obj: any) => {
    const keyMap = {
      amountOrdered: "amount_ordered",
      amountDelivered: "amount_delivered",
      quantityOrdered: "quantity_ordered",
      quantityDelivered: "quantity_delivered",
      unitPrice: "unit_price",
    } as any;

    return Object.keys(obj).reduce((acc: any, key: any) => {
      const newKey = keyMap[key] || key;
      acc[newKey] = obj[key];
      return acc;
    }, {});
  };
  return (
    <div>
      {isLoading && <Loader />}

      <div className={styles.linkedSection}>
        <MatchingEquipmentComponent
          flow={link}
          equipment={link.equipment}
          className={isHover ? "deleted" : "selected"}
          type={link.type}
        />

        <div className={styles.link}>
          <div
            className={styles.button}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={async () => {
              setLoading(true);

              await handleClick(
                rootStore,
                flowId,
                link.equipment.id,
                link.license.id,
                link.type
              );

              setLoading(false);
            }}
          >
            <CloseIcon />
          </div>
        </div>

        <MatchingLicenseComponent
          endUserFromLicense={
            (link.license.recipient as any) ||
            (link.license.company_name as any)
          }
          partNumber={
            (link.license.partNumber as any) || (link.license.pn as any)
          }
          selectedEquipmentItem={link.equipment}
          license={renameKeys(link.license)}
          className={isHover ? "deleted" : "selected"}
          rootStore={rootStore}
          linked={true}
        />
      </div>
    </div>
  );
};

export default MatchingLinkedComponent;
