/* Styles import */
import styles from "./progressUpload.module.css";

/* Global import */
import React, { Component } from "react";
import {
  FormGroup,
  Button,
  Loading,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

/* Custom import */
import UploadInput from "../uploadInput";

interface Props {
  label: string;
  progress: number;
  onChange: (file: File) => void;
  uploadState: RequestState;
  subLabel?: string;
}
interface State {
  file?: File;
}

export default class ProgressUpload extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  handleSubmit() {
    if (this.state.file) {
      this.props.onChange(this.state.file);
    }
  }

  getFakeButtonClass(state: RequestState) {
    let cssClass: string = "";

    switch (state) {
      case "FAIL":
        cssClass = styles.fail;
        break;
      case "DONE":
        cssClass = styles.success;
        break;
      default:
        break;
    }

    return cssClass;
  }

  getFakeButtonContent(state: RequestState) {
    let render: JSX.Element | undefined = undefined;

    switch (state) {
      case "PENDING":
        render = (
          <div className={styles.loading}>
            <Loading small />
          </div>
        );
        break;
      case "FAIL":
        render = (
          <div className={styles.icon}>
            <CloseIcon />
          </div>
        );
        break;
      case "DONE":
        render = (
          <div className={styles.icon}>
            <CheckIcon />
          </div>
        );
        break;
      default:
        break;
    }

    return render;
  }

  render() {
    return (
      <FormGroup>
        <label>{this.props.label}</label>

        <div className={styles.row}>
          <div className={styles.input}>
            <UploadInput
              value={this.state.file}
              onChange={(file) => this.setState({ file })}
            />

            {this.props.uploadState === "PENDING" && (
              <div className={styles.loader}>
                <div style={{ width: `${this.props.progress}%` }}></div>
              </div>
            )}
          </div>

          {this.props.uploadState !== "INITIALIZE" ? (
            <div
              className={`${styles.fakeButton} ${this.getFakeButtonClass(
                this.props.uploadState,
              )}`}
            >
              {this.getFakeButtonContent(this.props.uploadState)}
            </div>
          ) : (
            <Button
              size="medium"
              primary
              disabled={this.state.file === undefined}
              onClick={() => this.handleSubmit()}
            >
              {i18n.t("components.upload-input.upload-and-save")}
            </Button>
          )}
        </div>
        <div className={styles.row}>
          <label>{this.props.subLabel}</label>
        </div>
      </FormGroup>
    );
  }
}
