/* Styles import */
import { flowResult } from "mobx";
import styles from "../matching.module.css";
import i18n from "i18next";

/* Global import */
import React from "react";

interface Props {
  flow: any;
  equipment: MatchingEquipmentDto;
  className?: "selected" | "deleted";
  clickable?: boolean;
  onClick?: (id: number) => void;
  type?: string; // ici, la propriété type est facultative
}

const MatchingEquipmentComponent = ({
  equipment,
  className,
  clickable,
  onClick,
  type,
  flow,
}: Props) => {
  const eccn = type === "US" ? equipment.us_eccn : equipment.nationalEccn;
  return (
    <div
      className={`${styles.equipment} ${styles[className || ""]} ${
        clickable ? styles.clickable : ""
      }`}
      onClick={() => {
        if (clickable && onClick) {
          onClick(equipment.id);
        }
      }}
    >
      <div className={styles.icon}>
        <img
          src={require("../../../../statics/images/equipment.svg").default}
          alt={"equipment"}
        />
      </div>
      {/* <div className={styles.separator}></div> */}

      <div className={styles.informations}>
        <div>
          <div className={styles.regulation}>
            {equipment && equipment.model ? equipment.model : ""}
          </div>
          <br></br>
          <div className={styles.partNumber}>
            <b>{i18n.t("pages.license.add.part-number")} :</b>{" "}
            {equipment && equipment.partNumber ? equipment.partNumber : ""}
            {equipment && equipment.part_number ? equipment.part_number : ""}
          </div>
          <div className={styles.partNumber}>
            <b>{i18n.t("pages.license.add.quantity")} :</b>{" "}
            {equipment && equipment.quantity ? equipment.quantity : ""}
          </div>
          <div className={styles.partNumber}>
            <b>{i18n.t("pages.license.add.unit-price")} :</b>{" "}
            {(equipment && equipment.unitPrice) || equipment.unit_price
              ? equipment?.unitPrice?.toLocaleString() ||
                equipment?.unit_price?.toLocaleString()
              : "-"}
          </div>
          <div className={styles.partNumber}>
            <b>{i18n.t("pages.license.add.amount")} :</b>{" "}
            {equipment && equipment.amount
              ? equipment.amount.toLocaleString()
              : ""}
          </div>
          <div className={styles.partNumber}>
            <b>{i18n.t("pages.license.add.end-user")} :</b>{" "}
            {flow.flow?.customer || flow.customer
              ? flow.flow?.customer || flow.customer
              : " - "}
          </div>
        </div>
        <div className={styles.eccn}>{eccn}</div>
      </div>
    </div>
  );
};

export default MatchingEquipmentComponent;
