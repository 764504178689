/* Styles import */
import styles from "./license.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Checkbox,
  SearchInput,
  Table,
  TextInput,
  TableHead,
  TableRow,
  TableBody,
  TableHeaderCell,
  Pagination,
  IconButton,
  Radio,
  Flag,
  //DialogActions,
  //DialogContent,
  //Dialog,
  Button,
} from "../../components/horizon-components-react/src/components";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import i18n from "i18next";
import { Hidden, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { inject, observer } from "mobx-react";
import AutoComplete from "../../components/autoComplete";
import { FormLabel } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import FolderOpenIcon from "@material-ui/icons/Folder";
import ViewIcon from "@material-ui/icons/Loupe";
import moment from "moment";
import Api from "../../utils/api";
/* Custom import */
import AppBar from "../../components/appBar";
import Loader from "../../components/loader";
import {
  generateFlowId,
  redirectFlow,
  flowStatus,
} from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow";
import { flowStatusList, flowType } from "../../utils/constant";
import { UnsubscribeTwoTone } from "@material-ui/icons";
import { unset } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

interface State {
  flowType?: FlowType;
  creationDateBegin?: string;
  dualUse?: boolean;
  usEar?: boolean;
  status?: FlowStatus;
  creationDateEnd?: string;
  ml?: boolean;
  usItar?: boolean;
  search?: string;
  departureCountry: number;
  endUserCountry: number;
  customer?: string;
  flows: FlowDto[];
  page: number;
  totalResults: number;
  loading: boolean;
  filter: Filter;
  desId: number;
  open: boolean;
  open1: boolean;
}

type Keys = keyof State;

const i18nTitle = i18n.t("pages.search-flow.tooltip.edit");
const titleElement = <span>{i18nTitle}</span>;

const SearchFlow: React.FC<Props> = ({ rootStore }) => {
  //const departureCountry = 0;
  const api = new Api();
  const navigate = useNavigate();
  const navigateTowards = (id: any) => {
    const url = `/flow/${id}/classification`;
    navigate(url);
  };
  const [state, setState] = useState({
    //flows: [] as FlowDto[],
    page: 1,
    totalResults: 0,
    customer: "",
    filter: "ALL",
    open: false,
    open1: false,
    desId: 0,
    ml: false,
    usItar: false,
    search: "",
    status: "" as FlowStatus,
  });

  const [departureCountry, setDepartureCountry] = useState(0);
  const [endUserCountry, setEndusercountry] = useState(0);
  const [selectedFlowType, setSelectedFlowtype] = useState("");
  const [customer, setCustomer] = useState("");
  const [dateBegin, setDateBegin] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [page, setPage] = useState(1);
  const [flows, setFlows] = useState<FlowDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      await rootStore.countriesStore.getCountries();
      rootStore.countriesStore.getCountriesDeparture();
    };

    const loadData2 = async () => {
      setLoading(true);
      const searchFlowObject = {
        ...state,
        endUserCountry: endUserCountry as number,
        flowType: selectedFlowType as FlowType,
        customer: customer as string,
        departureCountry: departureCountry as number,
        pageNumber: (page - 1) as number,
        creationDateBegin: dateBegin,
        creationDateEnd: dateEnd,
      };
      const searchResult =
        await rootStore.flowStore.searchFlow(searchFlowObject);

      if (searchResult)
        searchResult.results.map(async (flow, index) => {
          // if ("ARCHIVED" === flow.status) delete searchResult.results[index];

          if (
            departureCountry !== undefined &&
            departureCountry !== 0 &&
            departureCountry !== flow.departureCountryId
          )
            delete searchResult.results[index];

          if (
            endUserCountry !== undefined &&
            endUserCountry !== 0 &&
            endUserCountry !== flow.destinationCountryId
          )
            delete searchResult.results[index];
          if (
            customer !== undefined &&
            customer !== "" &&
            customer !== flow.customer
          )
            delete searchResult.results[index];
        });

      setFlows(
        searchResult ? (searchResult.results as FlowDto[]) : ([] as FlowDto[])
      );
      setState({
        ...state,
        /*flows: searchResult
          ? (searchResult.results as FlowDto[])
          : ([] as FlowDto[]),*/
        totalResults: searchResult ? searchResult.totalResults : 0,
      });
      setLoading(false);
    };
    loadData();
    loadData2();
    //return () => {};
  }, [
    endUserCountry,
    departureCountry,
    selectedFlowType,
    customer,
    dateBegin,
    dateEnd,
    page,
  ]);

  const search = async (params: SearchFlowParams) => {
    setLoading(true);

    const searchResult = await rootStore.flowStore.searchFlow(params);

    if (searchResult)
      searchResult.results.map(async (flow, index) => {
        if ("ARCHIVED" === flow.status) delete searchResult.results[index];

        if (
          params.departureCountry !== undefined &&
          params.departureCountry !== 0 &&
          params.departureCountry !== flow.departureCountryId
        )
          delete searchResult.results[index];

        if (
          params.endUserCountry !== undefined &&
          params.endUserCountry !== 0 &&
          params.endUserCountry !== flow.destinationCountryId
        )
          delete searchResult.results[index];
        if (
          params.customer !== undefined &&
          params.customer !== "" &&
          params.customer !== flow.customer
        )
          delete searchResult.results[index];
      });

    setFlows(
      searchResult ? (searchResult.results as FlowDto[]) : ([] as FlowDto[])
    );
    setState({
      ...state,
      totalResults: searchResult ? searchResult.totalResults : 0,
    });
    setLoading(false);
  };

  const submitDelete = async (flowId: number) => {
    const searchResult = await rootStore.flowStore.deleteFlow(flowId);

    window.location.reload();
  };

  const renderForm = () => {
    return (
      <form className={styles.form}>
        <div className={styles.container}>
          <GridRow alignItems="end">
            <GridCol md={3}>
              <div className={styles.input}>
                <AutoComplete
                  id="departure-country"
                  label={i18n.t("pages.new-flow.general.departure-country")}
                  options={rootStore.countriesStore.countriesDepartureToOptions}
                  errorText={i18n.t("errors.departure-country-not-defined")}
                  value={departureCountry || ""}
                  onChange={(e) => {
                    setDepartureCountry(parseInt(e));
                    /*handleDepartureCountry(e);
                    handleChange("departureCountry", e);*/
                  }}
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <div className={styles.input}>
                <FormLabel>
                  {i18n.t("pages.new-flow.general.flow-type")}
                </FormLabel>

                <select
                  id="flow-type"
                  value={selectedFlowType || ""}
                  onChange={(e) =>
                    /*handleChange("flowType", e.target.value)*/ setSelectedFlowtype(
                      e.target.value as FlowType
                    )
                  }
                >
                  <option value="">
                    {i18n.t("pages.new-flow.select-value")}
                  </option>
                  {flowType.map((type: any, index: number) => {
                    return (
                      <option key={index} value={type.value || ""}>
                        {type.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </GridCol>

            <GridCol md={3}>
              <div className={styles.input}>
                <AutoComplete
                  id="end-user-country"
                  label={i18n.t("pages.exportability.country-of-destination")}
                  errorText={i18n.t("errors")}
                  onChange={(e) => {
                    /*setState({ ...state, endUserCountry: value.target.value });
                    handleChange("endUserCountry", value.target.value);*/
                    setEndusercountry(e);
                  }}
                  options={rootStore.countriesStore.countriesToOptions}
                  value={endUserCountry || ""}
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>
                {i18n.t("pages.new-flow.general.end-user-country")}
              </FormLabel>
              <div className={styles.input}>
                <TextInput
                  value={customer || ""}
                  onChange={(e) => {
                    /*
                    setState({ ...state, customer: value.target.value });
                    handleChange("customer", value.target.value);*/
                    setCustomer(e.target.value);
                  }}
                />
              </div>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.search-flow.creation-date")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-begin"
                  type="date"
                  value={
                    dateBegin
                      ? moment(dateBegin).format("YYYY-MM-DD") // seulement format si une valeur existe
                      : ""
                  }
                  onChange={
                    (e) => setDateBegin(e.target.value)
                    //handleChange("creationDateBegin", e.target.value)
                  }
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.new-flow.general.to")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-end"
                  type="date"
                  value={dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : ""}
                  onChange={
                    (e) => setDateEnd(e.target.value)
                    //handleChange("creationDateEnd", e.target.value)
                  }
                />
              </div>
            </GridCol>
          </GridRow>
        </div>
      </form>
    );
  };

  const renderListFlow = () => {
    const handleOpen = () => setState({ ...state, open: true });
    const handleClose = () => setState({ ...state, open: false });
    const handleClose1 = () => setState({ ...state, open1: false });
    return (
      <div className={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell className={styles.paddingColumn}>
                <b>{i18n.t("pages.search-flow.order_num")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.paddingColumn}>
                <b>{i18n.t("pages.search-flow.creation-date")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.paddingColumn}>
                <b>{i18n.t("pages.search-flow.departure-country")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.paddingColumn}>
                <b>{i18n.t("pages.search-flow.end-user-country")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.paddingColumn}>
                <b>{i18n.t("pages.search-flow.customers-company")}</b>
              </TableHeaderCell>

              <TableHeaderCell className={styles.mediumColumn}>
                <b>{i18n.t("pages.search-flow.model")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.fixedColumn}>
                <b>{i18n.t("pages.search-flow.folder")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.fixedColumn}>
                <b>{i18n.t("pages.search-flow.du-ml")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.fixedColumn}>
                <b>{i18n.t("pages.search-flow.regulation")}</b>
              </TableHeaderCell>
              <TableHeaderCell className={styles.fixedColumn}>
                <b>{i18n.t("pages.search-flow.action")} </b>
              </TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {flows.map((flow, index) => {
              let label = flowStatus["CLASSIFICATION"];

              if (flowStatus[flow.status]) {
                label = flowStatus[flow.status];
              }

              return (
                <TableRow key={index}>
                  <td>
                    {flow.order_num ? flow.order_num.replace("null", " ") : ""}
                  </td>

                  <td>
                    {flow.createdAt &&
                      moment(flow.createdAt)
                        .locale(i18n.language)
                        .format(
                          i18n.language === "fr" ? "D MMM YYYY" : "MMM D, YYYY"
                        )}
                  </td>

                  <td>
                    {flow.departureCountryName ===
                    "United States of America (USA)"
                      ? "USA"
                      : flow.departureCountryName}
                  </td>
                  <td>{flow.destinationCountryName}</td>
                  <td>{flow.customer}</td>

                  <td className={styles.mediumColumn}>
                    {flow.flowAircraft
                      ? flow.flowAircraft.model
                      : flow.flowEquipments.length > 0
                        ? flow.flowEquipments[0].model
                        : ""}
                  </td>
                  <td className={styles.fixedColumn}>
                    <img
                      src={require(`../../../statics/images/folder1.png`)}
                      alt={"Folder"}
                    />
                  </td>
                  <td className={styles.fixedColumn}>
                    {flow.status === "ARCHIVED" && <Flag>ARCHIVED</Flag>}
                    {!flow.hasMlEccn && flow.hasDuEccn && <Flag>DU</Flag>}

                    {flow.hasMlEccn == true && <Flag>ML</Flag>}

                    {!flow.hasDuEccn &&
                      !flow.hasMlEccn == true &&
                      flow.hasNcEccn == true && <Flag>NL</Flag>}
                  </td>
                  <td className={styles.fixedColumn}>
                    {!flow.hasItarEccn && flow.hasEarEccn == true && (
                      <Flag>EAR</Flag>
                    )}

                    {flow.hasItarEccn == true && <Flag>ITAR</Flag>}

                    {!flow.hasEarEccn &&
                      !flow.hasItarEccn &&
                      flow.hasEar99Eccn == true && <Flag>EAR99</Flag>}
                  </td>
                  <td className={styles.fixedColumn}>
                    {/*

                    <IconButton
                      onClick={() =>
                        history.push(`/flow/${flow.id}/view`)
                      }
                    >
                      <Tooltip
                        title={i18n.t("pages.search-flow.tooltip.visualise")}
                      >
                        <ViewIcon />
                      </Tooltip>
                    </IconButton>
                    */}
                    {flow.status !== "ARCHIVED" && (
                      <IconButton onClick={() => navigateTowards(flow.id)}>
                        <Tooltip title={i18nTitle}>
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                    )}

                    <IconButton
                      onClick={() => {
                        setState({ ...state, desId: flow.id, open1: true });
                      }}
                      //  style={{display: injected.rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) ? 'block' : 'none' }}

                      //   disabled={ !injected.rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) }
                    >
                      <Tooltip title={i18nTitle}>
                        <DeleteIcon style={{ color: "#e74c3c" }} />
                      </Tooltip>
                    </IconButton>
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          page={page}
          count={state.totalResults}
          rowsPerPage={50}
          onPageChange={(_, page) => setPage(page)}
        />
        <Dialog
          open={state.open1}
          title="Suppression Flux"
          onClose={handleClose1}
        >
          <DialogContent>
            Le Flux va être supprimé ainsi que ses données.
            <br />
            Etes-vous vraiment sûr ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Annuler</Button>
            <Button
              primary
              onClick={() => {
                submitDelete(state.desId);
                handleClose1();
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="FLOW" />

      <HeaderFlow pages="SEARCH" />

      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.search-flow.title")}</Title>

        {renderForm()}

        {flows.length > 0 ? (
          renderListFlow()
        ) : (
          <p>{i18n.t("pages.search-flow.no-result")}</p>
        )}

        {loading && <Loader />}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(SearchFlow));
